import { makeIconDef } from "./makeIconDef";

export const SearchIconDef = {
  search: makeIconDef("search", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6924 9.19238C14.6924 12.2299 12.2299 14.6924 9.19238 14.6924C6.15482 14.6924 3.69238 12.2299 3.69238 9.19238C3.69238 6.15482 6.15482 3.69238 9.19238 3.69238C12.2299 3.69238 14.6924 6.15482 14.6924 9.19238ZM14.0765 14.7835C13.707 14.4139 13.119 14.399 12.6781 14.6797C11.6708 15.3209 10.4749 15.6924 9.19238 15.6924C5.60253 15.6924 2.69238 12.7822 2.69238 9.19238C2.69238 5.60253 5.60253 2.69238 9.19238 2.69238C12.7822 2.69238 15.6924 5.60253 15.6924 9.19238C15.6924 10.4749 15.3209 11.6707 14.6798 12.678C14.3991 13.1188 14.414 13.7068 14.7836 14.0764L21.0815 20.3743C21.2768 20.5696 21.2768 20.8862 21.0815 21.0814V21.0814C20.8863 21.2767 20.5697 21.2767 20.3744 21.0814L14.0765 14.7835Z"
        fill="currentColor"
      />
    ),
    filled: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6924 9.19238C13.6924 11.6777 11.6777 13.6924 9.19238 13.6924C6.7071 13.6924 4.69238 11.6777 4.69238 9.19238C4.69238 6.7071 6.7071 4.69238 9.19238 4.69238C11.6777 4.69238 13.6924 6.7071 13.6924 9.19238ZM13.6332 15.0473C13.2844 14.6985 12.737 14.664 12.3042 14.9005C11.38 15.4054 10.3197 15.6924 9.19238 15.6924C5.60253 15.6924 2.69238 12.7822 2.69238 9.19238C2.69238 5.60253 5.60253 2.69238 9.19238 2.69238C12.7822 2.69238 15.6924 5.60253 15.6924 9.19238C15.6924 10.3197 15.4054 11.3799 14.9006 12.3041C14.6641 12.7369 14.6986 13.2843 15.0474 13.633L21.0815 19.6672C21.4721 20.0577 21.4721 20.6909 21.0815 21.0814V21.0814C20.691 21.4719 20.0579 21.4719 19.6673 21.0814L13.6332 15.0473Z"
        fill="currentColor"
      />
    ),
  }),
};
