import { IconDef } from ".";

export const makeIconDef = (name: string, def: IconDef) => ({
  regular: () => (
    <symbol
      key={`icon-${name}-regular`}
      id={`icon-${name}-regular`}
      viewBox="0 0 24 24"
      fill="none"
    >
      {def.regular()}
    </symbol>
  ),
  filled: () => (
    <symbol
      key={`icon-${name}-filled`}
      id={`icon-${name}-filled`}
      viewBox="0 0 24 24"
      fill="none"
    >
      {def.filled ? def.filled() : def.regular()}
    </symbol>
  ),
});
