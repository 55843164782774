import { Observer } from "mobx-react-lite";
import { IntlProvider } from "react-intl";
import "./App.scss";
import AppContainer from "./components/AppContainer/AppContainer";
import ErrorBoundary from "./components/_base/ErrorBoundary/ErrorBoundary";
import Symbols from "./components/_base/Symbols/Symbols";
import { useControllers } from "./hooks/controller.hooks";
import { LOCALE_MESSAGE_MAP } from "./lang/index.lang";

const App = () => {
  const { SETTINGS } = useControllers();

  return (
    <Observer
      children={() => (
        <div className="App">
          <Symbols />
          <IntlProvider
            locale={SETTINGS.locale}
            messages={LOCALE_MESSAGE_MAP[SETTINGS.locale]}
          >
            <ErrorBoundary fallback={e => "Error loading game"}>
              <AppContainer />
            </ErrorBoundary>
          </IntlProvider>
        </div>
      )}
    />
  );
};

export default App;
