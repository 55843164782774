import { Observer } from "mobx-react-lite";
import React from "react";
import PieceGraphic from "../PieceGraphic/PieceGraphic";
import { BoardColNumber, BoardRowNumber } from "./Board";

type BoardDebugPieceSetProps = {};

const BoardDebugPieceSet: React.FC<BoardDebugPieceSetProps> = React.memo(
  props => {
    return (
      <Observer
        children={() => (
          <g className="BoardDebugPieceSet">
            {Array(10)
              .fill(null)
              .map((n, row) => {
                return Array(9)
                  .fill(null)
                  .map((n, col) => {
                    return (
                      <PieceGraphic
                        baseType="debug"
                        key={`row-${row}-col-${col}`}
                        row={row as BoardRowNumber}
                        col={col as BoardColNumber}
                      />
                    );
                  });
              })}
          </g>
        )}
      />
    );
  }
);

export default BoardDebugPieceSet;
