import { MSG } from "./MSG.enum";

export const ZH_CN_MESSAGES = {
  [MSG.pzxy]: "枰踪侠韵",
  [MSG.pzxyLogo]: "枰踪侠韵商标",
  [MSG.appName]: "棋谱工作室",
  [MSG.untitledGame]: "未命名棋谱",
  [MSG.createNewGame]: "创建新棋谱",
  [MSG.viewModeSelectorTitle]: "界面模式",
  [MSG.viewModeSelectorEditSetupModeLabel]: "开局",
  [MSG.viewModeSelectorReadingModeLabel]: "阅读",
  [MSG.viewModeSelectorDesignModeLabel]: "设计",
  [MSG.viewModeSelectorEditModeLabel]: "编辑",
  [MSG.viewModeSelectorExportModeLabel]: "导出",
  [MSG.importGamesSectionTitle]: "导入",
  [MSG.importJsonButtonLabel]: "导入 JSON 棋谱",
  [MSG.enterMovesRecordButtonLabel]: "输入棋谱…",
  [MSG.pasteOrEnterMovesRecordLabel]: "请在以下文字框内输入或粘贴棋谱：",
  [MSG.error_MovesRecordImportFailure]:
    "读取棋谱文本失败。如果您确认棋谱内容无误，请联系开发团队。您所输入的内容已复制到剪贴板。",
  [MSG.gameOpeningSetupEditorTitle]: "编辑开局",
  [MSG.gameOpening]: "开局",
  [MSG.endGame]: "终局",
  [MSG.clearBoard]: "清空棋盘",
  [MSG.removeSelectedPieceButtonLabel]: "移除选中棋子",
  [MSG.confirmClearBoard]: "确认要清空棋盘吗？",
  [MSG.metaInfoEditorSectionTitle]: "棋谱信息与设定",
  [MSG.gameRecordTitle]: "棋谱标题",
  [MSG.gameCategory]: "棋谱类别",
  [MSG.gameCollectionName]: "棋谱合辑名称",
  [MSG.gameMatchName]: "对局名称",
  [MSG.gameTournamentName]: "赛事名称",
  [MSG.gameRecordFileName]: "存储文件名",
  [MSG.exporterSectionTitle]: "导出",
  [MSG.exporterOptionsTableTitle]: "导出选项",
  [MSG.exportAsJonButtonLabel]: "导出为 JSON",
  [MSG.exportOnlyOpeningSetupAsJonButtonLabel]: "导出为 JSON (仅开局)",
  [MSG.exportCurrentFrameButtonLabel]: "将当前画面存为图片",
  [MSG.exportAllFramesButtonLabel]: "批量导出每一步骤为单独的图片",
  [MSG.exportImageFileFormatLabel]: "静态图片导出格式",
  [MSG.exportGifButtonLabel]: "渲染 GIF 动图",
  [MSG.tooManyFramesForGifWarning]:
    "该棋局步骤较多，继续渲染GIF动图可能会导致文件过大或页面失去响应。",
  [MSG.movesSectionTitle]: "棋谱内容",
  [MSG.DesignCustomizerSectionTitle]: "设计",
  [MSG.customizerOptionBoardDesignSectionTitle]: "棋盘",
  [MSG.customizerOptionBoardInset]: "留白",
  [MSG.customizerOptionBoardBackgroundColor]: "背景色",
  [MSG.customizerOptionBoardBackgroundImage]: "背景图",
  [MSG.customizerOptionBoardBorderColor]: "边框色",
  [MSG.customizerOptionBoardBorderWidth]: "边框宽度",
  [MSG.customizerOptionBoardFontFamily]: "字体",
  [MSG.customizerOptionBoardFontWeight]: "字重",
  [MSG.customizerOptionBoardFontSize]: "字号",
  [MSG.customizerOptionBoardTextColor]: "文字色",
  [MSG.customizerOptionBoardTextOpacity]: "文字透明度",
  [MSG.customizerOptionBoardGridColor]: "网格色",
  [MSG.customizerOptionBoardGridOpacity]: "网格透明度",
  [MSG.customizerOptionPieceDesignSectionTitle]: "棋子风格",
  [MSG.customizerOptionPieceBaseType]: "形状",
  [MSG.customizerOptionPieceCharSet]: "文字",
  [MSG.customizerOptionMoreOptionsSectionTitle]: "更多选项",
  [MSG.fontNameSystemSans]: "系统字体",
  [MSG.fontNameGenwan]: "源云明体",
  [MSG.fontNameTaipeiSans]: "台北黑体",
  [MSG.fontNameSourceHanSansSC]: "思源黑体",
  [MSG.fontNameSourceHanSerifSC]: "思源宋体",
  [MSG.pieceCharSetNameGenwan]: "源云明体 500",
  [MSG.pieceCharSetNameTaipeiSans]: "台北黑体 300",
  [MSG.pieceCharSetName1941]: "1941 古书隶",
  [MSG.pieceCharSetNameTsingWoodbone]: "清朝木骨棋子",
  [MSG.pieceCharSetNameTikHianOriginal]: "竹香原创",
  [MSG.selectorPlaceholderText]: "　",
  [MSG.pieceBaseTypeCircle]: "圆形",
  [MSG.customizerOptionPieceDesignRedSectionTitle]: "红方",
  [MSG.customizerOptionPieceDesignBlackSectionTitle]: "黑方",
  [MSG.labelOutlineColor]: "描边",
  [MSG.labelOutlineTexture]: "描边纹理",
  [MSG.labelFillColor]: "背景",
  [MSG.labelFillTexture]: "背景纹理",
  [MSG.labelTextColor]: "文字",
  [MSG.labelTextTexture]: "文字纹理",
  [MSG.labelHeader]: "页眉",
  [MSG.labelFooter]: "页脚",
  [MSG.noticeTextAllPiecesAlive]: "目前所有棋子都摆在棋盘之上。",
  [MSG.labelMiddleSlot]: "中",
  [MSG.labelStartSlot]: "左",
  [MSG.labelEndSlot]: "右",
  [MSG.labelPlayerRed]: "红方",
  [MSG.labelPlayerBlack]: "黑方",
  [MSG.labelPlayerRedName]: "红方姓名",
  [MSG.labelPlayerBlackName]: "黑方姓名",
  [MSG.labelCustomText]: "自定义文字",
  [MSG.boardPlayerColorLabelRed]: "红方",
  [MSG.boardPlayerColorLabelBlack]: "黑方",
  [MSG.boardPlayerColorLabelRedWithSpace]: "红 方",
  [MSG.boardPlayerColorLabelBlackWithSpace]: "黑 方",
  [MSG.unnamedPlayer]: "佚名",
  [MSG.themeNameGenwanWarm]: "源云明体 米色背景",
  [MSG.themeNameTaipeiSansWhite]: "台北黑体 白色背景",
  [MSG.themeNameTextbook1941BW]: "1941古书隶 黑白教材用",
  [MSG.themeNameTsingWoodboneClean]: "清朝木骨棋子 白色背景",
  [MSG.themeNameTikHianOriginal]: "竹香原创 象牙白",
  [MSG.themeNameTikHianWhite]: "竹香原创 简约白",
  [MSG.themeNameTikHianOlive]: "竹香原创 竹仙绿",
  [MSG.themeNameTikHianDark]: "竹香原创 碳灰金",
  [MSG.customizerThemePresetSectionTitle]: "预设主题",
  [MSG.labelDistanceToBoardContent]: "与内容间距",
  [MSG.nthRoundCounter]: "回合数",
  [MSG.nthRound]: "第 {nth} 回合",
  [MSG.nthPage]: "第 {nth} 页",
  [MSG.pageNumber]: "页码",
  [MSG.pageNumberWithCount]: "当前页 / 总计",
  [MSG.labelShowColumnNumberLabels]: "显示纵线数字标号",
  [MSG.labelColumnNumberLabelBlackFontWeight]: "黑方字重",
  [MSG.labelColumnNumberLabelRedFontWeight]: "红方字重",
  [MSG.labelExportStaticImageWidth]: "导出静态图片分辨率 (宽度，像素)",
  [MSG.labelExportAnimatedImageWidth]: "导出GIF动态图片分辨率 (宽度，像素)",
  [MSG.labelAnimatePieceMovement]: "移动棋子动画效果 (渲染GIF时无效)",
  [MSG.labelDropHereToRemovePiece]: "在此处放下棋子",
  [MSG.copyGameRecordAsText]: "复制棋谱为文本",
  [MSG.copySuccessMessage]: "复制成功！您现在可以在其他程序中粘贴棋谱为文本。",
  [MSG.labelPrevMove]: "上一步",
  [MSG.labelNextMove]: "下一步",
  [MSG.labelStartAutoPlay]: "开始自动播放",
  [MSG.labelStartAutoPlayFromBeginning]: "从头自动播放",
  [MSG.labelStopAutoPlay]: "停止自动播放",
  [MSG.labelPauseAutoPlay]: "暂停自动播放",
  [MSG.labelAutoPlayStepDurationInSeconds]: "每步时间(秒)",
  [MSG.labelPlayerArmyAssignment]: "棋盘方向",
  [MSG.labelBlackAtNorth]: "红下黑上",
  [MSG.labelRedAtNorth]: "红上黑下",
  [MSG.labelPlayerFirstHandSelector]: "先行方",
  [MSG.labelOpeningSetup]: "棋局开始",
  [MSG.labelCurrentlySelectedPiece]: "当前选中棋子",
  [MSG.labelCharacterDesignVariant]: "字形",
  [MSG.exporterStateReady]: "选择项目以进行导出。",
  [MSG.exporterStateExportingAsJSON]: "正在导出为JSON…",
  [MSG.exporterStateRenderingNthFrame]: "正在渲染第 {nth} 页…",
  [MSG.exporterStateRenderingNthFrameOfTotal]: "正在渲染页面 {nth} / {total} …",
  [MSG.exporterStatePreparingGIF]: "正在生成GIF文件…",
  [MSG.exporterStatePreparingZIP]: "正在压缩图片文件…",
  [MSG.exporterStateDownloading]: "正在启动下载…",
  [MSG.exporterStateDownloadingGIF]: "正在下载GIF…",
  [MSG.exporterStateCompletion]: "导出完成。",
  [MSG.textureNameMarble]: "大理石",
  [MSG.textureNamePaperWarm]: "暖色纸",
  [MSG.textureNamePaperWarmer]: "暖色纸 2",
};
