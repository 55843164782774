export function splitArrayEvery<T>(arr: T[], n: number) {
  const group = [] as T[][];
  for (var i = 0, j = 0; i < arr.length; i++) {
    if (i >= n && i % n === 0) j++;
    group[j] = group[j] || [];
    group[j].push(arr[i]);
  }
  return group;
}

export const addToArrayIfNew = <T extends any = any>(
  arr?: T[],
  ...itemsToAdd: T[]
) => {
  if (!arr) return arr;
  for (let item of itemsToAdd) {
    if (arr.includes(item)) continue;
    arr.push(item);
  }
  return arr;
};

export const removeFromArray = <T extends any = any>(
  arr?: T[],
  ...itemsToRemove: any
) => {
  if (!arr) return arr;
  for (let item of itemsToRemove) {
    let i = arr.indexOf(item);
    while (i >= 0) {
      arr.splice(i, 1);
      i = arr.indexOf(item);
    }
  }
  return arr;
};

type JoinerArgType = string | number | boolean | undefined | null;

export const joinTruthy = (separator: string, ...args: JoinerArgType[]) => {
  return args.filter((x: any) => x).join(separator);
};

export const joinTruthyFactory =
  (separator: string = ",") =>
  (...args: JoinerArgType[]) =>
    joinTruthy(separator, ...args);

export const joinTruthyWithSpace = joinTruthyFactory(" ");

export const space = (...args: JoinerArgType[]) => args.join(" ");
export const comma = (...args: JoinerArgType[]) => args.join(",");

export const keepTruthy = <T>(arr: T[]) =>
  arr.filter(i => i || (i as any) === 0 || (i as any) === -0) as Exclude<
    T,
    false | undefined | null | "" | typeof NaN
  >[];

export const replaceContents = <T>(arr: T[], newContent: T[]) =>
  arr.splice(0, arr.length, ...(newContent ?? []));

export const indexAt = <T>(arr: T[], index: number) => {
  if (arr.length > index) return arr[index];
  return undefined;
};

export const moveElementInArray = <T>(
  arr: T[],
  oldIndex: number,
  newIndex: number
) => {
  while (oldIndex < 0) {
    oldIndex += arr.length;
  }
  while (newIndex < 0) {
    newIndex += arr.length;
  }
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1;
    while (k--) {
      arr.push(undefined as any);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
};

export const moveElementInArrayToLast = <T>(arr: T[], oldIndex: number) =>
  moveElementInArray(arr, oldIndex, arr.length - 1);
