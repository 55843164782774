import { useObservableRef } from "./useObservableRef.hook";
import { useResizeQuery } from "./useResizeQuery";

export const useCreateResizeQueryWithRef = <
  T extends HTMLElement | SVGElement = HTMLDivElement
>() => {
  const ref = useObservableRef<T>();
  const query = useResizeQuery(ref);
  return { ref, query };
};
