import {
  BoardTheme,
  PieceId,
  PieceIds,
  VariantType,
} from "../@types/app.types";
import { BoardGridTheme } from "../components/Board/Board";
import { PieceCharSetType } from "../components/PieceGraphic/PieceGraphic";
import {
  BoardHeaderFooterElement,
  makePieceSetDesignConfig,
} from "../contexts/studio/studioDesign.contextPartial";
import { MSG } from "../lang/MSG.enum";
import { ColorPalette } from "../utils/colors.utils";

const BoardThemeTaipeiSansWhite: BoardTheme = {
  identifier: "taipei-sans-white",
  name: MSG.themeNameTaipeiSansWhite,
  value: {
    board: {
      backgroundColor: ColorPalette.white,
      borderWidth: 1,
      borderRadius: 0,
      textColor: "#8E9BA8",
      borderColor: ColorPalette.white,
      fontSize: 48,
      fontFamily: "taipeiSans",
      fontWeight: 300,
      inset: {
        userDefined: {
          top: 40,
          left: 60,
          right: 60,
          bottom: 120,
        },
      },
    },
    header: {
      distanceToBoardContent: 30,
      middle: {
        element: BoardHeaderFooterElement.None,
      },
    },
    footer: {
      distanceToBoardContent: 62,
      middle: {
        element: BoardHeaderFooterElement.LogoPZ,
      },
    },
    grid: {
      theme: "default" as BoardGridTheme,
      color: "#8E9BA8",
      opacity: 1,
    },
    columnNumberLabels: {
      enabled: true,
      red: {
        fontSize: 36,
        fontWeight: 400,
      },
      black: {
        fontSize: 36,
        fontWeight: 400,
      },
    },
    red: {
      ...makePieceSetDesignConfig("red"),
      charSet: "TaipeiSans",
    },
    black: {
      ...makePieceSetDesignConfig("black"),
      charSet: "TaipeiSans",
    },
    pieceSet: {
      charSet: "TaipeiSans" as PieceCharSetType,
      charSetVariantDefs: {} as Record<PieceId, VariantType>,
    },
    debugMarker: {
      size: 68,
    },
  },
};

[...PieceIds].forEach(
  i =>
    (BoardThemeTaipeiSansWhite.value.pieceSet!.charSetVariantDefs![
      i as PieceId
    ] = "A")
);

export default BoardThemeTaipeiSansWhite;
