import { reaction } from "mobx";
import { Observer } from "mobx-react-lite";
import React from "react";
import { useOnMount } from "../../../hooks/lifecycle.hooks";
import { useProps, useStore } from "../../../utils/mobx.utils";
import tick from "../../../utils/waiters.utils";
import BaseCheckbox from "../BaseCheckbox/BaseCheckbox";
import BaseIcon from "../BaseIcon/BaseIcon";
// import './BaseSelectorCheckboxGroupOption.scss';

interface BaseSelectorCheckboxGroupOptionProps {
  option: any;
  value: string | number | undefined;
  label: string | number | React.ReactChild;
  disabled: boolean;
  isSelected: boolean;
  toggleSelect: (e: any) => unknown;
}

const BaseSelectorCheckboxGroupOption: React.FC<
  BaseSelectorCheckboxGroupOptionProps
> = props => {
  const p = useProps(props);

  const s = useStore(() => ({
    _value: p.isSelected,
    // get value() {
    //   return props.isSelected
    // },
    // set value(v) {
    //   props.toggleSelect(props.value);
    // }
  }));

  useOnMount(() =>
    reaction(
      () => s._value,
      async () => {
        await tick();
        p.toggleSelect(p.value);
      }
    )
  );

  return (
    <Observer
      children={() => {
        const { disabled, label, option } = p;
        const { icon } = option;
        return (
          <BaseCheckbox
            form={s}
            field="_value"
            disabled={disabled}
            color={p.option.color}
          >
            {icon && <BaseIcon name={icon} />}
            {label}
          </BaseCheckbox>
        );
      }}
    />
  );
};

export default BaseSelectorCheckboxGroupOption;
