import { action } from "mobx";
import { Observer } from "mobx-react-lite";
import React from "react";
import { PieceDef } from "../../constants/pieceSet.constants";
import { useStudioContext } from "../../contexts/studio/studio.context";
import { moveElementInArrayToLast } from "../../utils/arrays.utils";
import { useStore } from "../../utils/mobx.utils";
import PieceGraphic from "../PieceGraphic/PieceGraphic";

type BoardPieceSetProps = {};

const BoardPieceSet: React.FC<BoardPieceSetProps> = props => {
  const c = useStudioContext();
  const s = useStore(() => ({
    get setup() {
      return (
        c.currentMove?.setupAfter || c.currentMove?.setupBefore || c.game?.setup
      );
    },
    get pieceGraphics() {
      return s.setup?.map(pc => (
        <PieceGraphic key={`${pc.id}`} {...pc} onPlacement={handlePieceClick} />
      ));
    },
    get orderedPieceGraphics() {
      const content = [...s.pieceGraphics];
      const index = s.setup.findIndex(
        pc => pc.id === c.selectedPieceId || pc.id === c.lastSelectedPieceId
      );
      moveElementInArrayToLast(content, index);
      return content;
    },
  }));
  const selectPiece = action((pc?: Partial<PieceDef>) => {
    if (pc?.id) c.selectedPiece = pc as PieceDef;
  });
  const handlePieceClick = (pc?: Partial<PieceDef>) => {
    switch (c.mode) {
      case "edit":
      case "design":
        selectPiece(pc);
        break;
      case "export": {
        selectPiece(pc);
        c.mode = "edit";
        break;
      }
      default:
        break;
    }
  };
  return (
    <Observer
      children={() => <g className="BoardPieceSet">{s.orderedPieceGraphics}</g>}
    />
  );
};

export default BoardPieceSet;
