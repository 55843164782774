import { Power4, TweenLite } from "gsap";
import { action, flow, reaction } from "mobx";
import { Observer } from "mobx-react-lite";
import React from "react";
import { VariantOptionDefMap, VariantType } from "../../@types/app.types";
import { PieceDef } from "../../constants/pieceSet.constants";
import {
  useStudioContext,
  useStudioDesign,
} from "../../contexts/studio/studio.context";
import { useOnMount } from "../../hooks/lifecycle.hooks";
import { useObservableRef } from "../../hooks/useObservableRef.hook";
import { keepTruthy } from "../../utils/arrays.utils";
import joinClassName from "../../utils/className.utils";
import { ColorPalette } from "../../utils/colors.utils";
import { makeDisposerController } from "../../utils/disposer.utils";
import { useProps, useStore } from "../../utils/mobx.utils";
import { isNil } from "../../utils/ramdaEquivalents.utils";
import tick from "../../utils/waiters.utils";
import {
  BoardGridPieceGap,
  BoardGridPieceSize,
} from "../Board/BoardGridSymbolDefault";
import { PieceCharSet1941VariantOptionDefMap } from "../PieceChar/PieceCharSet1941Symbols";
import { PieceCharSetTikHianOriginalVariantOptionDefMap } from "../PieceChar/PieceCharSetTikHianOriginalSymbols";
import "./PieceGraphic.scss";

export type PieceBaseType = "circle" | "handDrawn" | "debug";
export type PieceBaseSubtype = "alpha" | "beta";
export type PieceBaseFillType = "outlined" | "filled";
export type PieceUIRole = "piece" | "hotspot";

export type PieceCharSetType =
  | "Genwan"
  | "TaipeiSans"
  | "1941"
  | "TsingWoodbone"
  | "TikHianOriginal";

export type PieceGraphicProps = Partial<PieceDef> & {
  baseType?: PieceBaseType;
  role?: PieceUIRole;
  x?: number;
  y?: number;
  // row?: BoardRowNumber,
  // col?: BoardColNumber,
  onPlacement?: (pc?: Partial<PieceDef>) => void;
  standalone?: boolean;
  size?: number;
};

const PieceGraphic: React.FC<PieceGraphicProps> = props => {
  const c = useStudioContext();
  const d = useStudioDesign();

  const ref = useObservableRef<SVGGElement>();
  const baseRef = useObservableRef<SVGUseElement>();
  const charRef = useObservableRef<SVGUseElement>();
  const highlighterRef = useObservableRef<SVGCircleElement>();

  const p = useProps(props);
  const s = useStore(() => ({
    get isExporting() {
      return c.isExporting;
    },
    get playerIdentifier() {
      return p.playerIdentifier;
    },
    get player() {
      return s.playerIdentifier ? c.game.$[s.playerIdentifier] : undefined;
    },
    get army() {
      return s.player?.army;
    },
    handlePlacement() {
      if (p.standalone && p.id && c.selectedPieceId === p.id) {
        c.selectedPiece = null;
        return;
      }
      const payload = p.id
        ? p
        : p.role === "hotspot"
        ? {
            row: s.row,
            col: s.col,
          }
        : undefined;
      p.onPlacement?.(payload);
    },
    get isCurrentMove() {
      return p.id && c.currentMove?.pieceBefore?.id === p.id;
    },
    get row() {
      return p.row ?? p.row;
    },
    get col() {
      return p.col ?? p.col;
    },
    get size() {
      return p.size ?? d.pieceSet.size;
    },
    get x() {
      if (p.standalone) return 0;
      return isNil(s.col)
        ? (p.x ?? s.size) - s.size / 2
        : d.board.inset.visual.left +
            (s.col * s.size + d.grid.pieceGapX * s.col - s.size / 2);
    },
    get y() {
      if (p.standalone) return 0;
      return isNil(s.row)
        ? (p.y ?? s.size) - s.size / 2
        : d.board.inset.visual.top +
            (s.row * s.size + d.grid.pieceGapY * s.row - s.size / 2);
    },
    displayX: -BoardGridPieceSize,
    displayY: -BoardGridPieceSize,
    // get displayX() {
    //   return (s.isExporting || !c.settings.animatePieceMovement) ? s.x : -BoardGridPieceSize;
    // },
    // get displayY() {
    //   return (s.isExporting || !c.settings.animatePieceMovement) ? s.y : -BoardGridPieceSize;
    // },
    get baseGraphic() {
      switch (s.baseType) {
        case "debug":
          return (
            <use
              xlinkHref={`#${c.id}__PieceBaseSymbolDebug`}
              width={s.size}
              height={s.size}
              x={s.displayX}
              y={s.displayY}
              fill={s.defaultColor}
              ref={baseRef}
            />
          );
        case "handDrawn":
        case "circle":
        default:
          if (!s.army) return null;
          return (
            <use
              xlinkHref={`#${c.id}__PieceBaseSymbol-${c.design[s.army].base}-${
                s.army
              }`}
              width={s.size}
              height={s.size}
              x={s.displayX}
              y={s.displayY}
              ref={baseRef}
            />
          );
      }
    },
    get charGraphic() {
      if (!s.army || !p.id || !p.name) return null;
      return (
        <use
          xlinkHref={`#${c.id}__PieceCharSymbol-${c.design[s.army].charSet}-${
            p.name
          }-${s.army}--${s.variantTypeOrFallback}`}
          x={s.displayX}
          y={s.displayY}
          // data-x={s.x} data-y={s.y}
          width={s.size}
          height={s.size}
          ref={charRef}
        />
      );
    },
    get defaultColor() {
      return s.army
        ? {
            black: ColorPalette.inkBlue,
            red: ColorPalette.red,
          }[s.army]
        : undefined;
    },
    get design() {
      if (s.army) return d[s.army];
      return null;
    },
    // get outlineColor() {
    //   return s.design?.fillColor ?? s.defaultColor;
    // },
    // get fillColor() {
    //   return s.design?.fillColor ?? ColorPalette.white;
    // },
    // get textColor() {
    //   return s.design?.fillColor ?? s.defaultColor;
    // },
    get style() {
      return {
        color: s.defaultColor,
      };
    },
    get isSelected() {
      return Boolean(c.selectedPieceId && c.selectedPieceId === p.id);
    },
    get isVisible() {
      return !p.id || p.alive || p.standalone ? true : false;
    },
    get baseType() {
      return p.baseType ?? (s.army ? d[s.army].base : d.black.base);
    },
    get charSet() {
      return s.army ? d[s.army].charSet : d.black.charSet;
    },
    get definedVariantType() {
      return p.id ? d.pieceSet.charSetVariantDefs[p.id] : "A";
    },
    get variantTypeOrFallback(): VariantType {
      if (!s.army || !p.id) return "A";
      let map: Maybe<VariantOptionDefMap> = undefined;
      switch (s.charSet) {
        case "1941":
          map = PieceCharSet1941VariantOptionDefMap;
          break;
        case "TikHianOriginal":
          map = PieceCharSetTikHianOriginalVariantOptionDefMap;
          break;
        default:
          break;
      }
      return map && p.name
        ? map[s.army][p.name].includes(s.definedVariantType)
          ? s.definedVariantType
          : "A"
        : "A";
    },
    // handleDragStart: action(() => {
    //   console.log('drag start');
    //   if (p.piece) c.draggingPiece = p.piece;
    //   console.log(c.draggingPiece);
    // }),
    // handleDragEnd: action(() => {
    //   if (p.role === 'hotspot') {
    //     console.log('drag end');
    //     c.draggingPiece = null;
    //     s.handlePlacement();
    //   }
    // }),
    getCurrentRefs() {
      return keepTruthy([baseRef.current, charRef.current]);
    },
    get animationDuration() {
      return c.isExporting || !c.settings.animatePieceMovement
        ? 0
        : c.isAutoPlaying
        ? 0.75
        : 0.25;
    },
  }));

  useOnMount(() => {
    if (p.role === "hotspot") return;
    const d = makeDisposerController();
    d.add(
      reaction(
        () => [s.col, s.x],
        (current, prev) => {
          const updateDisplayValue = action(() => {
            s.displayX = s.x;
          });
          if (!current || !prev) {
            updateDisplayValue();
            return;
          }
          const [currentRow, currentX] = current;
          const [prevRow, prevX] = prev;
          if (currentRow === prevRow || isNil(currentX)) {
            updateDisplayValue();
            return;
          }
          try {
            TweenLite.fromTo(
              s.getCurrentRefs(),
              s.animationDuration,
              {
                attr: { x: prevX ?? currentX },
              },
              {
                attr: { x: currentX },
                ease: Power4.easeInOut,
                onComplete: updateDisplayValue,
              }
            );
            TweenLite.fromTo(
              highlighterRef.current,
              s.animationDuration,
              {
                attr: { cx: (prevX ?? currentX) + s.size / 2 },
              },
              {
                attr: { cx: currentX + s.size / 2 },
                ease: Power4.easeInOut,
              }
            );
          } catch (e) {
            TweenLite.set(s.getCurrentRefs(), { attr: { x: currentX } });
            TweenLite.set(highlighterRef.current, {
              attr: { cx: currentX + s.size / 2 },
            });
            updateDisplayValue();
          }
        },
        { fireImmediately: true }
      )
    );
    d.add(
      reaction(
        () => [s.row, s.y],
        (current, prev) => {
          const updateDisplayValue = action(() => {
            s.displayY = s.y;
          });
          if (!current || !prev) {
            updateDisplayValue();
            return;
          }
          const [currentRow, currentY] = current;
          const [prevRow, prevY] = prev;
          if (currentRow === prevRow || isNil(currentY)) {
            updateDisplayValue();
            return;
          }
          try {
            TweenLite.fromTo(
              s.getCurrentRefs(),
              s.animationDuration,
              {
                attr: { y: prevY ?? currentY },
              },
              {
                attr: { y: currentY },
                ease: Power4.easeInOut,
                onComplete: updateDisplayValue,
              }
            );
            TweenLite.fromTo(
              highlighterRef.current,
              s.animationDuration,
              {
                attr: { cy: (prevY ?? currentY) + s.size / 2 },
              },
              {
                attr: { cy: currentY + s.size / 2 },
                ease: Power4.easeInOut,
              }
            );
          } catch (e) {
            TweenLite.set(s.getCurrentRefs(), { attr: { y: currentY } });
            TweenLite.set(highlighterRef.current, {
              attr: { y: currentY + s.size / 2 },
            });
          }
        },
        { fireImmediately: true }
      )
    );
    d.add(
      reaction(
        () => s.isVisible,
        (current, prev) =>
          flow(function* () {
            const shouldHide = !current && prev;
            const shouldShow = current && !prev;
            if (shouldHide) {
              TweenLite.set(ref.current, { opacity: 1 });
              yield tick(
                c.settings.animatePieceMovement && !c.isExporting
                  ? c.isAutoPlaying
                    ? 300
                    : 150
                  : 0
              );
              TweenLite.fromTo(
                ref.current,
                c.settings.animatePieceMovement && !c.isExporting ? 0.25 : 0,
                { opacity: 1 },
                { opacity: 0 }
              );
            } else if (shouldShow) {
              TweenLite.fromTo(
                ref.current,
                c.settings.animatePieceMovement && !c.isExporting ? 0.2 : 0,
                { opacity: 0 },
                { opacity: 1 }
              );
            } else {
              TweenLite.set(ref.current, { opacity: s.isVisible ? 1 : 0 });
            }
          })()
      )
    );
    return d.disposer;
  });

  return (
    <Observer
      children={() => {
        const g = (
          <g
            className="PieceGraphic"
            data-player={p.playerIdentifier}
            data-army={s.army}
            data-name={p.name}
            data-alive={p.alive}
            data-row={p.row}
            data-col={p.col}
            data-selected={s.isSelected}
            data-role={p.role}
            data-visibility={s.isVisible ? "visible" : "hidden"}
            onClick={s.handlePlacement}
            ref={ref}
            // onMouseDown={s.handleDragStart}
            // onMouseUp={s.handleDragEnd}
          >
            {p.role === "hotspot" && (
              <rect
                className="PieceGraphicHotspot"
                x={s.x}
                y={s.y}
                width={s.size}
                height={s.size}
                fill="transparent"
                rx={s.size / 2}
              />
            )}
            {p.role !== "hotspot" &&
              (s.isVisible ||
                (c.settings.animatePieceMovement && !c.isExporting)) && (
                <>
                  {!c.isExporting && (
                    <circle
                      cx={s.x + s.size / 2}
                      cy={s.y + s.size / 2}
                      r={(s.size - BoardGridPieceGap) / 2}
                      fill="transparent"
                      stroke={s.defaultColor}
                      strokeWidth="4"
                      className={joinClassName(
                        "PieceGraphicHighlighterCircle",
                        !c.isExporting &&
                          (s.isCurrentMove || s.isSelected) &&
                          s.baseType !== "debug" &&
                          (p.alive || p.standalone) &&
                          "--visible"
                      )}
                      ref={highlighterRef}
                    />
                  )}
                  <g className="PieceGraphicInner">
                    {s.baseGraphic}
                    {s.charGraphic}
                  </g>
                </>
              )}
          </g>
        );
        return p.standalone ? (
          <svg
            width={s.size}
            height={s.size}
            viewBox={`0 0 ${s.size} ${s.size}`}
          >
            {g}
          </svg>
        ) : (
          g
        );
      }}
    />
  );
};

export default PieceGraphic;
