import { makeIconDef } from "./makeIconDef";

export const PrintIconDef = {
  print: makeIconDef("print", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 3H6V8H18V3ZM5 3V8H4C2.34315 8 1 9.34315 1 11V17C1 17.5523 1.44772 18 2 18H5V21V22H6H18H19V21V18H22C22.5523 18 23 17.5523 23 17V11C23 9.34315 21.6569 8 20 8H19V3V2H18H6H5V3ZM5 15V17H2V11C2 9.89543 2.89543 9 4 9H5H6H18H19H20C21.1046 9 22 9.89543 22 11V17H19V15C19 13.8954 18.1046 13 17 13H7C5.89543 13 5 13.8954 5 15ZM6 21V18V17V15C6 14.4477 6.44772 14 7 14H17C17.5523 14 18 14.4477 18 15V17V18V21H6ZM8.5 19C8.22386 19 8 19.2239 8 19.5C8 19.7761 8.22386 20 8.5 20H11.5C11.7761 20 12 19.7761 12 19.5C12 19.2239 11.7761 19 11.5 19H8.5ZM13 19.5C13 19.2239 13.2239 19 13.5 19H14.5C14.7761 19 15 19.2239 15 19.5C15 19.7761 14.7761 20 14.5 20H13.5C13.2239 20 13 19.7761 13 19.5ZM8.5 15C8.22386 15 8 15.2239 8 15.5C8 15.7761 8.22386 16 8.5 16H9.5C9.77614 16 10 15.7761 10 15.5C10 15.2239 9.77614 15 9.5 15H8.5ZM11 15.5C11 15.2239 11.2239 15 11.5 15H13.5C13.7761 15 14 15.2239 14 15.5C14 15.7761 13.7761 16 13.5 16H11.5C11.2239 16 11 15.7761 11 15.5ZM8.5 17C8.22386 17 8 17.2239 8 17.5C8 17.7761 8.22386 18 8.5 18H13.5C13.7761 18 14 17.7761 14 17.5C14 17.2239 13.7761 17 13.5 17H8.5ZM15 17.5C15 17.2239 15.2239 17 15.5 17C15.7761 17 16 17.2239 16 17.5C16 17.7761 15.7761 18 15.5 18C15.2239 18 15 17.7761 15 17.5ZM20 12C20.5523 12 21 11.5523 21 11C21 10.4477 20.5523 10 20 10C19.4477 10 19 10.4477 19 11C19 11.5523 19.4477 12 20 12Z"
        fill="currentColor"
      />
    ),
    filled: () => (
      <>
        <path
          opacity="0.7"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 11C1 9.34315 2.34315 8 4 8H20C21.6569 8 23 9.34315 23 11V18H19V14H5V18H1V11ZM20 12C20.5523 12 21 11.5523 21 11C21 10.4477 20.5523 10 20 10C19.4477 10 19 10.4477 19 11C19 11.5523 19.4477 12 20 12Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19 2H5V8H19V2ZM5 14H19V22H5V14ZM7 17H15V18H7V17ZM15 15H7V16H15V15ZM7 19H12V20H7V19Z"
          fill="currentColor"
        />
      </>
    ),
  }),
};
