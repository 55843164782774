import { LangCode } from "../constants/lang.enum";
import { ValidPieceNameAbbrSet } from "../constants/pieceSet.constants";
import { MoveType, RelativeLocatorType } from "../models/makeMove.model";
import {
  ValidColumnNumbers,
  fullWidthColumnNumberToHanCharMap,
} from "./rowOrColumn.utils";

export const moveTypeDisplayNameMap = {
  forward: { en: "forward", sc: "进", tc: "進" },
  backward: { en: "backward", sc: "退", tc: "退" },
  sideways: { en: "sideways", sc: "平", tc: "平" },
} as const;
export const moveTypeValidNameMap = {
  forward: ["进", "進"],
  backward: ["退", "退"],
  sideways: ["平"],
} as const;
export const getMoveTypeFromString = (
  s: Sometimes<string>
): Nullable<MoveType> => {
  if (!s) return null;
  for (let [key, value] of Object.entries(moveTypeValidNameMap)) {
    if ((value as readonly string[]).includes(s as any)) return key as MoveType;
  }
  return null;
};
export const getMoveTypeDisplayName = (
  moveType?: Sometimes<MoveType>,
  lang?: LangCode
) => {
  if (!moveType) return null;
  return moveTypeDisplayNameMap[moveType][lang ?? "tc"];
};

export const relativeLocatorValidNameMap = {
  first: ["前"],
  second: ["二"],
  third: ["三"],
  fourth: ["四"],
  fifth: ["五"],
  last: ["後", "后"],
} as const;

export const getRelativeLocatorTypeFromChar = (c: Sometimes<string>) => {
  if (!c) return null;
  for (let [key, value] of Object.entries(relativeLocatorValidNameMap)) {
    if ((value as readonly string[]).includes(c as any))
      return key as RelativeLocatorType;
  }
  return null;
};

export const relativeLocatorDisplayNameMap = {
  first: { en: "first", sc: "前", tc: "前" },
  second: { en: "second", sc: "二", tc: "二" },
  third: { en: "third", sc: "三", tc: "三" },
  fourth: { en: "fourth", sc: "四", tc: "四" },
  fifth: { en: "fifth", sc: "五", tc: "五" },
  last: { en: "last", sc: "后", tc: "後" },
} as const;
export const getRelativeLocatorDisplayName = (
  s: Sometimes<RelativeLocatorType>,
  lang?: Sometimes<LangCode>
) => {
  if (!s) return null;
  return relativeLocatorDisplayNameMap[s][lang ?? "tc"];
};

export const validMoveActionCharSet = "平进進上退下";
export const validRelativeLocatorCharSet = "前後后";
export const validSpecialActionCharSet = "讓先";
export const validMoveRecordCharSet = [
  ValidPieceNameAbbrSet,
  ValidColumnNumbers,
  validMoveActionCharSet,
  validRelativeLocatorCharSet,
  validSpecialActionCharSet,
]
  .flat()
  .join("");

export const systemizeMoveToHanChar = (move: string) =>
  move
    .split("")
    .map(i => (fullWidthColumnNumberToHanCharMap as AnyObject)[i] ?? i)
    .join("");
