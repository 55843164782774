import { action, observable, reaction } from "mobx";
import { LOCALE } from "../lang/LOCALE.enum";
import { RootController } from "./_root.controller";

export const makeSettingsController = () => {
  const c = observable({
    $root: null as Nullable<RootController>,
    $init: action((root: RootController) => {
      c.$root = root;
    }),
    locale: LOCALE.ZH_CN,
  });
  reaction(
    () => c.locale,
    () => document.documentElement.setAttribute("lang", c.locale),
    { fireImmediately: true }
  );
  return c;
};
export type SettingsController = ReturnType<typeof makeSettingsController>;
