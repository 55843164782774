import { makeIconDef } from "./makeIconDef";

export const MapPinIconDef = {
  "map-pin": makeIconDef("map-pin", {
    regular: () => (
      <>
        <path
          d="M17.5494 14.0453L17.5303 14.0663L17.5138 14.0892C17.4629 14.1598 17.3982 14.2358 17.317 14.3171C16.4379 15.1961 14.998 16.7268 13.7744 18.4598C13.08 19.4433 12.4319 20.5238 12.0066 21.6077C11.5848 20.5217 10.9373 19.4384 10.2421 18.452C9.03147 16.7343 7.60579 15.2142 6.724 14.3305L6.72195 14.3284C5.34932 12.9687 4.5 11.084 4.5 9C4.5 4.85786 7.85786 1.5 12 1.5C16.1421 1.5 19.5 4.85786 19.5 9C19.5 10.9432 18.7617 12.7127 17.5494 14.0453ZM12 11.5C13.3807 11.5 14.5 10.3807 14.5 9C14.5 7.61929 13.3807 6.5 12 6.5C10.6193 6.5 9.5 7.61929 9.5 9C9.5 10.3807 10.6193 11.5 12 11.5Z"
          stroke="currentColor"
        />
      </>
    ),
    filled: () => (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 9C20 11.0724 19.212 12.9607 17.9193 14.3818C17.8507 14.4767 17.7682 14.573 17.6705 14.6707C15.9309 16.4103 11.9999 20.7023 11.9999 23.9773V23.9206C11.984 20.6729 8.11515 16.4325 6.37007 14.6836C4.90654 13.2339 4 11.2228 4 9C4 4.58172 7.58172 1 12 1C16.4183 1 20 4.58172 20 9ZM12 12C13.6569 12 15 10.6569 15 9C15 7.34315 13.6569 6 12 6C10.3431 6 9 7.34315 9 9C9 10.6569 10.3431 12 12 12Z"
          fill="currentColor"
        />
      </>
    ),
  }),
};
