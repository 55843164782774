import { Observer } from "mobx-react-lite";
import React from "react";
import {
  useStudioContext,
  useStudioDesign,
} from "../../contexts/studio/studio.context";
import { ColorPalette } from "../../utils/colors.utils";
import BoardHeaderFooterSlotContent from "./BoardHeaderFooterSlotContent";
// import './BoardHeader.scss';

type BoardHeaderProps = {};

const BoardHeader: React.FC<BoardHeaderProps> = props => {
  const c = useStudioContext();
  const d = useStudioDesign();

  return (
    <Observer
      children={() => (
        <g className="BoardHeader">
          {c.debug && (
            <g>
              <rect
                className="BoardFooterDebugAreaMarker"
                x={d.board.borderWidth}
                y={d.board.borderWidth}
                width={d.header.outerWidth}
                height={d.header.outerHeight}
                fill={ColorPalette.red}
                fillOpacity={0.1}
              />
              <line
                x1={0}
                x2={d.board.width}
                y1={d.header.visualCenterYFromTop}
                y2={d.header.visualCenterYFromTop}
                stroke={ColorPalette.red}
              />
            </g>
          )}

          <g className="BoardHeaderStartSlot">
            <BoardHeaderFooterSlotContent
              config={d.header.start}
              position="header"
              slot="start"
            />
          </g>
          <g className="BoardHeaderMiddleSlot">
            <BoardHeaderFooterSlotContent
              config={d.header.middle}
              position="header"
              slot="middle"
            />
          </g>
          <g className="BoardHeaderRighrSlot">
            <BoardHeaderFooterSlotContent
              config={d.header.end}
              position="header"
              slot="end"
            />
          </g>
        </g>
      )}
    />
  );
};

export default BoardHeader;
