import { makeIconDef } from "./makeIconDef";

export const CloseIconDef = {
  close: makeIconDef("close", {
    regular: () => (
      <path
        d="M4 4L20 20M20 4L4 20"
        stroke="currentColor"
        strokeLinecap="round"
      />
    ),
    filled: () => (
      <path
        d="M4 4L20 20M20 4L4 20"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2"
      />
    ),
  }),
};
