import { Observer } from "mobx-react-lite";
import React from "react";
import {
  useStudioContext,
  useStudioDesign,
} from "../../contexts/studio/studio.context";
// import './PieceBaseCircleSymbols.scss';

type PieceBaseCircleSymbolsProps = {};

const PieceBaseCircleSymbols: React.FC<PieceBaseCircleSymbolsProps> = props => {
  const c = useStudioContext();
  const d = useStudioDesign();

  return (
    <Observer
      children={() => (
        <g className="PieceBaseCircleSymbols">
          <symbol
            id={`${c.id}__PieceBaseSymbol-circle-red`}
            width={d.pieceSet.size}
            height={d.pieceSet.size}
            viewBox={`0 0 ${d.pieceSet.size} ${d.pieceSet.size}`}
          >
            <circle
              cx={d.pieceSet.size / 2}
              cy={d.pieceSet.size / 2}
              r={54}
              fill={d.red.fillColor}
              stroke={d.red.outlineColor}
              strokeWidth={d.red.outlineWidth}
            />
          </symbol>
          <symbol
            id={`${c.id}__PieceBaseSymbol-circle-black`}
            width={d.pieceSet.size}
            height={d.pieceSet.size}
            viewBox={`0 0 ${d.pieceSet.size} ${d.pieceSet.size}`}
          >
            <circle
              cx={d.pieceSet.size / 2}
              cy={d.pieceSet.size / 2}
              r={54}
              fill={d.black.fillColor}
              stroke={d.black.outlineColor}
              strokeWidth={d.black.outlineWidth}
            />
          </symbol>
        </g>
      )}
    />
  );
};

export default PieceBaseCircleSymbols;
