import { Observer } from "mobx-react-lite";
import React from "react";
import {
  useStudioContext,
  useStudioDesign,
} from "../../contexts/studio/studio.context";
import { ColorPalette } from "../../utils/colors.utils";
import BoardHeaderFooterSlotContent from "./BoardHeaderFooterSlotContent";
// import './BoardFooter.scss';

type BoardFooterProps = {};

export const BoardLogoRatio = 313 / 62;
export const BoardLogoWidth = 330;
export const BoardLogoHeight = BoardLogoWidth / BoardLogoRatio;

const BoardFooter: React.FC<BoardFooterProps> = props => {
  const c = useStudioContext();
  const d = useStudioDesign();

  return (
    <Observer
      children={() => (
        <g className="BoardFooter">
          {c.debug && (
            <g>
              <rect
                className="BoardFooterDebugAreaMarker"
                x={d.board.borderWidth}
                y={d.footer.yFromTop}
                width={d.footer.outerWidth}
                height={d.footer.outerHeight}
                fill={ColorPalette.red}
                fillOpacity={0.1}
              />
              <line
                x1={0}
                x2={d.board.width}
                y1={d.footer.visualCenterYFromTop}
                y2={d.footer.visualCenterYFromTop}
                stroke={ColorPalette.red}
              />
            </g>
          )}
          <g className="BoardFooterStartSlot">
            <BoardHeaderFooterSlotContent
              config={d.footer.start}
              position="footer"
              slot="start"
            />
          </g>
          <g className="BoardFooterMiddleSlot">
            <BoardHeaderFooterSlotContent
              config={d.footer.middle}
              position="footer"
              slot="middle"
            />
          </g>
          <g className="BoardFooterRightSlot">
            <BoardHeaderFooterSlotContent
              config={d.footer.end}
              position="footer"
              slot="end"
            />
          </g>
        </g>
      )}
    />
  );
};

export default BoardFooter;
