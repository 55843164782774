import { Observer } from "mobx-react-lite";
import React from "react";
import BaseInput from "../../components/_base/BaseInput/BaseInput";
import BaseSelector from "../../components/_base/BaseSelector/BaseSelector";
import { FM, useFM } from "../../constants/lang-components.aliases";
import { useStudioContext } from "../../contexts/studio/studio.context";
import { MSG } from "../../lang/MSG.enum";
import { useStore } from "../../utils/mobx.utils";
import "./GameMetaInfoEditor.scss";
import StudioSidebarUIPanel from "./StudioSidebarUIPanel";

type GameMetaInfoEditorProps = {};

const GameMetaInfoEditor: React.FC<GameMetaInfoEditorProps> = React.memo(
  props => {
    const fm = useFM();
    const c = useStudioContext();
    const s = useStore(() => ({
      get $() {
        return c.game.$;
      },
      get blackNameEditorRow() {
        return (
          <tr key="BTR">
            <th key="BTH">
              <FM
                id={MSG.labelPlayerBlackName}
                defaultMessage="Player Black Name"
                key="PlayerBlackNameLabel"
              />
            </th>
            <td key="BTD">
              <BaseInput
                form={c.game.black}
                field="name"
                key="PlayerBlackNameField"
              />
            </td>
          </tr>
        );
      },
      get redNameEditorRow() {
        return (
          <tr key="RTR">
            <th key="RTH">
              <FM
                id={MSG.labelPlayerRedName}
                defaultMessage="Player Red Name"
                key="PlayerRedNameLabel"
              />
            </th>
            <td key="RTD">
              <BaseInput
                form={c.game.red}
                field="name"
                key="PlayerRedNameField"
              />
            </td>
          </tr>
        );
      },
    }));

    return (
      <Observer
        children={() => {
          return (
            <StudioSidebarUIPanel
              className="GameMetaInfoEditor"
              heading={
                <FM
                  id={MSG.metaInfoEditorSectionTitle}
                  defaultMessage="Game Meta Info Editor"
                />
              }
            >
              <table>
                <tbody>
                  <tr>
                    <th>
                      <FM
                        id={MSG.gameRecordTitle}
                        defaultMessage="Game Record Title"
                      />
                    </th>
                    <td>
                      <BaseInput form={s.$} field="title" />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <FM
                        id={MSG.gameCollectionName}
                        defaultMessage="Collection Name"
                      />
                    </th>
                    <td>
                      <BaseInput form={s.$} field="collection" />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <FM
                        id={MSG.gameTournamentName}
                        defaultMessage="Tournament Name"
                      />
                    </th>
                    <td>
                      <BaseInput form={s.$} field="tournament" />
                    </td>
                  </tr>
                  {c.game.$.playerA.army === "black" && (
                    <>
                      {s.blackNameEditorRow}
                      {s.redNameEditorRow}
                    </>
                  )}
                  {c.game.$.playerA.army === "red" && (
                    <>
                      {s.redNameEditorRow}
                      {s.blackNameEditorRow}
                    </>
                  )}
                  <tr>
                    <th>
                      <FM
                        id={MSG.labelPlayerArmyAssignment}
                        defaultMessage="Army Assignment"
                      />
                    </th>
                    <td>
                      <BaseSelector
                        form={c.game}
                        field="hasInvertedPlayerArmyAssignment"
                        options={[
                          {
                            label: fm({
                              id: MSG.labelBlackAtNorth,
                              defaultMessage: "Black/Red",
                            }),
                            value: false,
                          },
                          {
                            label: fm({
                              id: MSG.labelRedAtNorth,
                              defaultMessage: "Red/Black",
                            }),
                            value: true,
                          },
                        ]}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <FM
                        id={MSG.labelPlayerFirstHandSelector}
                        defaultMessage="First Move"
                      />
                    </th>
                    <td>
                      <BaseSelector
                        form={c.game.$}
                        field="firstHandArmy"
                        options={[
                          {
                            label: fm({
                              id: MSG.labelPlayerRed,
                              defaultMessage: "Red",
                            }),
                            value: "red",
                          },
                          {
                            label: fm({
                              id: MSG.labelPlayerBlack,
                              defaultMessage: "Black",
                            }),
                            value: "black",
                          },
                        ]}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </StudioSidebarUIPanel>
          );
        }}
      />
    );
  }
);

export default GameMetaInfoEditor;
