import { EN_GB_MESSAGES } from "./en-us.lang";
import { LOCALE } from "./LOCALE.enum";
import { ZH_CN_MESSAGES } from "./zh-cn.lang";
import { ZH_HK_MESSAGES } from "./zh-hk.lang";

export const LOCALE_MESSAGE_MAP = {
  [LOCALE.EN_US]: EN_GB_MESSAGES,
  [LOCALE.ZH_CN]: ZH_CN_MESSAGES,
  [LOCALE.ZH_HK]: ZH_HK_MESSAGES,
};
