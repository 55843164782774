import md5 from "md5";
import { reaction } from "mobx";
import { Observer } from "mobx-react-lite";
import React, { CSSProperties } from "react";
import { useIntl } from "react-intl";
import { useControllers } from "../../hooks/controller.hooks";
import { useOnMount } from "../../hooks/lifecycle.hooks";
import Studio from "../../pages/Studio/Studio";
import { useStore } from "../../utils/mobx.utils";
import BaseInput from "../_base/BaseInput/BaseInput";
import TitleManager from "../_base/TitleManager/TitleManager";
import "./AppContainer.scss";

type AppContainerProps = {};

const AppContainer: React.FC<AppContainerProps> = props => {
  const { UI } = useControllers();
  const intl = useIntl();
  const s = useStore(() => ({
    pin: localStorage.getItem("pin") || "",
    get encodedCode() {
      return md5(s.pin);
    },
    get pinIsCorrect() {
      return s.encodedCode === "e2db197e07804d57f3af6c3bd1368f5e";
    },
    get computedGlobalStyles() {
      return {
        "--vw": UI.vw + "px",
        "--vh": UI.vh + "px",
      } as CSSProperties;
    },
  }));
  useOnMount(() => {
    UI.intl = intl;
    return reaction(
      () => s.pin,
      value => localStorage.setItem("pin", value)
    );
  });
  return (
    <Observer
      children={() => (
        <div className="AppContainer" style={s.computedGlobalStyles}>
          {s.pinIsCorrect ? (
            <Studio />
          ) : (
            <div className="PasswordScreen">
              <div className="PasswordScreenInner">
                <BaseInput
                  form={s}
                  field="pin"
                  placeholder="Enter Code..."
                  Label="Password Protected Area"
                />
              </div>
            </div>
          )}
          <TitleManager />
        </div>
      )}
    />
  );
};

export default AppContainer;
