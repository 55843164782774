import deepFreeze from "deep-freeze";
import { PlayerArmy, PlayerIdentifier } from "../models/makePlayer.model";

export const ValidColIndexSet = [0, 1, 2, 3, 4, 5, 6, 7, 8] as const;
export type ValidColIndex = ArrayElementType<typeof ValidColIndexSet>;
export const ValidRowIndexSet = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9] as const;
export type ValidRowIndex = ArrayElementType<typeof ValidRowIndexSet>;

export const isValidColIndex = (n: number): n is ValidColIndex =>
  ValidColIndexSet.includes(n as any);
export const isValidRowIndex = (n: number): n is ValidRowIndex =>
  ValidRowIndexSet.includes(n as any);

export const hanCharToHalfWidthNumberMap = {
  一: "1",
  二: "2",
  三: "3",
  四: "4",
  五: "5",
  六: "6",
  七: "7",
  八: "8",
  九: "9",
} as const;

export type HanCharNumber = keyof typeof hanCharToHalfWidthNumberMap;

export const fullWidthColumnNumberToHanCharMap = {
  "１": "一",
  "２": "二",
  "３": "三",
  "４": "四",
  "５": "五",
  "６": "六",
  "７": "七",
  "８": "八",
  "９": "九",
} as const;

export type FullWidthNumber = keyof typeof fullWidthColumnNumberToHanCharMap;

export const halfWidthColumnNumberToHanCharMap = {
  "1": "一",
  "2": "二",
  "3": "三",
  "4": "四",
  "5": "五",
  "6": "六",
  "7": "七",
  "8": "八",
  "9": "九",
} as const;

export const getHanCharFromHalfWidthNumber = (
  n: number | string
): HanCharNumber | null => {
  return Reflect.get(halfWidthColumnNumberToHanCharMap, n + "") ?? null;
};

export const halfWidthColumnNumberToFullWidthMap = {
  "1": "１",
  "2": "２",
  "3": "３",
  "4": "４",
  "5": "５",
  "6": "６",
  "7": "７",
  "8": "８",
  "9": "９",
} as const;

export const getFullWidthNumberFromHalfWidthNumber = (
  n: number | string
): FullWidthNumber | null => {
  return Reflect.get(halfWidthColumnNumberToFullWidthMap, n + "") ?? null;
};

export const fullWidthColumnNumberToHalfWidthMap = {
  "１": "1",
  "２": "2",
  "３": "3",
  "４": "4",
  "５": "5",
  "６": "6",
  "７": "7",
  "８": "8",
  "９": "9",
} as const;

export const getFullWidthNumbers = () =>
  Object.values(halfWidthColumnNumberToFullWidthMap);
export const fullWidthNumbers = deepFreeze(getFullWidthNumbers());
export const isFullWidthNumber = (
  n: Sometimes<string>
): n is ArrayElementType<typeof fullWidthNumbers> =>
  Boolean(n && fullWidthNumbers.includes(n as any));

export const getHanNumbers = () =>
  Object.values(halfWidthColumnNumberToHanCharMap);
export const hanNumbers = deepFreeze(getHanNumbers());
export const isHanNumber = (
  s: Sometimes<string>
): s is ArrayElementType<typeof hanNumbers> =>
  Boolean(s && hanNumbers.includes(s as any));

export const getNormalWidthNumbers = () =>
  Object.values(fullWidthColumnNumberToHalfWidthMap);
export const normalWidthNumbers = deepFreeze(getNormalWidthNumbers());
export const isNormalWidthNumber = (
  n: Sometimes<string>
): n is ArrayElementType<typeof normalWidthNumbers> =>
  Boolean(n && normalWidthNumbers.includes(n as any));

export const ValidColumnNumbers = [
  Object.values(hanNumbers),
  Object.values(fullWidthNumbers),
  Object.values(normalWidthNumbers),
].flat();

export const columnNumberValidDisplayValueMap = {
  "0": ["1", "九", "１"],
  "1": ["2", "八", "２"],
  "2": ["3", "七", "３"],
  "3": ["4", "六", "４"],
  "4": ["5", "五", "５"],
  "5": ["6", "四", "６"],
  "6": ["7", "三", "７"],
  "7": ["8", "二", "８"],
  "8": ["9", "一", "９"],
} as const;

export const columnNumberValidDisplayValueMapReversed = {
  "8": ["1", "九", "１"],
  "7": ["2", "八", "２"],
  "6": ["3", "七", "３"],
  "5": ["4", "六", "４"],
  "4": ["5", "五", "５"],
  "3": ["6", "四", "６"],
  "2": ["7", "三", "７"],
  "1": ["8", "二", "８"],
  "0": ["9", "一", "９"],
} as const;

export const getColumnNumberFromChar = (
  c?: Sometimes<string>,
  playerIdentifier?: Sometimes<PlayerIdentifier>,
  playerArmy?: Sometimes<PlayerArmy>
) => {
  const reversed =
    (playerIdentifier === "playerA" && playerArmy === "red") ||
    (playerIdentifier === "playerB" && playerArmy === "black");
  if (!c) return null;
  for (let [key, value] of Object.entries(
    reversed
      ? columnNumberValidDisplayValueMapReversed
      : columnNumberValidDisplayValueMap
  )) {
    if ((value as readonly string[]).includes(c))
      return +key as unknown as ValidColIndex;
  }
  return null;
};

export const numberNameMap = {
  "1": ["一", "１", "1"],
  "2": ["二", "２", "2"],
  "3": ["三", "３", "3"],
  "4": ["四", "４", "4"],
  "5": ["五", "５", "5"],
  "6": ["六", "６", "6"],
  "7": ["七", "７", "7"],
  "8": ["八", "８", "8"],
  "9": ["九", "９", "9"],
} as const;

export const getNumberFromChar = (c?: Sometimes<string>) => {
  if (!c) return null;
  for (let [key, value] of Object.entries(numberNameMap)) {
    if ((value as readonly string[]).includes(c))
      return +key as unknown as ValidColIndex;
  }
  return null;
};

export const columnNumberDefaultDisplayValueMap = {
  "0": ["九", "１", "0"],
  "1": ["八", "２", "1"],
  "2": ["七", "３", "2"],
  "3": ["六", "４", "3"],
  "4": ["五", "５", "4"],
  "5": ["四", "６", "5"],
  "6": ["三", "７", "6"],
  "7": ["二", "８", "7"],
  "8": ["一", "９", "8"],
} as const;
export const columnNumberDefaultDisplayValueMapReversed = {
  "8": ["九", "１", "0"],
  "7": ["八", "２", "1"],
  "6": ["七", "３", "2"],
  "5": ["六", "４", "3"],
  "4": ["五", "５", "4"],
  "3": ["四", "６", "5"],
  "2": ["三", "７", "6"],
  "1": ["二", "８", "7"],
  "0": ["一", "９", "8"],
} as const;

export const getColumnDisplayName = (
  ci: Sometimes<ValidColIndex>,
  playerIdentifier: Sometimes<PlayerIdentifier>,
  playerArmy: Sometimes<PlayerArmy>
): HanCharNumber | FullWidthNumber | null => {
  const reversed =
    (playerIdentifier === "playerA" && playerArmy === "red") ||
    (playerIdentifier === "playerB" && playerArmy === "black");
  return (
    (reversed
      ? columnNumberDefaultDisplayValueMapReversed
      : (columnNumberDefaultDisplayValueMap as AnyObject))[ci + ""][
      playerArmy === "red" ? 0 : 1
    ] ?? null
  );
};
