import { action, flow, observable } from "mobx";
import React, { useContext } from "react";
import { BoardTheme } from "../../@types/app.types";
import { Game, makeGame } from "../../models/makeGame.model";
import { Move } from "../../models/makeMove.model";
import BoardThemeTikHianOriginal from "../../themes/tikhian-original";
import { indexAt } from "../../utils/arrays.utils";
import { recursiveMergeWithTypeCast } from "../../utils/objects.utils";
import { getRandomNumericString } from "../../utils/random.utils";
import tick from "../../utils/waiters.utils";
import { makeDefaultDesignConfig } from "./studioDesign.contextPartial";

export type StudioUIMode = "edit" | "design" | "export";

export const makeDefaultStudioContext = (game?: Game) => {
  const visitedIndices = [] as number[];

  const s = observable({
    get id() {
      return s.game.$.id ?? getRandomNumericString(6);
    },
    mode: "design" as StudioUIMode,
    game: game ?? makeGame(),
    design: makeDefaultDesignConfig(),
    applyTheme: action((theme: BoardTheme) => {
      console.log(`Applying theme "${theme.identifier}"`);
      recursiveMergeWithTypeCast(s.design, theme.value);
    }),
    currentMoveIndex: 0,
    get pageNumber() {
      return s.currentMoveIndex + 1;
    },
    get pageCountTotal() {
      return s.game.moves.length;
    },
    setCurrentMoveIndex: flow(function* (i: number) {
      if (!visitedIndices.includes(i)) {
        let distance = i - s.currentMoveIndex;
        if (distance > 100) {
          while (distance > 100) {
            s.currentMoveIndex = s.currentMoveIndex + Math.min(100, distance);
            distance = i - s.currentMoveIndex;
            yield tick();
          }
        }
      }
      s.currentMoveIndex = i;
      visitedIndices.push(i);
    }),
    lastSelectedPieceId: null as Nullable<string>,
    selectedPieceId: null as Nullable<string>,
    get selectedPiece() {
      return (
        s.currentMove?.setupBefore?.find(pc => pc.id === s.selectedPieceId) ??
        null
      );
    },
    set selectedPiece(pc) {
      s.lastSelectedPieceId = s.selectedPieceId;
      if (pc) {
        if (s.selectedPieceId === pc.id) s.selectedPieceId = null;
        else s.selectedPieceId = pc.id;
      } else s.selectedPieceId = null;
    },
    draggingPieceId: null as Nullable<string>,
    get draggingPiece() {
      return (
        s.currentMove?.setupBefore?.find(pc => pc.id === s.draggingPieceId) ??
        null
      );
    },
    set draggingPiece(pc) {
      if (pc) {
        if (s.draggingPieceId === pc.id) s.draggingPieceId = null;
        else s.draggingPieceId = pc.id;
      } else s.draggingPieceId = null;
    },
    debug: false,
    isExporting: false,
    get currentMove(): Sometimes<Move> {
      return indexAt(s.game.moves, s.currentMoveIndex);
    },
    get currentRoundIndex() {
      return Math.floor((s.currentMoveIndex + 1) / 2);
    },
    get currentMoveIsLastMove() {
      return s.currentMoveIndex >= s.game.moves.length - 1;
    },
    settings: {
      animatePieceMovement: true,
    },
    isAutoPlaying: false,
  });

  s.applyTheme(BoardThemeTikHianOriginal);

  return s;
};

export const DefaultStudioContext = makeDefaultStudioContext();

export const StudioContext = React.createContext(DefaultStudioContext);

export const useStudioContext = () => useContext(StudioContext);
export const useStudioDesign = () => useContext(StudioContext).design;
