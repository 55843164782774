import { Observer } from "mobx-react-lite";
import React, { ReactElement } from "react";
import { getFont } from "../../constants/fonts";
import { useFM } from "../../constants/lang-components.aliases";
import {
  useStudioContext,
  useStudioDesign,
} from "../../contexts/studio/studio.context";
import {
  BoardHeaderFooterElement,
  HeaderFooterSlotConfig,
} from "../../contexts/studio/studioDesign.contextPartial";
import { MSG } from "../../lang/MSG.enum";
import { useProps, useStore } from "../../utils/mobx.utils";
import { BoardLogoHeight, BoardLogoWidth } from "./BoardFooter";

type BoardHeaderFooterSlotContentProps = {
  config: HeaderFooterSlotConfig;
  position: "header" | "footer";
  slot: "start" | "end" | "middle";
};

const BoardHeaderFooterSlotContent: React.FC<
  BoardHeaderFooterSlotContentProps
> = props => {
  const p = useProps(props);
  const c = useStudioContext();
  const d = useStudioDesign();
  const fm = useFM();
  const s = useStore(() => ({
    get textX() {
      switch (p.slot) {
        case "start":
          return d.board.inset.computed.left + d.grid.inset.left * 0.25;
        case "end":
          return (
            d.board.inset.computed.left +
            d.grid.outerWidth -
            +d.grid.inset.right * 0.25
          );
        case "middle":
        default:
          return d.footer.outerWidth / 2 + d.board.borderWidth;
      }
    },
    get textY() {
      switch (p.position) {
        case "header":
          return d.header.visualCenterYFromTop + d.board.fontSize / 2;
        case "footer":
        default:
          return d.footer.visualCenterYFromTop + d.board.fontSize / 2;
      }
    },
    get renderText() {
      return (children: ReactElement | string | number) => (
        <Observer
          children={() => {
            return (
              <text
                x={s.textX}
                y={s.textY}
                fill={d.board.textColor}
                fontSize={d.board.fontSize}
                fontFamily={getFont(d.board.fontFamily)}
                fontWeight={d.board.fontWeight}
                textAnchor={p.slot}
                children={children}
              />
            );
          }}
        />
      );
    },
    get logoX() {
      switch (p.slot) {
        case "start":
          return s.textX;
        case "end":
          return s.textX - BoardLogoWidth;
        case "middle":
        default:
          return (
            (d[p.position].outerWidth - BoardLogoWidth) / 2 +
            d.board.borderWidth
          );
      }
    },
    get logoY() {
      switch (p.position) {
        case "header":
          return d[p.position].visualCenterYFromTop - BoardLogoHeight * 0.4;
        case "footer":
        default:
          return d.footer.visualCenterYFromTop - BoardLogoHeight * 0.4;
      }
    },
    get headerIsBlack() {
      return c.game.$.playerA.army === "black";
    },
    get footerIsRed() {
      return c.game.$.playerB.army === "red";
    },
    get headerPlayerColorLabel() {
      return s.headerIsBlack
        ? MSG.boardPlayerColorLabelBlack
        : MSG.boardPlayerColorLabelRed;
    },
    get footerPlayerColorLabel() {
      return s.footerIsRed
        ? MSG.boardPlayerColorLabelRed
        : MSG.boardPlayerColorLabelBlack;
    },
    get headerPlayerColorLabelWithSpace() {
      return s.headerIsBlack
        ? MSG.boardPlayerColorLabelBlackWithSpace
        : MSG.boardPlayerColorLabelRedWithSpace;
    },
    get footerPlayerColorLabelWithSpace() {
      return s.footerIsRed
        ? MSG.boardPlayerColorLabelRedWithSpace
        : MSG.boardPlayerColorLabelBlackWithSpace;
    },
  }));
  return (
    <Observer
      children={() => {
        switch (p.config.element) {
          case BoardHeaderFooterElement.LogoPZ:
            return (
              <use
                xlinkHref={`#${c.id}__LogoSymbolP`}
                x={s.logoX}
                y={s.logoY}
                width={BoardLogoWidth}
                height={BoardLogoHeight}
                opacity={0.62}
                fill={d.board.textColor}
              />
            );
          case BoardHeaderFooterElement.GameRecordTitle:
            return s.renderText(c.game.$.title);
          case BoardHeaderFooterElement.GameCollectionName:
            return s.renderText(c.game.$.collection ?? "");
          case BoardHeaderFooterElement.GameTournamentName:
            return s.renderText(c.game.$.tournament ?? "");
          case BoardHeaderFooterElement.PlayerArmy:
            return s.renderText(
              fm(
                p.position === "header"
                  ? {
                      id: s.headerPlayerColorLabelWithSpace,
                      defaultMessage: s.headerIsBlack
                        ? "Player Black"
                        : "Player Red",
                    }
                  : {
                      id: s.footerPlayerColorLabelWithSpace,
                      defaultMessage: s.footerIsRed
                        ? "Player Red"
                        : "Player Black",
                    }
              )
            );
          case BoardHeaderFooterElement.PlayerName:
            return s.renderText(
              p.position === "header"
                ? fm({
                    id: s.headerPlayerColorLabel,
                    defaultMessage: s.headerIsBlack ? "Black" : "Red",
                  }) +
                    " " +
                    (c.game.$.playerA.name ||
                      fm({ id: MSG.unnamedPlayer, defaultMessage: "No Name" }))
                : fm({
                    id: s.footerPlayerColorLabel,
                    defaultMessage:
                      s.footerPlayerColorLabel === MSG.boardPlayerColorLabelRed
                        ? "Red"
                        : "Black",
                  }) +
                    " " +
                    (c.game.$.playerB.name ||
                      fm({ id: MSG.unnamedPlayer, defaultMessage: "No Name" }))
            );
          case BoardHeaderFooterElement.PageNumber:
            return s.renderText(c.pageNumber);
          case BoardHeaderFooterElement.PageNumberWithTotal:
            return s.renderText(
              `${
                c.pageNumber > c.pageCountTotal
                  ? c.pageCountTotal
                  : c.pageNumber
              } / ${c.pageCountTotal}`
            );
          case BoardHeaderFooterElement.NthRoundCounter:
            return s.renderText(
              fm(
                c.currentRoundIndex === 0
                  ? {
                      id: MSG.gameOpening,
                      defaultMessage: "Game Opening",
                    }
                  : {
                      id: MSG.nthRound,
                      defaultMessage: "nth Round",
                    },
                {
                  nth: c.currentRoundIndex + "",
                }
              )
            );
          case BoardHeaderFooterElement.CustomText:
            return s.renderText(p.config.customText);
          default:
            return null;
        }
      }}
    />
  );
};

export default BoardHeaderFooterSlotContent;
