import { action } from "mobx";
import { Observer } from "mobx-react-lite";
import React from "react";
import { PieceDef } from "../../constants/pieceSet.constants";
import { useStudioContext } from "../../contexts/studio/studio.context";
import { useStore } from "../../utils/mobx.utils";
import { getAlivePieceAtCoord } from "../../utils/piece.utils";
import { isNil } from "../../utils/ramdaEquivalents.utils";
import PieceGraphic from "../PieceGraphic/PieceGraphic";
import { BoardColNumber, BoardRowNumber } from "./Board";
import "./BoardPieceHotspotSet.scss";

type BoardPieceHotspotSetProps = {};

const BoardPieceHotspotSet: React.FC<BoardPieceHotspotSetProps> = React.memo(
  props => {
    const c = useStudioContext();

    const s = useStore(() => ({
      handleHotspotClick: action((pc?: Partial<PieceDef>) => {
        if (c.selectedPiece && pc) {
          if (!isNil(pc.row) && !isNil(pc.col) && c.currentMove?.setupBefore) {
            const existingPieceInDestination = getAlivePieceAtCoord(
              c.currentMove.setupBefore,
              pc.col,
              pc.row
            );
            if (existingPieceInDestination) {
              if (existingPieceInDestination.id === c.selectedPieceId) {
                if (existingPieceInDestination.alive) {
                  c.selectedPiece = null;
                  return;
                }
              }
              existingPieceInDestination.alive = false;
            }
            c.selectedPiece.row = pc.row;
            c.selectedPiece.col = pc.col;
            c.selectedPiece.alive = true;
            c.selectedPiece = null;
          }
        }
      }),
    }));

    return (
      <Observer
        children={() => (
          <g className="BoardPieceHotspotSet">
            {Array(10)
              .fill(null)
              .map((n, row) => {
                return Array(9)
                  .fill(null)
                  .map((n, col) => {
                    return (
                      <PieceGraphic
                        role="hotspot"
                        key={`row-${row}-col-${col}`}
                        row={row as BoardRowNumber}
                        col={col as BoardColNumber}
                        onPlacement={s.handleHotspotClick}
                      />
                    );
                  });
              })}
          </g>
        )}
      />
    );
  }
);

export default BoardPieceHotspotSet;
