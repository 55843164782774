import { action } from "mobx";
import { Observer } from "mobx-react-lite";
import React, { CSSProperties } from "react";
import Board from "../../components/Board/Board";
import GameMoveListUI from "../../components/GameMoveListUI/GameMoveListUI";
import StudioTitleBar from "../../components/StudioTitleBar/StudioTitleBar";
import {
  StudioContext,
  makeDefaultStudioContext,
  useStudioContext,
} from "../../contexts/studio/studio.context";
import { useOnMount } from "../../hooks/lifecycle.hooks";
import { ColorPalette, withOpacity } from "../../utils/colors.utils";
import { isDevelopment } from "../../utils/env.utils";
import { useStore } from "../../utils/mobx.utils";
import DesignCustomizer from "./DesignCustomizer/DesignCustomizer";
import GameEditorUI from "./GameEditorUI";
import GameExporterUI from "./GameExporterUI";
import GameMetaInfoEditor from "./GameMetaInfoEditor";
import "./Studio.scss";
import StudioModeSwitcher from "./StudioModeSwitcher";

type StudioProps = {};

const Studio: React.FC<StudioProps> = React.memo(props => {
  const c = useStudioContext();

  const s = useStore(() => ({
    context: makeDefaultStudioContext(),
    get boardEdgeUnclear() {
      return (
        s.context.design.board.backgroundColor === ColorPalette.white &&
        (s.context.design.board.borderColor === ColorPalette.white ||
          s.context.design.board.borderColor === "transparent")
      );
    },
    get contentAreaStyle(): CSSProperties {
      return {
        backgroundColor: s.boardEdgeUnclear
          ? withOpacity(ColorPalette.gray, 0.1)
          : ColorPalette.white,
      };
    },
  }));

  useOnMount(() => {
    // const persistedContext = localStorage.getItem("StudioContext");
    // if (persistedContext) {
    //   runInAction(() => {
    //     console.log("merging context", JSON.parse(persistedContext));
    //     recursiveMergeWithTypeCast(s.context, JSON.parse(persistedContext));
    //   });
    // }
    // const d = makeDisposerController();
    // d.add(
    //   reaction(
    //     () => JSON.stringify(s.context),
    //     stringifiedContext => {
    //       localStorage.setItem("StudioContext", stringifiedContext);
    //     }
    //   )
    // );
    if (isDevelopment) {
      Reflect.set(window, "context", s.context);
    }
  });

  const handleContentAreaBackgroundHotSpotClick = action(() => {
    switch (c.mode) {
      case "edit":
      case "design":
        c.selectedPiece = null;
        break;
      default:
        return;
    }
  });

  return (
    <Observer
      children={() => (
        <StudioContext.Provider value={s.context}>
          <StudioTitleBar />

          <div
            className="Studio"
            data-mode={s.context.mode}
            data-game-id={s.context.game.$.id}
            data-selected-piece-id={s.context.selectedPieceId}
            data-has-selected-piece={!!s.context.selectedPieceId}
            data-is-exporting={s.context.isExporting}
            data-is-auto-playing={s.context.isAutoPlaying}
            data-piece-movement-animation={
              s.context.settings.animatePieceMovement ? "on" : "off"
            }
          >
            <header className="StudioHeader">
              <GameMetaInfoEditor />
              <GameMoveListUI />
            </header>

            <div className="StudioContentArea" style={s.contentAreaStyle}>
              <span
                className="StudioContentAreaBackgroundHotSpot"
                onClick={handleContentAreaBackgroundHotSpotClick}
              />
              <figure className="StudioContentAreaFigure">
                <Board game={s.context.game} debug={s.context.debug} />
              </figure>
            </div>

            <aside className="StudioAside">
              <StudioModeSwitcher />
              <div className="StudioAsideInner">
                {s.context.mode === "design" && <DesignCustomizer />}
                {s.context.mode === "edit" && <GameEditorUI />}
                {s.context.mode === "export" && (
                  <GameExporterUI game={s.context.game} />
                )}
              </div>
            </aside>
          </div>
        </StudioContext.Provider>
      )}
    />
  );
});

export default Studio;
