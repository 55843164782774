import { Observer } from "mobx-react-lite";
import React from "react";
import { FM } from "../../constants/lang-components.aliases";
import { MSG } from "../../lang/MSG.enum";
import { Move } from "../../models/makeMove.model";
import { useProps } from "../../utils/mobx.utils";
import { capitalizeFirstLetter } from "../../utils/string.utils";
import BaseInput from "../_base/BaseInput/BaseInput";
import "./MoveEditor.scss";

type MoveEditorProps = {
  move: Move;
};

const MoveEditor: React.FC<MoveEditorProps> = props => {
  const p = useProps(props);
  return (
    <Observer
      children={() => (
        <div className="MoveEditor" data-player={p.move.army}>
          <p className="MoveEditorPlayerMark">
            <strong>
              <FM
                id={
                  p.move.army === "black"
                    ? MSG.labelPlayerBlack
                    : MSG.labelPlayerRed
                }
                defaultMessage={`Player ${capitalizeFirstLetter(p.move.army)}`}
              />
            </strong>
          </p>
          <h2>
            <BaseInput
              form={p.move}
              field="def"
              type="contentEditable"
              applyOnBlur
            />
          </h2>
          <div className="MoveEditorDisplayDefRenderer">
            <p>
              规范化繁体：<strong>{p.move.displayDef}</strong>
            </p>
          </div>
        </div>
      )}
    />
  );
};

export default MoveEditor;
