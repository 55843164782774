import { makeIconDef } from "./makeIconDef";

export const UnlockIconDef = {
  unlock: makeIconDef("unlock", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4C10.2525 4 8.76679 5.12056 8.22211 6.68231C8.1184 6.9797 7.78825 7.15736 7.4997 7.03112V7.03112C7.28449 6.93697 7.16441 6.70187 7.23584 6.47808C7.8797 4.46086 9.76925 3 12 3C14.7614 3 17 5.23858 17 8V9C17 9.55228 17.4477 10 18 10V10V10C18.5523 10 19 10.4477 19 11V11V17C19 18.6569 17.6569 20 16 20H8C6.34315 20 5 18.6569 5 17V11V11C5 10.4477 5.44772 10 6 10V10H12H15C15.5523 10 16 9.55228 16 9V8C16 5.79086 14.2091 4 12 4ZM17 11H16H12H7C6.44772 11 6 11.4477 6 12V17C6 18.1046 6.89543 19 8 19H16C17.1046 19 18 18.1046 18 17V12C18 11.4477 17.5523 11 17 11V11ZM12 17C11.4477 17 11 16.5523 11 16V14C11 13.4477 11.4477 13 12 13V13C12.5523 13 13 13.4477 13 14V16C13 16.5523 12.5523 17 12 17V17Z"
        fill="currentColor"
      />
    ),
    filled: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 5C10.8964 5 9.93193 5.59594 9.41092 6.48357C9.08989 7.0305 8.42063 7.43402 7.83962 7.17983V7.17983C7.42732 6.99945 7.1933 6.5464 7.36179 6.12911C8.1025 4.29454 9.90007 3 12 3C14.7614 3 17 5.23858 17 8V9C17 9.55228 17.4477 10 18 10V10C18.5523 10 19 10.4477 19 11V17C19 18.6569 17.6569 20 16 20H8C6.34315 20 5 18.6569 5 17V11C5 10.4477 5.44772 10 6 10H12H14C14.5523 10 15 9.55228 15 9V8C15 6.34315 13.6569 5 12 5ZM12 17C11.4477 17 11 16.5523 11 16V14C11 13.4477 11.4477 13 12 13V13C12.5523 13 13 13.4477 13 14V16C13 16.5523 12.5523 17 12 17V17Z"
        fill="currentColor"
      />
    ),
  }),
};
