import { makeIconDef } from "./makeIconDef";

export const CopyIconDef = {
  copy: makeIconDef("copy", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 5H5V16H9V17H5C4.44772 17 4 16.5523 4 16V5C4 4.44772 4.44772 4 5 4H13C13.5523 4 14 4.44772 14 5V6H13V5ZM11 8H19V19H11V8ZM10 8C10 7.44772 10.4477 7 11 7H19C19.5523 7 20 7.44772 20 8V19C20 19.5523 19.5523 20 19 20H11C10.4477 20 10 19.5523 10 19V8Z"
        fill="currentColor"
      />
    ),
  }),
};
