import { makeIconDef } from "./makeIconDef";

export const ZoomIconDef = {
  zoom: makeIconDef("zoom", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5 3V2.5H21L15.5 2.5V3.5L19.7929 3.5L14.1464 9.14645L14.8536 9.85355L20.5 4.20711V8.5L21.5 8.5V3ZM3 2.5H2.5V3V8.5H3.5V4.20711L9.14645 9.85355L9.85355 9.14645L4.20711 3.5H8.5V2.5H3ZM21.5 21.5H21H15.5V20.5H19.7929L14.1464 14.8536L14.8536 14.1464L20.5 19.7929V15.5H21.5V21V21.5ZM2.5 21L2.5 21.5H3H8.5V20.5H4.20711L9.85355 14.8536L9.14645 14.1464L3.5 19.7929L3.5 15.5H2.5L2.5 21Z"
        fill="currentColor"
      />
    ),
    filled: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 3V2L21 2L15.5 2V4L18.5858 4L13.7929 8.79289L15.2071 10.2071L20 5.41421V8.5L22 8.5V3ZM3 2H2V3V8.5H4V5.41421L8.79289 10.2071L10.2071 8.79289L5.41421 4H8.5V2H3ZM22 22H21H15.5V20H18.5858L13.7929 15.2071L15.2071 13.7929L20 18.5858V15.5H22V21V22ZM2 21L2 22H3H8.5V20H5.41421L10.2071 15.2071L8.79289 13.7929L4 18.5858L4 15.5H2L2 21Z"
        fill="currentColor"
      />
    ),
  }),
};
