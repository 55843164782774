import { Observer } from "mobx-react-lite";
import React from "react";
import BaseCheckbox from "../../../components/_base/BaseCheckbox/BaseCheckbox";
import BaseInput from "../../../components/_base/BaseInput/BaseInput";
import ShadedBlock from "../../../components/_base/ShadedBlock/ShadedBlock";
import { FM, useFM } from "../../../constants/lang-components.aliases";
import { useStudioDesign } from "../../../contexts/studio/studio.context";
import { MSG } from "../../../lang/MSG.enum";
import StudioSidebarUIPanel from "../StudioSidebarUIPanel";
import "./CustomizerMoreOptionsSection.scss";

type CustomizerMoreOptionsSectionProps = {};

const CustomizerMoreOptionsSection: React.FC<CustomizerMoreOptionsSectionProps> =
  React.memo(props => {
    const d = useStudioDesign();
    const fm = useFM();
    return (
      <Observer
        children={() => (
          <StudioSidebarUIPanel
            className="CustomizerMoreOptionsSection"
            heading={
              <FM
                id={MSG.customizerOptionMoreOptionsSectionTitle}
                defaultMessage="More Options"
              />
            }
          >
            <ShadedBlock spaceChildren>
              <BaseCheckbox
                form={d.columnNumberLabels}
                field="enabled"
                label={fm({
                  id: MSG.labelShowColumnNumberLabels,
                  defaultMessage: "Show Column Number Labels",
                })}
              />
              {d.columnNumberLabels.enabled && (
                <table>
                  <thead>
                    <tr>
                      <th>
                        <FM
                          id={MSG.labelColumnNumberLabelBlackFontWeight}
                          defaultMessage="Font Weight: Black"
                        />
                      </th>
                      <th>
                        <FM
                          id={MSG.labelColumnNumberLabelRedFontWeight}
                          defaultMessage="Font Weight: Red"
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <BaseInput
                          form={d.columnNumberLabels.black}
                          field="fontWeight"
                          type="number"
                          min="300"
                          max="700"
                          step="100"
                        />
                      </td>
                      <td>
                        <BaseInput
                          form={d.columnNumberLabels.red}
                          field="fontWeight"
                          type="number"
                          min="300"
                          max="700"
                          step="100"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </ShadedBlock>
          </StudioSidebarUIPanel>
        )}
      />
    );
  });

export default CustomizerMoreOptionsSection;
