import { action, flow, observable } from "mobx";
import { OpeningSetupString } from "../components/GameMoveListUI/GameMoveListItem";
import { defaultGameSetup } from "../constants/defaultGameSetup";
import { PieceDefList } from "../constants/pieceSet.constants";
import { splitArrayEvery } from "../utils/arrays.utils";
import { copyWithJSON } from "../utils/objects.utils";
import { getRandomNumericString } from "../utils/random.utils";
import { isFunction } from "../utils/typeChecks.utils";
import tick from "../utils/waiters.utils";
import { Move, makeMove } from "./makeMove.model";
import {
  PlayerArmy,
  PlayerSnapshot,
  makePlayerSnapshot,
} from "./makePlayer.model";

// export type PlayerRecord = {
//     name: '' as Sometimes<string>,
//     fullName: '' as Sometimes<string>,
//     team: '' as Sometimes<string>,
//   }

export type GameResult = null | "tie" | PlayerArmy;
export enum GameCategory {
  "match" = "match",
  "tournament" = "tournament",
  "collection" = "collection",
}

export const makeGameSnapshot = () => ({
  id: getRandomNumericString(8),

  fileName: "",

  title: "",

  /** 分类 */
  category: "" as Sometimes<GameCategory | string>,
  /** 棋谱合辑 */
  collection: "" as Sometimes<string>,
  /** 赛事名称 */
  tournament: "" as Sometimes<string>,
  /** 棋谱合辑章节 / 赛事轮次 */
  section: "" as Sometimes<string>,
  /**  */
  result: null as GameResult,
  owner: "" as Sometimes<string>,
  /** "组别" */
  playerGroupType: "" as Sometimes<string>,
  /** "台次" */
  gameSet: "" as Sometimes<string>,
  location: "" as Sometimes<string>,
  /** "布局" */
  openingSetupType: "" as Sometimes<string>,
  /** "记时规则" */
  timeControlType: "" as Sometimes<string>,
  /** "棋局类型" */
  gameRecordType: "" as Sometimes<string>,
  /** 棋局性质 */
  gamePlayType: "" as Sometimes<string>,

  playerA: makePlayerSnapshot("playerA", "black"),
  playerB: makePlayerSnapshot("playerB", "red"),

  firstHandArmy: "red" as Sometimes<PlayerArmy>,

  description: "" as Sometimes<string>,

  /** Time that the record was created, not necessarily the date that the game was initially played */
  timeCreated: "" as Sometimes<string>,
  /** Date that the game happened, not necessarily the date that the record was created in the system */
  date: "" as Sometimes<string>,

  notes: "" as Sometimes<string>,
  viewCount: 0,
  /** "棋谱价值" */
  gameRecordValue: 0,

  moveList: [] as string[],
  setup: null as Nullable<PieceDefList>,

  sourceWebsite: "" as Sometimes<string>,
});

export type Round = [Move] | [Move, Move];

export const makeGame = (
  snapshot?: Partial<GameSnapshot> | (() => Sometimes<Partial<GameSnapshot>>)
) => {
  const givenSnapshot = (
    isFunction(snapshot) ? snapshot() : copyWithJSON(snapshot)
  ) as Partial<GameSnapshot>;
  const $snapshot = Object.assign(makeGameSnapshot(), givenSnapshot);
  console.log(`constructing game #${$snapshot.id}`);
  const defaultSetupCopy = observable([...defaultGameSetup]);
  const s = observable({
    $snapshot,
    get $() {
      return s.$snapshot;
    },
    get setup() {
      return s.$.setup || defaultSetupCopy;
    },
    get movesList() {
      return [OpeningSetupString, ...s.$.moveList];
    },
    get errors(): Error[] {
      return s.moves
        .map(m => m.error)
        .filter(i => i)
        .flat() as Error[];
    },
    get rounds(): Round[] {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [openingSetup, ...rest] = s.moves;
      return splitArrayEvery(rest, 2) as Round[];
    },
    moves: [] as Move[],
    get length() {
      return s.$.moveList.length;
    },
    get hasMoves() {
      return s.moves.length > 1;
    },
    pushMove: action((moveDef: string) => {
      console.log(moveDef);
      s.$.moveList.push(moveDef);
      const i = s.movesList.length - 1;
      const move = makeMove(s, i);
      s.moves.push(move);
      return move;
    }),
    pushMoves: (moveDefs: string[]) => {
      moveDefs.forEach(def => s.pushMove(def));
      return s;
    },
    popMove: action(() => {
      s.movesList.pop();
      return s.moves.pop();
    }),
    clearMoveList: () => {
      while (s.moves.length > 1) {
        s.popMove();
      }
    },
    init: async () => {
      await s.reset();
    },
    reset: async (snapshot?: GameSnapshot) =>
      await flow(function* () {
        s.moves.splice(0);
        yield tick();
        if (snapshot) {
          console.log(`resetting with snapshot#${snapshot.id}`);
          s.$snapshot = snapshot;
        }
        s.movesList.forEach((m, i) => {
          const move = makeMove(s, i);
          s.moves.push(move);
        });
      })(),
    get hasInvertedPlayerArmyAssignment() {
      return s.$.playerA.army === "red";
    },
    set hasInvertedPlayerArmyAssignment(v) {
      if (v) {
        s.$.playerA.army = "red";
        s.$.playerB.army = "black";
      } else {
        s.$.playerA.army = "black";
        s.$.playerB.army = "red";
      }
      let nameSwapBuffer = s.$.playerA.name;
      s.$.playerA.name = s.$.playerB.name;
      s.$.playerB.name = nameSwapBuffer;
    },
    get red() {
      return s.$.playerA.army === "red" ? s.$.playerA : s.$.playerB;
    },
    get black() {
      return s.$.playerA.army === "black" ? s.$.playerA : s.$.playerB;
    },
    get firstHandPlayer() {
      return !s.$.firstHandArmy || s.$.firstHandArmy === "red"
        ? s.red
        : s.black;
    },
    get secondHandPlayer() {
      return !s.$.firstHandArmy || s.$.firstHandArmy === "red"
        ? s.black
        : s.red;
    },
  }) as Game;
  s.init();
  return s;
};

export type GameSnapshot = ReturnType<typeof makeGameSnapshot>;
export type Game = {
  $snapshot: GameSnapshot;
  $: GameSnapshot;
  setup: PieceDefList;
  movesList: string[];
  errors: Error[];
  rounds: Round[];
  moves: Move[];
  length: number;
  hasMoves: boolean;
  pushMove: (moveDef: string) => Move;
  pushMoves: (moveDefs: string[]) => Game;
  popMove: () => Move;
  clearMoveList: () => Game;
  init: () => Promise<void>;
  red: PlayerSnapshot;
  black: PlayerSnapshot;
  firstHandPlayer: PlayerSnapshot;
  secondHandPlayer: PlayerSnapshot;
  reset: (snapshot?: GameSnapshot) => Promise<void>;
  hasInvertedPlayerArmyAssignment: boolean;
};
