export type PlayerIdentifier = "playerA" | "playerB";
export type PlayerArmy = "red" | "black";

export const makePlayerSnapshot = (
  identifier: PlayerIdentifier,
  army: PlayerArmy
) => ({
  identifier,
  name: "" as Sometimes<string>,
  fullName: "" as Sometimes<string>,
  team: "" as Sometimes<string>,
  army,
});

export type PlayerSnapshot = ReturnType<typeof makePlayerSnapshot>;
