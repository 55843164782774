import { makeIconDef } from "./makeIconDef";

export const ShareIconDef = {
  share: makeIconDef("share", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3536 3.64652L12 3.29297L11.6464 3.64652L7.14645 8.14652L7.85355 8.85363L11.5 5.20718V16.0001H12.5V5.20718L16.1464 8.85363L16.8536 8.14652L12.3536 3.64652ZM5.5 11.0001C4.67157 11.0001 4 11.6716 4 12.5001V18.5001C4 19.3285 4.67157 20.0001 5.5 20.0001H18.5C19.3284 20.0001 20 19.3285 20 18.5001V12.5001C20 11.6716 19.3284 11.0001 18.5 11.0001H15.5V12.0001H18.5C18.7761 12.0001 19 12.2239 19 12.5001V18.5001C19 18.7762 18.7761 19.0001 18.5 19.0001H5.5C5.22386 19.0001 5 18.7762 5 18.5001V12.5001C5 12.2239 5.22386 12.0001 5.5 12.0001H9V11.0001H5.5Z"
        fill="currentColor"
      />
    ),
  }),
};
