import { makeIconDef } from "./makeIconDef";

export const LockIconDef = {
  lock: makeIconDef("lock", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 8C8 5.79086 9.79086 4 12 4C14.2091 4 16 5.79086 16 8V9C16 9.55228 15.5523 10 15 10H9C8.44772 10 8 9.55228 8 9V8ZM6 10C6.55228 10 7 9.55228 7 9V8C7 5.23858 9.23858 3 12 3C14.7614 3 17 5.23858 17 8V9C17 9.55228 17.4477 10 18 10V10V10C18.5523 10 19 10.4477 19 11V11V17C19 18.6569 17.6569 20 16 20H8C6.34315 20 5 18.6569 5 17V11V11C5 10.4477 5.44772 10 6 10V10V10ZM7 11V11C6.44772 11 6 11.4477 6 12V17C6 18.1046 6.89543 19 8 19H16C17.1046 19 18 18.1046 18 17V12C18 11.4477 17.5523 11 17 11V11H16H8H7ZM12 17C11.4477 17 11 16.5523 11 16V14C11 13.4477 11.4477 13 12 13V13C12.5523 13 13 13.4477 13 14V16C13 16.5523 12.5523 17 12 17V17Z"
        fill="currentColor"
      />
    ),
    filled: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 8C9 6.34315 10.3431 5 12 5C13.6569 5 15 6.34315 15 8V9C15 9.55228 14.5523 10 14 10H10C9.44772 10 9 9.55228 9 9V8ZM6 10C6.55228 10 7 9.55228 7 9V8C7 5.23858 9.23858 3 12 3C14.7614 3 17 5.23858 17 8V9C17 9.55228 17.4477 10 18 10V10C18.5523 10 19 10.4477 19 11V17C19 18.6569 17.6569 20 16 20H8C6.34315 20 5 18.6569 5 17V11C5 10.4477 5.44772 10 6 10V10ZM12 17C11.4477 17 11 16.5523 11 16V14C11 13.4477 11.4477 13 12 13V13C12.5523 13 13 13.4477 13 14V16C13 16.5523 12.5523 17 12 17V17Z"
        fill="currentColor"
      />
    ),
  }),
};
