import { action } from "mobx";
import { Observer } from "mobx-react-lite";
import React from "react";
import { useStudioContext } from "../../../contexts/studio/studio.context";
import { boardThemesArray } from "../../../themes/boardThemeIndex";
import { useStore } from "../../../utils/mobx.utils";
import BoardDesignCustomizer from "./BoardDesignCustomizer";
import BoardHeaderFooterDesignCustomizer from "./BoardHeaderFooterDesignCustomizer";
import BoardThemeSelector from "./BoardThemeSelector";
import CustomizerMoreOptionsSection from "./CustomizerMoreOptionsSection";
import "./DesignCustomizer.scss";
import PieceDesignCustomizer from "./PieceDesignCustomizer";
import PieceSetDesignCustomizer from "./PieceSetDesignCustomizer";

const DesignCustomizer: React.FC = React.memo(props => {
  const c = useStudioContext();

  const s = useStore(() => ({
    theme: "tikhian-original",
    get selectedTheme() {
      return boardThemesArray.find(t => t.identifier === s.theme);
    },
    ready: false,
  }));

  const handleFormChange = action(() => {
    s.theme = "";
  });

  return (
    <Observer
      children={() => (
        <div className="DesignCustomizer">
          <PieceDesignCustomizer
            piece={c.selectedPiece}
            onChange={handleFormChange}
          />

          <BoardThemeSelector state={s} />

          <form
            className="DesignCustomizerInnerForm"
            onChange={handleFormChange}
          >
            <BoardDesignCustomizer />
            <PieceSetDesignCustomizer />

            <BoardHeaderFooterDesignCustomizer position="header" />
            <BoardHeaderFooterDesignCustomizer position="footer" />

            <CustomizerMoreOptionsSection />
          </form>
        </div>
      )}
    />
  );
});

export default DesignCustomizer;
