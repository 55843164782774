import { makeIconDef } from "./makeIconDef";

export const ShieldIconDef = {
  shield: makeIconDef("shield", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 4.63908C11.5 4.27934 11.0651 4.09918 10.8107 4.35356V4.35356C10.5294 4.63486 10.1479 4.7929 9.75003 4.7929H6.50002C6.22388 4.7929 6.00002 5.01675 6.00002 5.2929V9.50001C6.00002 9.77616 6.22388 10 6.50002 10H11C11.2761 10 11.5 9.77616 11.5 9.50001L11.5 4.63908ZM13.1893 4.35356C12.935 4.09917 12.5 4.27934 12.5 4.63909L12.5 9.50001C12.5 9.77616 12.7239 10 13 10H17.5C17.7762 10 18 9.77616 18 9.50001V5.2929C18 5.01676 17.7762 4.7929 17.5 4.7929H14.25C13.8522 4.7929 13.4706 4.63486 13.1893 4.35356V4.35356ZM11.5 11.5C11.5 11.2239 11.2761 11 11 11H6.50002C6.22388 11 6.00002 11.2239 6.00002 11.5V14.1716C6.00002 14.3042 6.0527 14.4314 6.14646 14.5251L10.6464 19.0251C10.9614 19.3401 11.5 19.117 11.5 18.6716L11.5 11.5ZM13.3536 19.0251C13.0386 19.3401 12.5 19.117 12.5 18.6716L12.5 11.5C12.5 11.2239 12.7239 11 13 11H17.5C17.7762 11 18 11.2239 18 11.5V14.1716C18 14.3042 17.9473 14.4314 17.8536 14.5251L13.3536 19.0251ZM5.00002 14.5858V11C5.00002 11 5.00001 11 5.00001 11V11C5 11 5 11 5 11V10C5 10 5 10 5.00001 10V10C5.00001 10 5.00002 10 5.00002 10V4.2929C5.00002 4.01676 5.22388 3.7929 5.50002 3.7929H9.75003C9.88264 3.7929 10.0098 3.74022 10.1036 3.64645L11.6465 2.10357C11.8417 1.90831 12.1583 1.90831 12.3536 2.10357L13.8965 3.64645C13.9902 3.74022 14.1174 3.7929 14.25 3.7929H18.5C18.7762 3.7929 19 4.01676 19 4.2929V14.5858C19 14.7184 18.9473 14.8456 18.8536 14.9393L12.3536 21.4393C12.1583 21.6346 11.8417 21.6346 11.6465 21.4393L5.14646 14.9393C5.0527 14.8456 5.00002 14.7184 5.00002 14.5858Z"
        fill="currentColor"
      />
    ),
  }),
};
