import { action } from "mobx";
import { Observer } from "mobx-react-lite";
import React from "react";
import { useStudioContext } from "../../contexts/studio/studio.context";
import { useStore } from "../../utils/mobx.utils";
import "./GameMoveListRangeControl.scss";

type GameMoveListRangeControlProps = {};

const GameMoveListRangeControl: React.FC<
  GameMoveListRangeControlProps
> = props => {
  const c = useStudioContext();
  const s = useStore(() => ({
    get value() {
      return c.currentMoveIndex;
    },
    set value(v) {
      c.setCurrentMoveIndex(v);
    },
    handleChange: action((e: React.ChangeEvent<HTMLInputElement>) => {
      s.value = Math.round(parseFloat(e.target.value));
    }),
    get total() {
      return c.game.moves.length - 1;
    },
  }));
  return (
    <Observer
      children={() =>
        s.total > 0 ? (
          <div className="GameMoveListRangeControl">
            <div className="GameMoveListRangeControlCurrentLabel">
              {c.currentMoveIndex > s.total
                ? s.total
                : c.currentMoveIndex <= 0
                ? 0
                : c.currentMoveIndex}
            </div>
            <input
              className="GameMoveListRangeControlInput"
              type="range"
              value={s.value}
              onChange={s.handleChange}
              step={1}
              min={0}
              max={s.total}
            />
            <div className="GameMoveListRangeControlTotalLabel">{s.total}</div>
          </div>
        ) : null
      }
    />
  );
};

export default GameMoveListRangeControl;
