import { reaction } from "mobx";
import { Observer } from "mobx-react-lite";
import React, { CSSProperties, SyntheticEvent } from "react";
import { useOnMount } from "../../../hooks/lifecycle.hooks";
import joinClassName from "../../../utils/className.utils";
import { useProps, useStore } from "../../../utils/mobx.utils";
import { activateIcon } from "../Symbols/Symbols";
import { IconDefs, IconName, IconVariant } from "../Symbols/iconDefs";
import "./BaseIcon.css";

type IconProps = {
  className?: string;
  title?: string;
  name?: IconName;
  icon?: IconName;
  variant?: IconVariant;
  onClick?: (e: SyntheticEvent<SVGElement>) => void;
  size?: string | number;
  style?: CSSProperties;
  color?: string;
};

const hasIcon = (name: IconName, variant: IconVariant) => {
  return Boolean(IconDefs[name]?.[variant]);
};

const BaseIcon: React.FC<IconProps> = React.memo(props => {
  const p = useProps(props);
  const s = useStore(() => ({
    get name() {
      return p.name ?? p.icon ?? "arrow";
    },
    get variant() {
      switch (p.variant) {
        // case 'color':
        //   return hasIcon(s.name, 'color') ? 'color' : hasIcon(s.name, 'filled') ? 'filled' : 'regular';
        case "filled":
          return hasIcon(s.name, "filled") ? "filled" : "regular";
        default:
          return "regular";
      }
    },
    get id() {
      return `${s.name}-${s.variant}`;
    },
  }));
  useOnMount(() =>
    reaction(
      () => s.id,
      () => activateIcon(s.name, s.variant),
      { fireImmediately: true }
    )
  );
  return (
    <Observer
      children={() => (
        <svg
          className={joinClassName("BaseIcon", p.className)}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          data-name={p.name}
          data-variant={p.variant}
          onClick={p.onClick}
          style={{ width: p.size, height: p.size, color: p.color, ...p.style }}
        >
          {p.title && <title>{p.title}</title>}
          <use xlinkHref={`#icon-${s.id}`} />
        </svg>
      )}
    />
  );
});

export default BaseIcon;
