import { Observer } from "mobx-react-lite";
import React from "react";
import BaseSelector from "../../../components/_base/BaseSelector/BaseSelector";
import { FM, useFM } from "../../../constants/lang-components.aliases";
import { useStudioDesign } from "../../../contexts/studio/studio.context";
import { MSG } from "../../../lang/MSG.enum";
import { ColorPalette } from "../../../utils/colors.utils";
import StudioSidebarUIPanel from "../StudioSidebarUIPanel";
import PlayerPieceSetDesignCustomizer from "./PlayerPieceSetDesignCustomizer";
// import './PieceSetDesignCustomizer.scss';

type PieceSetDesignCustomizerProps = {};

const PieceSetDesignCustomizer: React.FC<PieceSetDesignCustomizerProps> =
  React.memo(props => {
    const d = useStudioDesign();
    const fm = useFM();

    return (
      <Observer
        children={() => (
          <StudioSidebarUIPanel
            className="PieceSetDesignCustomizer"
            heading={
              <FM
                id={MSG.customizerOptionPieceDesignSectionTitle}
                defaultMessage="Piece Design"
              />
            }
          >
            <table>
              <tbody>
                <tr>
                  <th>
                    <FM
                      id={MSG.customizerOptionPieceBaseType}
                      defaultMessage="Piece Base"
                    />
                  </th>
                  <td>
                    <BaseSelector
                      form={d.pieceSet}
                      field="base"
                      appearance="system"
                      doNotShowEmptyOptionInSystemDropdown
                      options={[
                        {
                          label: fm({
                            id: MSG.pieceBaseTypeCircle,
                            defaultMessage: "Simple Circles",
                          }),
                          value: "circle",
                        },
                        // { label: fm({ id: MSG.pieceCharSetName1941, defaultMessage: 'Font: 1941' }), value: '1941' },
                      ]}
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <FM
                      id={MSG.customizerOptionPieceCharSet}
                      defaultMessage="Character Style"
                    />
                  </th>
                  <td>
                    <BaseSelector
                      form={d.pieceSet}
                      field="charSet"
                      appearance="system"
                      doNotShowEmptyOptionInSystemDropdown
                      options={[
                        {
                          label: fm({
                            id: MSG.pieceCharSetNameGenwan,
                            defaultMessage: "Genwan",
                          }),
                          value: "Genwan",
                        },
                        {
                          label: fm({
                            id: MSG.pieceCharSetNameTaipeiSans,
                            defaultMessage: "TaipeiSans",
                          }),
                          value: "TaipeiSans",
                        },
                        {
                          label: fm({
                            id: MSG.pieceCharSetNameTikHianOriginal,
                            defaultMessage: "TikHianOriginal",
                          }),
                          value: "TikHianOriginal",
                        },
                        {
                          label: fm({
                            id: MSG.pieceCharSetName1941,
                            defaultMessage: "1941",
                          }),
                          value: "1941",
                        },
                        {
                          label: fm({
                            id: MSG.pieceCharSetNameTsingWoodbone,
                            defaultMessage: "TsingWoodbone",
                          }),
                          value: "TsingWoodbone",
                        },
                      ]}
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <table className="DesignCustomizerTwoColumnTable">
              <thead>
                <tr>
                  <th>
                    <h4 style={{ color: ColorPalette.black }}>
                      <FM
                        id={MSG.customizerOptionPieceDesignBlackSectionTitle}
                        defaultMessage="Black"
                      />
                    </h4>
                  </th>
                  <th>
                    <h4 style={{ color: ColorPalette.red }}>
                      <FM
                        id={MSG.customizerOptionPieceDesignRedSectionTitle}
                        defaultMessage="Red"
                      />
                    </h4>
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>
                    <PlayerPieceSetDesignCustomizer player="black" />
                  </td>
                  <td>
                    <PlayerPieceSetDesignCustomizer player="red" />
                  </td>
                </tr>
              </tbody>
            </table>
          </StudioSidebarUIPanel>
        )}
      />
    );
  });

export default PieceSetDesignCustomizer;
