import { Observer } from "mobx-react-lite";
import React from "react";
import { Size } from "../../../constants/size.enum";
import joinClassName from "../../../utils/className.utils";
import { useProps, useStore } from "../../../utils/mobx.utils";
import "./BaseSpacer.scss";

interface BaseSpacerProps {
  size?: Size | string;
  inline?: boolean;
}

const BaseSpacer: React.FC<BaseSpacerProps> = props => {
  const p = useProps(props);

  const s = useStore(() => ({
    get isStandardSize() {
      return (
        !p.size || ["xs", "sm", "md", "ml", "lg", "xl"].includes(p.size || "")
      );
    },
    get inline() {
      return p.inline ?? false;
    },
    get style() {
      return {
        width: s.isStandardSize ? undefined : p.size,
        height: s.isStandardSize ? undefined : p.size,
      };
    },
  }));

  return (
    <Observer
      children={() => (
        <div
          className={joinClassName(
            "BaseSpacer",
            s.isStandardSize && (p.size || "md"),
            s.inline && "inline"
          )}
          style={s.style}
        />
      )}
    />
  );
};

export default BaseSpacer;
