import { MSG } from "./MSG.enum";

export const ZH_HK_MESSAGES = {
  [MSG.pzxy]: "枰蹤俠韻",
  [MSG.pzxyLogo]: "枰蹤俠韻商標",
  [MSG.appName]: "棋譜工作室",
  [MSG.untitledGame]: "未命名棋譜",
  [MSG.createNewGame]: "創建新棋譜",
  [MSG.viewModeSelectorTitle]: "界面模式",
  [MSG.viewModeSelectorEditSetupModeLabel]: "開局",
  [MSG.viewModeSelectorReadingModeLabel]: "閱讀",
  [MSG.viewModeSelectorDesignModeLabel]: "設計",
  [MSG.viewModeSelectorEditModeLabel]: "編輯",
  [MSG.viewModeSelectorExportModeLabel]: "導出",
  [MSG.importGamesSectionTitle]: "導入",
  [MSG.importJsonButtonLabel]: "導入 JSON 棋譜",
  [MSG.enterMovesRecordButtonLabel]: "輸入棋譜…",
  [MSG.pasteOrEnterMovesRecordLabel]: "請在以下文字框內輸入或粘貼棋譜：",
  [MSG.error_MovesRecordImportFailure]:
    "讀取棋譜文本失敗。如果您確認棋譜內容無誤，請聯系開發團隊。您所輸入的內容已復制到剪貼板。",
  [MSG.gameOpeningSetupEditorTitle]: "編輯開局",
  [MSG.gameOpening]: "開局",
  [MSG.endGame]: "終局",
  [MSG.clearBoard]: "清空棋盤",
  [MSG.removeSelectedPieceButtonLabel]: "移除選中棋子",
  [MSG.confirmClearBoard]: "確認要清空棋盤嗎？",
  [MSG.metaInfoEditorSectionTitle]: "棋譜信息與設定",
  [MSG.gameRecordTitle]: "棋譜標題",
  [MSG.gameCategory]: "棋譜類別",
  [MSG.gameCollectionName]: "棋譜合輯名稱",
  [MSG.gameMatchName]: "對局名稱",
  [MSG.gameTournamentName]: "賽事名稱",
  [MSG.gameRecordFileName]: "存儲文件名",
  [MSG.exporterSectionTitle]: "導出",
  [MSG.exporterOptionsTableTitle]: "導出選項",
  [MSG.exportAsJonButtonLabel]: "導出為 JSON",
  [MSG.exportOnlyOpeningSetupAsJonButtonLabel]: "導出為 JSON (僅開局)",
  [MSG.exportCurrentFrameButtonLabel]: "將當前畫面存為圖片",
  [MSG.exportAllFramesButtonLabel]: "批量導出每一步驟為單獨的圖片",
  [MSG.exportImageFileFormatLabel]: "靜態圖片導出格式",
  [MSG.exportGifButtonLabel]: "渲染 GIF 動圖",
  [MSG.tooManyFramesForGifWarning]:
    "該棋局步驟較多，繼續渲染GIF動圖可能會導致文件過大或頁面失去響應。",
  [MSG.movesSectionTitle]: "棋譜內容",
  [MSG.DesignCustomizerSectionTitle]: "設計",
  [MSG.customizerOptionBoardDesignSectionTitle]: "棋盤",
  [MSG.customizerOptionBoardInset]: "留白",
  [MSG.customizerOptionBoardBackgroundColor]: "背景色",
  [MSG.customizerOptionBoardBackgroundImage]: "背景圖",
  [MSG.customizerOptionBoardBorderColor]: "邊框色",
  [MSG.customizerOptionBoardBorderWidth]: "邊框寬度",
  [MSG.customizerOptionBoardFontFamily]: "字體",
  [MSG.customizerOptionBoardFontWeight]: "字重",
  [MSG.customizerOptionBoardFontSize]: "字號",
  [MSG.customizerOptionBoardTextColor]: "文字色",
  [MSG.customizerOptionBoardTextOpacity]: "文字透明度",
  [MSG.customizerOptionBoardGridColor]: "網格色",
  [MSG.customizerOptionBoardGridOpacity]: "網格透明度",
  [MSG.customizerOptionPieceDesignSectionTitle]: "棋子風格",
  [MSG.customizerOptionPieceBaseType]: "形狀",
  [MSG.customizerOptionPieceCharSet]: "文字",
  [MSG.customizerOptionMoreOptionsSectionTitle]: "更多選項",
  [MSG.fontNameSystemSans]: "系統字體",
  [MSG.fontNameGenwan]: "源雲明體",
  [MSG.fontNameTaipeiSans]: "臺北黑體",
  [MSG.fontNameSourceHanSansSC]: "思源黑體",
  [MSG.fontNameSourceHanSerifSC]: "思源宋體",
  [MSG.pieceCharSetNameGenwan]: "源雲明體 500",
  [MSG.pieceCharSetNameTaipeiSans]: "臺北黑體 300",
  [MSG.pieceCharSetName1941]: "1941 古書隸",
  [MSG.pieceCharSetNameTsingWoodbone]: "清朝木骨棋子",
  [MSG.pieceCharSetNameTikHianOriginal]: "竹香原創",
  [MSG.selectorPlaceholderText]: "　",
  [MSG.pieceBaseTypeCircle]: "圓形",
  [MSG.customizerOptionPieceDesignRedSectionTitle]: "紅方",
  [MSG.customizerOptionPieceDesignBlackSectionTitle]: "黑方",
  [MSG.labelOutlineColor]: "描邊",
  [MSG.labelOutlineTexture]: "描邊紋理",
  [MSG.labelFillColor]: "背景",
  [MSG.labelFillTexture]: "背景紋理",
  [MSG.labelTextColor]: "文字",
  [MSG.labelTextTexture]: "文字紋理",
  [MSG.labelHeader]: "頁眉",
  [MSG.labelFooter]: "頁腳",
  [MSG.noticeTextAllPiecesAlive]: "目前所有棋子都擺在棋盤之上。",
  [MSG.labelMiddleSlot]: "中",
  [MSG.labelStartSlot]: "左",
  [MSG.labelEndSlot]: "右",
  [MSG.labelPlayerRed]: "紅方",
  [MSG.labelPlayerBlack]: "黑方",
  [MSG.labelPlayerRedName]: "紅方姓名",
  [MSG.labelPlayerBlackName]: "黑方姓名",
  [MSG.labelCustomText]: "自定義文字",
  [MSG.boardPlayerColorLabelRed]: "紅方",
  [MSG.boardPlayerColorLabelBlack]: "黑方",
  [MSG.boardPlayerColorLabelRedWithSpace]: "紅 方",
  [MSG.boardPlayerColorLabelBlackWithSpace]: "黑 方",
  [MSG.unnamedPlayer]: "佚名",
  [MSG.themeNameGenwanWarm]: "源雲明體 米色背景",
  [MSG.themeNameTaipeiSansWhite]: "臺北黑體 白色背景",
  [MSG.themeNameTextbook1941BW]: "1941古書隸 黑白教材用",
  [MSG.themeNameTsingWoodboneClean]: "清朝木骨棋子 白色背景",
  [MSG.themeNameTikHianOriginal]: "竹香原創 象牙白",
  [MSG.themeNameTikHianWhite]: "竹香原創 簡約白",
  [MSG.themeNameTikHianOlive]: "竹香原創 竹仙綠",
  [MSG.themeNameTikHianDark]: "竹香原創 碳灰金",
  [MSG.customizerThemePresetSectionTitle]: "預設主題",
  [MSG.labelDistanceToBoardContent]: "與內容間距",
  [MSG.nthRoundCounter]: "回合數",
  [MSG.nthRound]: "第 {nth} 回合",
  [MSG.nthPage]: "第 {nth} 頁",
  [MSG.pageNumber]: "頁碼",
  [MSG.pageNumberWithCount]: "當前頁 / 總計",
  [MSG.labelShowColumnNumberLabels]: "顯示縱線數字標號",
  [MSG.labelColumnNumberLabelBlackFontWeight]: "黑方字重",
  [MSG.labelColumnNumberLabelRedFontWeight]: "紅方字重",
  [MSG.labelExportStaticImageWidth]: "導出靜態圖片分辨率 (寬度，像素)",
  [MSG.labelExportAnimatedImageWidth]: "導出GIF動態圖片分辨率 (寬度，像素)",
  [MSG.labelAnimatePieceMovement]: "移動棋子動畫效果 (渲染GIF時無效)",
  [MSG.labelDropHereToRemovePiece]: "在此處放下棋子",
  [MSG.copyGameRecordAsText]: "復制棋譜為文本",
  [MSG.copySuccessMessage]: "復制成功！您現在可以在其他程序中粘貼棋譜為文本。",
  [MSG.labelPrevMove]: "上一步",
  [MSG.labelNextMove]: "下一步",
  [MSG.labelStartAutoPlay]: "開始自動播放",
  [MSG.labelStartAutoPlayFromBeginning]: "從頭自動播放",
  [MSG.labelStopAutoPlay]: "停止自動播放",
  [MSG.labelPauseAutoPlay]: "暫停自動播放",
  [MSG.labelAutoPlayStepDurationInSeconds]: "每步時間(秒)",
  [MSG.labelPlayerArmyAssignment]: "棋盤方向",
  [MSG.labelBlackAtNorth]: "紅下黑上",
  [MSG.labelRedAtNorth]: "紅上黑下",
  [MSG.labelPlayerFirstHandSelector]: "先行方",
  [MSG.labelOpeningSetup]: "棋局開始",
  [MSG.labelCurrentlySelectedPiece]: "當前選中棋子",
  [MSG.labelCharacterDesignVariant]: "字形",
  [MSG.exporterStateReady]: "選擇項目以進行導出。",
  [MSG.exporterStateExportingAsJSON]: "正在導出為JSON…",
  [MSG.exporterStateRenderingNthFrame]: "正在渲染第 {nth} 頁…",
  [MSG.exporterStateRenderingNthFrameOfTotal]: "正在渲染頁面 {nth} / {total} …",
  [MSG.exporterStatePreparingGIF]: "正在生成GIF文件…",
  [MSG.exporterStatePreparingZIP]: "正在壓縮圖片文件…",
  [MSG.exporterStateDownloading]: "正在啟動下載…",
  [MSG.exporterStateDownloadingGIF]: "正在下載GIF…",
  [MSG.exporterStateCompletion]: "導出完成。",
  [MSG.textureNameMarble]: "大理石",
  [MSG.textureNamePaperWarm]: "暖色紙",
  [MSG.textureNamePaperWarmer]: "暖色紙 2",
};
