import { DeepOptional } from "../@types/util.types";
import { replaceContents } from "./arrays.utils";
import { uniq } from "./ramdaEquivalents.utils";
import { isArray, isObject, isString } from "./typeChecks.utils";

export const setValueOfKey = <T extends AnyObject>(
  object: T,
  key: keyof T,
  newValue: any
) => {
  object[key] = newValue;
};

export const setValueOfKeyFactory =
  <T extends AnyObject>(object: T) =>
  (key: keyof T, newValue: any) => {
    object[key] = newValue;
  };

export type TypeCastSchema<T> = Partial<
  Record<keyof T, "string" | "boolean" | "number" | "array" | "object">
>;

export function recursiveMergeWithTypeCast<T extends AnyObject = AnyObject>(
  obj: T,
  _src?: DeepOptional<T> | null,
  schema: TypeCastSchema<T> = {},
  skipUnknownKeys = true
) {
  const objIsValid = obj instanceof Object && !isArray(obj) && Boolean(obj);
  if (!objIsValid) {
    console.warn(
      "The object supplied for recursiveMergeWithTypeCast is not an object, though this has been ignored and the application will attemp to proceed with merging.",
      obj
    );
  }
  const src = isString(_src) ? ({} as Partial<T>) : _src;
  if (!src) return obj;
  const keys = skipUnknownKeys
    ? Object.keys(obj)
    : uniq([...Object.keys(obj), ...Object.keys(src)]);
  keys.forEach((key: any) => {
    const setter = setValueOfKeyFactory(obj as any);
    if (src[key] !== undefined) {
      const typeofKey = schema[key] || typeof obj[key];
      switch (typeofKey) {
        case "boolean":
          setter(key, src[key] === null ? src[key] : !!src[key]);
          break;
        case "string":
          setter(key, src[key] === null ? src[key] : src[key] + "");
          break;
        case "number":
          setter(
            key,
            src[key] === null ? src[key] : parseFloat(src && (src[key] as any))
          );
          break;
        default: {
          if (isArray(obj[key])) {
            replaceContents<any>(obj[key], ((src as any) ?? [])[key]);
          } else if (isObject(obj[key])) {
            recursiveMergeWithTypeCast(obj[key], src[key], undefined, false);
          } else {
            setter(key, src[key]);
          }
        }
      }
    }
  });
  return obj as T;
}

export function mergeIntoObjectByDescriptors<
  A extends AnyObject,
  B extends AnyObject
>(a: A, b: B, options?: { configurable?: boolean }) {
  const propertyDescriptorMap = Object.getOwnPropertyDescriptors(b);
  if (options) {
    if (options.configurable === true || options.configurable === false) {
      Object.values(propertyDescriptorMap).forEach(
        desc => (desc.configurable = options.configurable)
      );
    }
  }
  Object.defineProperties(a, propertyDescriptorMap);
  return a;
}

export function copyWithJSON<T = any>(obj?: T): T {
  if (obj === undefined || obj === null) return obj!;
  return JSON.parse(JSON.stringify(obj));
}
