import { makeIconDef } from "./makeIconDef";

export const CheckIconDef = {
  check: makeIconDef("check", {
    regular: () => (
      <path
        d="M3 11L9 17L21 5"
        stroke="currentColor"
        strokeWidth="1"
        strokeLinecap="round"
      />
    ),
    filled: () => (
      <path
        d="M3 11L9 17L21 5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    ),
  }),
};
