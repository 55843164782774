import { MSG } from "./MSG.enum";

export const EN_GB_MESSAGES = {
  [MSG.pzxy]: "枰蹤俠韻",
  [MSG.appName]: "Xiangqi Studio",
  [MSG.createNewGame]: "Create New Game",
  [MSG.viewModeSelectorTitle]: "View Mode",
  [MSG.viewModeSelectorEditSetupModeLabel]: "Setup",
  [MSG.viewModeSelectorReadingModeLabel]: "Reading & Export",
  [MSG.importGamesSectionTitle]: "Import",
  [MSG.importJsonButtonLabel]: "Import JSON…",
  [MSG.enterMovesRecordButtonLabel]: "Enter Moves Record…",
  [MSG.gameRecordTitle]: "Title",
  [MSG.gameOpeningSetupEditorTitle]: "Game Opening Setup",
  [MSG.removeSelectedPieceButtonLabel]: "Remove Selected Piece",
  [MSG.clearBoard]: "Clear Board",
  [MSG.confirmClearBoard]: "Are you sure you want to clear the board?",
  [MSG.metaInfoEditorSectionTitle]: "Game Information",
  [MSG.exporterSectionTitle]: "Export",
  [MSG.exportAsJonButtonLabel]: "Export as JSON",
  [MSG.exportOnlyOpeningSetupAsJonButtonLabel]:
    "Export as JSON (Opening Setup Only)",
  [MSG.exportCurrentFrameButtonLabel]: "Export Current Frame",
  [MSG.exportAllFramesButtonLabel]: "Export All Frames",
  [MSG.exportGifButtonLabel]: "Render GIF",
  [MSG.tooManyFramesForGifWarning]:
    "This game record has a large amount of moves. GIF rendering might take a long time or crash the page when memory limit is exceeded.",
  [MSG.movesSectionTitle]: "Moves",
  [MSG.DesignCustomizerSectionTitle]: "Customizer",
  [MSG.themeNameGenwanWarm]: "Genwan Warm",
  [MSG.themeNameTaipeiSansWhite]: "Taipei Sans White",
  [MSG.themeNameTextbook1941BW]: "Textbook 1941 BW",
  [MSG.themeNameTsingWoodboneClean]: "Tsing Woodbone",
  [MSG.themeNameTikHianOriginal]: "TikHian Original",
  [MSG.themeNameTikHianWhite]: "TikHian White",
  [MSG.themeNameTikHianOlive]: "TikHian Olive",
  [MSG.themeNameTikHianDark]: "TikHian Dark",
  [MSG.textureNameMarble]: "Marble",
  [MSG.textureNamePaperWarm]: "Paper, warm",
  [MSG.textureNamePaperWarmer]: "Paper, warmer",
};
