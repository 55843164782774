export const isProduction = process.env.NODE_ENV === "production";
export const isDevelopment = process.env.NODE_ENV === "development";
export const isInCypressTestMode = Boolean(window.Cypress);

declare global {
  interface Window {
    Cypress: AnyObject;
  }
}

const { hostname } = window.location;

export const isLocalhost = hostname === "localhost";
