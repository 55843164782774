import { action } from "mobx";
import { Observer } from "mobx-react-lite";
import React from "react";
import { useIntl } from "react-intl";
import PieceGraphic from "../../components/PieceGraphic/PieceGraphic";
import BaseButton from "../../components/_base/BaseButton/BaseButton";
import { FM } from "../../constants/lang-components.aliases";
import { PieceDef } from "../../constants/pieceSet.constants";
import { useStudioContext } from "../../contexts/studio/studio.context";
import { MSG } from "../../lang/MSG.enum";
import { ColorPalette } from "../../utils/colors.utils";
import { useStore } from "../../utils/mobx.utils";
import "./GameOpeningSetupEditor.scss";

type GameOpeningSetupEditorProps = {};

const GameOpeningSetupEditor: React.FC<GameOpeningSetupEditorProps> = props => {
  const c = useStudioContext();

  const intl = useIntl();

  const s = useStore(() => ({
    get redPlayerIdentifier() {
      return c.game.red.identifier;
    },
    get blackPlayerIdentifier() {
      return c.game.black.identifier;
    },
    get deadReds() {
      return c.game.setup.filter(
        pc => !pc.alive && pc.playerIdentifier === s.redPlayerIdentifier
      );
    },
    get deadBlacks() {
      return c.game.setup.filter(
        pc => !pc.alive && pc.playerIdentifier === s.blackPlayerIdentifier
      );
    },
  }));

  const clearBoard = action(() => {
    const confirm = window.confirm(
      intl.formatMessage({
        id: MSG.confirmClearBoard,
        defaultMessage: "Confirm Clearing Board",
      })
    );
    if (!confirm) return;
    c.game.setup.forEach(pc => (pc.alive = false));
    console.log(c.game.setup);
  });

  const handlePieceClick = action((pc?: Partial<PieceDef>) => {
    if (pc?.id) {
      c.selectedPiece = pc as PieceDef;
    }
  });

  const removeSelectedPiece = action(() => {
    if (!c.selectedPiece) return;
    c.selectedPiece.alive = false;
    c.selectedPiece = null;
  });

  const handlePieceBoxClick = () => {
    if (c.selectedPiece) {
      removeSelectedPiece();
    }
  };

  return (
    <Observer
      children={() => {
        const blackPieceBox = (
          <>
            <h3>
              <FM id={MSG.labelPlayerBlack} defaultMessage="Player Black" />
            </h3>
            {s.deadBlacks.length === 0 && (
              <p>
                <FM
                  id={MSG.noticeTextAllPiecesAlive}
                  defaultMessage="Notice: All pieces are currently alive."
                />
              </p>
            )}
            <div className="GameOpeningSetupEditorPieceBoxPieceList">
              {s.deadBlacks.map(pc => (
                <PieceGraphic
                  key={pc.id}
                  {...pc}
                  size={48}
                  standalone
                  onPlacement={handlePieceClick}
                />
              ))}
            </div>
          </>
        );
        const redPieceBox = (
          <>
            <h3 style={{ color: ColorPalette.red }}>
              <FM id={MSG.labelPlayerRed} defaultMessage="Player Red" />
            </h3>
            {s.deadReds.length === 0 && (
              <p>
                <FM
                  id={MSG.noticeTextAllPiecesAlive}
                  defaultMessage="Notice: All pieces are currently alive."
                />
              </p>
            )}
            <div className="GameOpeningSetupEditorPieceBoxPieceList">
              {s.deadReds.map(pc => (
                <PieceGraphic
                  key={pc.id}
                  {...pc}
                  size={48}
                  standalone
                  onPlacement={handlePieceClick}
                />
              ))}
            </div>
          </>
        );
        return (
          <div className="GameOpeningSetupEditor">
            <h3>
              <FM
                id={MSG.gameOpeningSetupEditorTitle}
                defaultMessage="Game Opening Setup Editor"
              />
            </h3>
            {/* <BaseInput form={context.game.$} field="title" Label="Title" /> */}
            <BaseButton onClick={clearBoard}>
              <FM id={MSG.clearBoard} defaultMessage="Clear Board" />
            </BaseButton>
            {c.selectedPiece && c.selectedPiece.alive && (
              <BaseButton onClick={removeSelectedPiece}>
                <FM
                  id={MSG.removeSelectedPieceButtonLabel}
                  defaultMessage="Remove Selected Piece"
                />
              </BaseButton>
            )}
            <div className="GameOpeningSetupEditorPieceBox">
              {c.game.$.playerA.army === "black" && (
                <>
                  {blackPieceBox}
                  {redPieceBox}
                </>
              )}
              {c.game.$.playerA.army === "red" && (
                <>
                  {redPieceBox}
                  {blackPieceBox}
                </>
              )}
              {c.selectedPiece && (
                <div
                  className="GameOpeningSetupEditorPieceBoxDropArea"
                  onClick={handlePieceBoxClick}
                >
                  <FM
                    id={MSG.labelDropHereToRemovePiece}
                    defaultMessage="Drop here to remove piece"
                  />
                </div>
              )}
            </div>
          </div>
        );
      }}
    />
  );
};

export default GameOpeningSetupEditor;
