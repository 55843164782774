import { action, observable } from "mobx";

const loadedScripts = new Map<
  string,
  { promise: Promise<true>; loaded: boolean | null }
>();

export function loadScript(src: string) {
  // console.log(src);

  const existingScriptDef = loadedScripts.get(src);

  if (existingScriptDef) {
    // console.log(src, 'has already been passed to load');

    switch (existingScriptDef.loaded) {
      case null: {
        // console.log('returning existing loader');
        return existingScriptDef.promise;
      }
      case true: {
        // console.log('already finished loading');
        return new Promise(resolve => resolve(true));
      }
      case false: {
        // console.log('previous request failed to load, retrying');
        break;
      }
      default: {
        break;
      }
    }
  }

  const state = observable({
    loaded: null as null | boolean,
  });

  const promise = new Promise<true>(async (resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = action(() => {
      state.loaded = true;
      resolve(true);
    });
    script.onerror = action((e: unknown) => {
      state.loaded = false;
      reject(e);
    });
    document.head.appendChild(script);
  });

  loadedScripts.set(
    src,
    observable({
      promise,
      get loaded() {
        return state.loaded;
      },
    })
  );

  return promise;
}
