import { makeIconDef } from "./makeIconDef";

export const InfoIconDef = {
  info: makeIconDef("info", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12ZM21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM14 7C14 7.55228 13.5523 8 13 8C12.4477 8 12 7.55228 12 7C12 6.44772 12.4477 6 13 6C13.5523 6 14 6.44772 14 7ZM9.25 11C9.11193 11 9 10.8881 9 10.75V10.25C9 10.1119 9.11193 10 9.25 10H12.5C12.6487 10 12.7898 10.0662 12.8848 10.1807C12.9797 10.2951 13.0188 10.4459 12.9914 10.5921L11.9652 16.0654C11.8812 16.5136 12.3918 16.8311 12.7566 16.5575L14.5 15.25C14.6105 15.1672 14.7672 15.1895 14.85 15.3L15.15 15.7C15.2328 15.8105 15.2105 15.9672 15.1 16.05L13.3566 17.3575C12.2622 18.1783 10.7302 17.2257 10.9823 15.8811L11.842 11.2961C11.8709 11.1422 11.7528 11 11.5963 11H9.25Z"
        fill="currentColor"
      />
    ),
    filled: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM14 7C14 7.55228 13.5523 8 13 8C12.4477 8 12 7.55228 12 7C12 6.44772 12.4477 6 13 6C13.5523 6 14 6.44772 14 7ZM9.25 11C9.11193 11 9 10.8881 9 10.75V10.25C9 10.1119 9.11193 10 9.25 10H12.5C12.6487 10 12.7898 10.0662 12.8848 10.1807C12.9797 10.2951 13.0188 10.4459 12.9914 10.5921L11.9652 16.0654C11.8812 16.5136 12.3918 16.8311 12.7566 16.5575L14.5 15.25C14.6105 15.1672 14.7672 15.1895 14.85 15.3L15.15 15.7C15.2328 15.8105 15.2105 15.9672 15.1 16.05L13.3566 17.3575C12.2622 18.1783 10.7302 17.2257 10.9823 15.8811L11.842 11.2961C11.8709 11.1422 11.7528 11 11.5963 11H9.25Z"
        fill="currentColor"
      />
    ),
  }),
};
