import { makeIconDef } from "./makeIconDef";

export const HomeIconDef = {
  home: makeIconDef("home", {
    regular: () => (
      <path
        d="M2.92584 13.3554C2.70163 13.389 2.5 13.2154 2.5 12.9887V10.4452C2.5 10.3035 2.56016 10.1684 2.66552 10.0736L2.33104 9.70192L2.66552 10.0736L11.6655 1.97357L11.331 1.60192L11.6655 1.97357C11.8557 1.80243 12.1443 1.80243 12.3345 1.97357L21.3345 10.0736C21.4398 10.1684 21.5 10.3035 21.5 10.4452V12.9887C21.5 13.2154 21.2984 13.389 21.0742 13.3554C20.2454 13.2311 19.5 13.873 19.5 14.7111V20.9999C19.5 21.276 19.2761 21.4999 19 21.4999H16C15.7239 21.4999 15.5 21.276 15.5 20.9999V15.9999C15.5 14.0669 13.933 12.4999 12 12.4999C10.067 12.4999 8.5 14.0669 8.5 15.9999V20.9999C8.5 21.276 8.27614 21.4999 8 21.4999H5C4.72386 21.4999 4.5 21.276 4.5 20.9999V14.7111C4.5 13.873 3.75464 13.2311 2.92584 13.3554Z"
        stroke="currentColor"
      />
    ),
    filled: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 13.8499C2.47349 13.9288 2 13.5211 2 12.9887V10.4452C2 10.1617 2.12032 9.89156 2.33104 9.70192L11.331 1.60192C11.7113 1.25965 12.2887 1.25965 12.669 1.60192L21.669 9.70192C21.8797 9.89156 22 10.1617 22 10.4452V12.9887C22 13.5211 21.5265 13.9288 21 13.8499V13.8499C20.4735 13.7709 20 14.1787 20 14.7111V20.9999C20 21.5521 19.5523 21.9999 19 21.9999H16C15.4477 21.9999 15 21.5521 15 20.9999V15.9999C15 14.343 13.6569 12.9999 12 12.9999C10.3431 12.9999 9 14.343 9 15.9999V20.9999C9 21.5521 8.55228 21.9999 8 21.9999H5C4.44772 21.9999 4 21.5521 4 20.9999V14.7111C4 14.1787 3.52651 13.7709 3 13.8499V13.8499Z"
        fill="currentColor"
      />
    ),
  }),
};
