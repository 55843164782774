import { makeIconDef } from "./makeIconDef";

export const HeartIconDef = {
  heart: makeIconDef("heart", {
    regular: () => (
      <path
        d="M11.621 6.59469L12 7.03495L12.379 6.59469C14.0089 4.70101 16.1761 4.17499 17.8341 4.6827C19.4607 5.18082 20.7018 6.70436 20.4728 9.18675C20.1733 12.4336 18.0706 13.9713 15.8304 15.5855L15.792 15.6132C14.5047 16.5407 13.1517 17.5156 12.2599 18.8718C12.2579 18.8748 12.2427 18.8951 12.1934 18.9162C12.144 18.9374 12.0762 18.9521 12 18.9521C11.9238 18.9521 11.856 18.9374 11.8066 18.9162C11.7572 18.8951 11.742 18.8748 11.7401 18.8718C10.8482 17.5155 9.49521 16.5407 8.2079 15.6131L8.16956 15.5855C5.92943 13.9713 3.82667 12.4336 3.52717 9.18675C3.29819 6.70436 4.53928 5.18082 6.16592 4.6827C7.82386 4.17499 9.99107 4.70101 11.621 6.59469Z"
        stroke="currentColor"
      />
    ),
    filled: () => (
      <path
        d="M12 6.26851C8.51137 2.21546 2.53091 3.82978 3.02928 9.23268C3.35192 12.7303 5.65132 14.3872 7.87725 15.9912C9.19208 16.9386 10.4813 17.8676 11.3223 19.1465C11.5902 19.554 12.4097 19.554 12.6777 19.1465C13.5187 17.8676 14.8079 16.9386 16.1227 15.9912C18.3487 14.3872 20.6481 12.7303 20.9707 9.23268C21.4691 3.82978 15.4886 2.21546 12 6.26851Z"
        fill="currentColor"
      />
    ),
  }),
};
