import { action, observable, reaction } from "mobx";
import { DisplayMode } from "../constants/DisplayMode.enum";
import { removeFromArray } from "../utils/arrays.utils";
import { BreakpointName, breakpoint } from "../utils/breakpoints.utils";
import { isLocalhost } from "../utils/env.utils";
import { useStore } from "../utils/mobx.utils";
import { useControllers } from "./controller.hooks";
import { useOnMount } from "./lifecycle.hooks";
import { ObservableRef } from "./useObservableRef.hook";
import { useResizeObserver } from "./useResizeObserver";

export type ResizeQueryRef = ObservableRef<HTMLElement | SVGElement>;

const RESIZE_QUERIES = observable([]) as ResizeQuery[];
// @ts-ignore
if (isLocalhost) window.RESIZE_QUERIES = RESIZE_QUERIES;

export const useResizeQuery = (ref: ResizeQueryRef, defaultWidth?: number) => {
  const { UI } = useControllers();

  const s = useStore(() => ({
    ref,
    entry: null as ResizeObserverEntry | null,
    boundingBox: null as Nullable<DOMRect>,

    get ready() {
      return Boolean(s.entry);
    },

    get width() {
      return (
        s.entry?.target?.clientWidth ||
        ref.current?.clientWidth ||
        defaultWidth ||
        0
      );
    },
    get height() {
      return s.entry?.target?.clientHeight ?? ref.current?.clientHeight ?? 0;
    },

    get ratio() {
      return s.width / s.height;
    },

    get contentBottom() {
      return s.entry?.contentRect.bottom ?? 0;
    },
    get contentHeight() {
      return s.entry?.contentRect.height ?? 0;
    },
    get contentLeft() {
      return s.entry?.contentRect.left ?? 0;
    },
    get contentRight() {
      return s.entry?.contentRect.right ?? 0;
    },
    get contentTop() {
      return s.entry?.contentRect.top ?? 0;
    },
    get contentWidth() {
      return s.entry?.contentRect.width ?? 0;
    },

    get scrollWidth() {
      return s.entry?.target?.scrollWidth ?? s.width;
    },
    get scrollHeight() {
      return s.entry?.target?.scrollHeight ?? s.height;
    },

    get x() {
      return s.entry?.contentRect.x ?? 0;
    },
    get y() {
      return s.entry?.contentRect.y ?? 0;
    },

    get top(): Maybe<number> {
      return s.boundingBox?.top;
    },
    get rightFromViewportLeft() {
      return s.boundingBox?.right;
    },
    get rightFromViewportRight() {
      if (s.rightFromViewportLeft === undefined) return undefined;
      return UI.vw - s.rightFromViewportLeft;
    },
    get bottomFromViewportTop() {
      return s.boundingBox?.bottom || 0;
    },
    get bottomFromViewportBottom() {
      if (s.bottomFromViewportTop === undefined) return undefined;
      return UI.vh - s.bottomFromViewportTop;
    },
    get left() {
      return s.boundingBox?.left ?? s.contentLeft;
    },

    fromBreakpoint(n: BreakpointName) {
      return s.width >= breakpoint(n);
    },
    get displayMode(): DisplayMode {
      switch (true) {
        case s.onlyPhones:
          return DisplayMode.phone;
        case s.onlyTablets:
          return DisplayMode.tablet;
        default:
          return DisplayMode.desktop;
      }
    },
    get onlyPhones(): boolean {
      return s.width < breakpoint("tablet");
    },
    get fromPhoneMd(): boolean {
      return s.width >= breakpoint("phone-md");
    },
    get fromPhoneLg(): boolean {
      return s.width >= breakpoint("phone-lg");
    },
    get onlyTablets(): boolean {
      return s.width >= breakpoint("tablet") && s.width < breakpoint("desktop");
    },
    get fromTablet(): boolean {
      return s.width >= breakpoint("tablet");
    },
    get fromTabletLg(): boolean {
      return s.width >= breakpoint("tablet-lg");
    },
    get fromDesktop(): boolean {
      return s.width >= breakpoint("desktop");
    },
    get fromDesktopLg(): boolean {
      return s.width >= breakpoint("desktop-lg");
    },

    callbacks: [] as Function[],
    onResize: action((fn: Function) => {
      s.callbacks.push(fn);
    }),
    removeHandler: action((fn: Function) => {
      s.callbacks.splice(s.callbacks.indexOf(fn), 1);
    }),

    updateBoundingBox: action(() => {
      if (s.entry) s.boundingBox = s.entry.target.getBoundingClientRect();
    }),
  }));

  useResizeObserver(entries => {
    // We wrap it in requestAnimationFrame to avoid this error - ResizeObserver loop limit exceeded
    window.requestAnimationFrame(
      action(() => {
        if (!Array.isArray(entries) || !entries.length) return;
        s.entry = entries[0];
        s.updateBoundingBox();
      })
    );
  }, ref);

  useOnMount(() => {
    const handler = () => s.updateBoundingBox();
    window.addEventListener("resize", handler);
    if (isLocalhost) RESIZE_QUERIES.push(s);
    const disposer = reaction(
      () => `${s.width}-${s.height}`,
      () => {
        s.callbacks.forEach(c => c());
      }
    );
    return () => {
      window.removeEventListener("resize", handler);
      if (isLocalhost) removeFromArray(RESIZE_QUERIES, s);
      disposer();
    };
  });

  return s;
};

export type ResizeQuery = ReturnType<typeof useResizeQuery>;
