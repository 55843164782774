import { makeIconDef } from "./makeIconDef";

export const HamburgerMenuIconDef = {
  "hamburger-menu": makeIconDef("hamburger-menu", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 6.5H4V5.5H20V6.5ZM20 12.5H4V11.5H20V12.5ZM4 18.5H15.6364V17.5H4V18.5Z"
        fill="currentColor"
      />
    ),
    filled: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 6C4 5.44772 4.44772 5 5 5H19C19.5523 5 20 5.44772 20 6C20 6.55228 19.5523 7 19 7H5C4.44772 7 4 6.55228 4 6ZM4 12C4 11.4477 4.44772 11 5 11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H5C4.44772 13 4 12.5523 4 12ZM5 17C4.44772 17 4 17.4477 4 18C4 18.5523 4.44772 19 5 19H15.1818C15.7341 19 16.1818 18.5523 16.1818 18C16.1818 17.4477 15.7341 17 15.1818 17H5Z"
        fill="currentColor"
      />
    ),
  }),
};
