import { Observer } from "mobx-react-lite";
import React from "react";
import BaseInput from "../../../components/_base/BaseInput/BaseInput";
import BaseSelector from "../../../components/_base/BaseSelector/BaseSelector";
import { FM, useFM } from "../../../constants/lang-components.aliases";
import {
  useStudioContext,
  useStudioDesign,
} from "../../../contexts/studio/studio.context";
import {
  BoardHeaderFooterElement,
  HeaderFooterSlotConfig,
} from "../../../contexts/studio/studioDesign.contextPartial";
import { MSG } from "../../../lang/MSG.enum";
import { useProps, useStore } from "../../../utils/mobx.utils";
import { capitalizeFirstLetter } from "../../../utils/string.utils";
import StudioSidebarUIPanel from "../StudioSidebarUIPanel";

type BoardHeaderFooterDesignCustomizerProps = {
  position: "header" | "footer";
};

const BoardHeaderFooterDesignCustomizer: React.FC<BoardHeaderFooterDesignCustomizerProps> =
  React.memo(props => {
    const p = useProps(props);
    const d = useStudioDesign();
    const fm = useFM();
    const c = useStudioContext();
    const s = useStore(() => ({
      get headerArmy() {
        return c.game.$.playerA.army;
      },
      get footerArmy() {
        return c.game.$.playerB.army;
      },
      get headerArmyLabel() {
        return s.headerArmy === "black"
          ? MSG.labelPlayerBlack
          : MSG.labelPlayerRed;
      },
      get footerArmyLabel() {
        return s.footerArmy === "black"
          ? MSG.labelPlayerBlack
          : MSG.labelPlayerRed;
      },
      get headerPlayerNameLabel() {
        return s.headerArmy === "black"
          ? MSG.labelPlayerBlackName
          : MSG.labelPlayerRedName;
      },
      get footerPlayerNameLabel() {
        return s.footerArmy === "black"
          ? MSG.labelPlayerBlackName
          : MSG.labelPlayerRedName;
      },
    }));

    const renderSlotConfigurerRow = (
      config: HeaderFooterSlotConfig,
      titleMSG: MSG,
      titledefaultMessage: string
    ) => {
      return (
        <tr>
          <th>
            <FM id={titleMSG} defaultMessage={titledefaultMessage} />
          </th>
          <td>
            <BaseSelector
              form={config}
              field="element"
              appearance="system"
              options={[
                {
                  label: fm({ id: MSG.pzxyLogo, defaultMessage: "PZXY Logo" }),
                  value: BoardHeaderFooterElement.LogoPZ,
                },
                {
                  label: fm({ id: MSG.gameRecordTitle, defaultMessage: "" }),
                  value: BoardHeaderFooterElement.GameRecordTitle,
                },
                {
                  label: fm({ id: MSG.gameCollectionName, defaultMessage: "" }),
                  value: BoardHeaderFooterElement.GameCollectionName,
                },
                {
                  label: fm({ id: MSG.gameTournamentName, defaultMessage: "" }),
                  value: BoardHeaderFooterElement.GameTournamentName,
                },
                {
                  label: fm({
                    id:
                      p.position === "header"
                        ? s.headerArmyLabel
                        : s.footerArmyLabel,
                    defaultMessage:
                      (p.position === "header"
                        ? s.headerArmyLabel
                        : s.footerArmyLabel) === MSG.labelPlayerBlack
                        ? "Black"
                        : "Red",
                  }),
                  value: BoardHeaderFooterElement.PlayerArmy,
                },
                {
                  label: fm({
                    id:
                      p.position === "header"
                        ? s.headerPlayerNameLabel
                        : s.footerPlayerNameLabel,
                    defaultMessage:
                      (p.position === "header"
                        ? s.headerArmyLabel
                        : s.footerArmyLabel) === MSG.labelPlayerBlack
                        ? "Player Black with Name"
                        : "Player Red with Name",
                  }),
                  value: BoardHeaderFooterElement.PlayerName,
                },
                {
                  label: fm({
                    id: MSG.pageNumberWithCount,
                    defaultMessage: "Page Number with Count",
                  }),
                  value: BoardHeaderFooterElement.PageNumberWithTotal,
                },
                {
                  label: fm({
                    id: MSG.nthRoundCounter,
                    defaultMessage: "nth Round",
                  }),
                  value: BoardHeaderFooterElement.NthRoundCounter,
                },
                {
                  label: fm({
                    id: MSG.labelCustomText,
                    defaultMessage: "Custom Text",
                  }),
                  value: BoardHeaderFooterElement.CustomText,
                },
              ]}
            />
            {config.element === BoardHeaderFooterElement.CustomText && (
              <div>
                <BaseInput
                  form={config}
                  field="customText"
                  placeholder={fm({
                    id: MSG.labelCustomText,
                    defaultMessage: "Custom Text",
                  })}
                />
              </div>
            )}
          </td>
        </tr>
      );
    };

    return (
      <Observer
        children={() => (
          <StudioSidebarUIPanel
            className="BoardHeaderFooterDesignCustomizer"
            heading={
              <FM
                id={p.position === "header" ? MSG.labelHeader : MSG.labelFooter}
                defaultMessage={capitalizeFirstLetter(p.position)}
              />
            }
          >
            <table className="DesignCustomizerSingleColumnTable">
              <tbody>
                <tr>
                  <th>
                    <FM
                      id={MSG.labelDistanceToBoardContent}
                      defaultMessage="Distance to Content"
                    />
                  </th>
                  <td>
                    <BaseInput
                      form={d[p.position]}
                      field="distanceToBoardContent"
                      step="1"
                      min="0"
                      type="number"
                    />
                  </td>
                </tr>
                {renderSlotConfigurerRow(
                  d[p.position].start,
                  MSG.labelStartSlot,
                  "Left Slot"
                )}
                {renderSlotConfigurerRow(
                  d[p.position].middle,
                  MSG.labelMiddleSlot,
                  "Middle Slot"
                )}
                {renderSlotConfigurerRow(
                  d[p.position].end,
                  MSG.labelEndSlot,
                  "Right Slot"
                )}
              </tbody>
            </table>
          </StudioSidebarUIPanel>
        )}
      />
    );
  });

export default BoardHeaderFooterDesignCustomizer;
