export enum ColorName {
  "white" = "white",
  "gray" = "gray",
  "slate" = "slate",
  "black" = "black",

  "inkBlue" = "inkBlue",
  "red" = "red",
  "ivory" = "ivory",
  "ivoryLight" = "ivoryLight",
  "gold" = "gold",
  "teal" = "teal",
  "lightBlue" = "lightBlue",
  "beige" = "beige",
  "brown" = "brown",
  "lavender" = "lavender",
}

export enum ContextColor {
  Foreground = "foreground",
  Median = "median",
  Background = "background",
  Primary = "primary",
  Secondary = "secondary",
  Tertiary = "tertiary",
  Alert = "alert",
  Attention = "attention",
  Neutral = "neutral",
  Positive = "positive",
}

export const {
  Foreground,
  Median,
  Background,
  Primary,
  Secondary,
  Tertiary,
  Alert,
  Attention,
  Neutral,
  Positive,
} = ContextColor;
