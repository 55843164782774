import { LangCode } from "../constants/lang.enum";
import { PlayerArmy } from "../models/makePlayer.model";

const PlayerColorHanNameMap = {
  red: { en: "", sc: "红", tc: "紅" },
  black: { en: "", sc: "黑", tc: "黑" },
} as const;

export const getPlayerColorHanName = (
  playerColor?: Sometimes<PlayerArmy>,
  lang?: LangCode
) => {
  return playerColor ? PlayerColorHanNameMap[playerColor][lang ?? "tc"] : null;
};
