import {
  BoardTheme,
  PieceId,
  PieceIds,
  VariantType,
} from "../@types/app.types";
import { BoardGridTheme } from "../components/Board/Board";
import {
  BoardHeaderFooterElement,
  makePieceSetDesignConfig,
} from "../contexts/studio/studioDesign.contextPartial";
import { MSG } from "../lang/MSG.enum";
import { ColorPalette } from "../utils/colors.utils";

const BoardThemeTsingWoodboneClean: BoardTheme = {
  identifier: "tsing-woodbone-clean",
  name: MSG.themeNameTsingWoodboneClean,
  value: {
    board: {
      backgroundColor: ColorPalette.white,
      borderWidth: 0,
      borderRadius: 0,
      textColor: ColorPalette.inkBlue,
      borderColor: ColorPalette.white,
      fontSize: 48,
      fontFamily: "genwan",
      fontWeight: 600,
      inset: {
        userDefined: {
          top: 50,
          left: 50,
          right: 50,
          bottom: 150,
        },
      },
    },
    header: {
      distanceToBoardContent: 30,
      middle: {
        element: BoardHeaderFooterElement.None,
      },
    },
    footer: {
      distanceToBoardContent: 62,
      middle: {
        element: BoardHeaderFooterElement.LogoPZ,
      },
    },
    grid: {
      theme: "default" as BoardGridTheme,
      color: ColorPalette.inkBlue,
      opacity: 0.62,
    },
    columnNumberLabels: {
      enabled: true,
      red: {
        fontSize: 36,
        fontWeight: 600,
      },
      black: {
        fontSize: 36,
        fontWeight: 500,
      },
    },
    red: {
      ...makePieceSetDesignConfig("red"),
      charSet: "TsingWoodbone",
    },
    black: {
      ...makePieceSetDesignConfig("black"),
      charSet: "TsingWoodbone",
    },
    pieceSet: {
      charSet: "TsingWoodbone",
      charSetVariantDefs: {} as Record<PieceId, VariantType>,
    },
    debugMarker: {
      size: 68,
    },
  },
};

[...PieceIds].forEach(
  i =>
    (BoardThemeTsingWoodboneClean.value.pieceSet!.charSetVariantDefs![
      i as PieceId
    ] = "A")
);

export default BoardThemeTsingWoodboneClean;
