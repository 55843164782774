import { Observer } from "mobx-react-lite";
import React from "react";
import { PieceCharSet1941VariantOptionDefMap } from "../../../components/PieceChar/PieceCharSet1941Symbols";
import BaseSelector from "../../../components/_base/BaseSelector/BaseSelector";
import { FM } from "../../../constants/lang-components.aliases";
import { PieceDef } from "../../../constants/pieceSet.constants";
import {
  useStudioContext,
  useStudioDesign,
} from "../../../contexts/studio/studio.context";
import { MSG } from "../../../lang/MSG.enum";
import { useProps, useStore } from "../../../utils/mobx.utils";
import StudioSidebarUIPanel from "../StudioSidebarUIPanel";
import "./PieceDesignCustomizer.scss";

type PieceDesignCustomizerProps = {
  piece?: Sometimes<PieceDef>;
  onChange?: () => void;
};

const PieceDesignCustomizer: React.FC<PieceDesignCustomizerProps> = props => {
  const p = useProps(props);
  const d = useStudioDesign();
  const c = useStudioContext();
  const s = useStore(() => ({
    // get pieceDesign() {
    //   return d[p.piece.player];
    // },
    get player() {
      return p.piece ? c.game.$[p.piece.playerIdentifier] : null;
    },
    get army() {
      return s.player?.army;
    },
    get availableVariants() {
      if (!p.piece || !s.army) return [];
      switch (d.pieceSet.charSet) {
        case "1941":
          return (
            PieceCharSet1941VariantOptionDefMap[s.army][p.piece.name] || ["A"]
          );
        case "Genwan":
        default:
          return ["A"];
      }
    },
  }));

  return (
    <Observer
      children={() =>
        p.piece ? (
          <StudioSidebarUIPanel
            className="PieceDesignCustomizer"
            heading={
              <FM
                id={MSG.labelCurrentlySelectedPiece}
                defaultMessage="Selected Piece"
              />
            }
          >
            <form onChange={props.onChange}>
              <table>
                <tbody>
                  <tr>
                    <th>
                      <FM
                        id={MSG.labelCharacterDesignVariant}
                        defaultMessage="Character Design Variant"
                      />
                    </th>
                    <td>
                      <BaseSelector
                        form={d.pieceSet.charSetVariantDefs}
                        field={p.piece.id}
                        options={s.availableVariants}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
          </StudioSidebarUIPanel>
        ) : null
      }
    />
  );
};

export default PieceDesignCustomizer;
