import { reaction } from "mobx";
import React from "react";
import { useControllers } from "../../../hooks/controller.hooks";
import { useOnMount } from "../../../hooks/lifecycle.hooks";

type TitleManagerProps = {};

const TitleManager: React.FC<TitleManagerProps> = props => {
  const { NAVIGATOR } = useControllers();
  useOnMount(() => {
    return reaction(
      () => NAVIGATOR.windowTitle,
      title => {
        const newTitle = title || "";
        document.title = newTitle;
      },
      { fireImmediately: true }
    );
  });
  return null;
};

export default TitleManager;
