import { useStore } from "../utils/mobx.utils";

export const useObservableRef = <T extends Element>(initialValue?: T) => {
  const ref = useStore(() => ({
    current: initialValue || null,
  }));
  return ref;
};

export type ObservableRef<T extends Element> = {
  current: T | null;
};
