import * as React from "react";
import joinClassName from "../../../utils/className.utils";
import { reportError } from "../../../utils/errors.utils";
import ErrorRenderer from "../ErrorRenderer/ErrorRenderer";

interface ErrorBoundaryProps {
  className?: string;
  fallback?: (error?: unknown) => string | React.ReactElement;
}
interface ErrorBoundaryState {
  error: unknown;
}

class ErrorBoundary extends React.Component<
  React.PropsWithChildren<ErrorBoundaryProps>,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: unknown) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  componentDidCatch(error: unknown, errorInfo: unknown) {
    reportError(error as Error);
  }

  render() {
    if (!!this.state.error) {
      const { fallback: Fallback } = this.props;
      // You can render any custom fallback UI
      return (
        <div className={joinClassName("ErrorBoundary", this.props.className)}>
          {Fallback ? (
            typeof Fallback === "string" ? (
              Fallback
            ) : (
              Fallback(this.state.error)
            )
          ) : (
            <ErrorRenderer error={this.state.error} />
          )}
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
