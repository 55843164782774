import { action, reaction } from "mobx";
import { Observer } from "mobx-react-lite";
import React, { PropsWithChildren } from "react";
import { useFM } from "../../../constants/lang-components.aliases";
import { isDevelopment, isLocalhost } from "../../../env";
import { useControllers } from "../../../hooks/controller.hooks";
import { useOnMount } from "../../../hooks/lifecycle.hooks";
import { MSG } from "../../../lang/MSG.enum";
import { keepTruthy } from "../../../utils/arrays.utils";
import { useProps, useStore } from "../../../utils/mobx.utils";
import "./WindowTitle.scss";

interface WindowTitleProps {
  className?: string;
  title: string;
  visible?: boolean;
}

const WindowTitle: React.FC<PropsWithChildren<WindowTitleProps>> = props => {
  const { NAVIGATOR } = useControllers();

  const p = useProps(props);

  const fm = useFM();

  const s = useStore(() => ({
    appName: fm({ id: MSG.appName, defaultMessage: "App Name" }),
  }));

  const setTitle = action((title: string) => {
    const newTitle = keepTruthy([
      isLocalhost || isDevelopment ? "DEV" : "",
      title,
      s.appName,
    ]).join(" • ");
    NAVIGATOR.windowTitle = newTitle;
  });

  useOnMount(() =>
    reaction(
      () => p.title,
      title => setTitle(title ?? ""),
      { fireImmediately: true }
    )
  );

  return <Observer children={() => <>{p.children}</>} />;
};

export default WindowTitle;
