import { makeIconDef } from "./makeIconDef";

export const PlayIconDef = {
  play: makeIconDef("play", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9948 12.0593C19.9942 12.0587 19.9913 12.0558 19.9839 12.0515L6.04839 4.00586C6.04101 4.0016 6.03702 4.00053 6.03617 4.00032C6.03512 4.00005 6.03447 3.99999 6.03362 4C6.03136 4.00004 6.02469 4.00092 6.01613 4.00586C6.00757 4.0108 6.00347 4.01614 6.00231 4.01808C6.00187 4.0188 6.0016 4.0194 6.00131 4.02044C6.00107 4.02129 6 4.02528 6 4.0338L6 20.1251C6 20.1336 6.00107 20.1376 6.00131 20.1385C6.00146 20.139 6.0016 20.1394 6.00176 20.1398C6.00192 20.1402 6.00209 20.1405 6.00231 20.1408C6.00347 20.1428 6.00757 20.1481 6.01613 20.153C6.02469 20.158 6.03136 20.1589 6.03362 20.1589C6.03447 20.1589 6.03512 20.1589 6.03617 20.1586C6.03702 20.1584 6.04101 20.1573 6.04839 20.153L19.9839 12.1074C19.9913 12.1031 19.9942 12.1002 19.9948 12.0996C19.9952 12.0992 19.9955 12.0988 19.9957 12.0985C19.9959 12.0982 19.9961 12.0979 19.9963 12.0975C19.9974 12.0956 20 12.0893 20 12.0795C20 12.0696 19.9974 12.0634 19.9963 12.0614C19.9959 12.0606 19.9955 12.0601 19.9948 12.0593ZM20.4839 11.1855C21.172 11.5828 21.172 12.5761 20.4839 12.9734L6.54839 21.0191C5.86022 21.4164 5 20.9197 5 20.1251V4.0338C5 3.23917 5.86022 2.74252 6.54839 3.13984L20.4839 11.1855Z"
        fill="currentColor"
      />
    ),
    filled: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4839 12.9734C21.172 12.5761 21.172 11.5828 20.4839 11.1855L6.54839 3.13984C5.86022 2.74252 5 3.23917 5 4.0338L5 20.1251C5 20.9197 5.86022 21.4164 6.54839 21.0191L20.4839 12.9734Z"
        fill="currentColor"
      />
    ),
  }),
};
