import { makeIconDef } from "./makeIconDef";

export const ForbiddenIconDef = {
  forbidden: makeIconDef("forbidden", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 12C20 16.4183 16.4183 20 12 20C9.9708 20 8.11805 19.2445 6.70771 17.9994L17.9994 6.70771C19.2445 8.11805 20 9.9708 20 12ZM6.0006 17.2923L17.2923 6.0006C15.882 4.75551 14.0292 4 12 4C7.58172 4 4 7.58172 4 12C4 14.0292 4.75552 15.882 6.0006 17.2923ZM21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
        fill="currentColor"
      />
    ),
    filled: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 12C19 15.866 15.866 19 12 19C10.4277 19 8.97642 18.4816 7.80785 17.6064L17.6064 7.80785C18.4816 8.97642 19 10.4277 19 12ZM6.39363 16.1922L16.1922 6.39363C15.0236 5.51841 13.5723 5 12 5C8.13401 5 5 8.13401 5 12C5 13.5723 5.51841 15.0236 6.39363 16.1922ZM21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
        fill="currentColor"
      />
    ),
  }),
};
