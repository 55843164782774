import { action, observable } from "mobx";
import { IntlShape } from "react-intl";
import { DisplayMode } from "../constants/DisplayMode.enum";
import { breakpoint } from "../utils/breakpoints.utils";
import { RootController } from "./_root.controller";

export const makeUIController = () => {
  const c = observable({
    $root: null as Nullable<RootController>,
    vw: window.innerWidth,
    vh: window.innerHeight,
    get viewportRatio() {
      return c.vw / c.vh;
    },
    get displayMode(): DisplayMode {
      if (c.vw >= breakpoint("desktop")) return DisplayMode.desktop;
      if (c.vw >= breakpoint("tablet")) return DisplayMode.tablet;
      return DisplayMode.phone;
    },
    get onlySmallPhones(): boolean {
      return c.vw <= 375 && c.vh < 575;
    },
    get onlyPhones(): boolean {
      return c.displayMode === "phone";
    },
    get fromTablet(): boolean {
      return c.displayMode !== "phone";
    },
    get fromTabletMd(): boolean {
      return c.vw >= breakpoint("tablet-md");
    },
    get fromTabletLg(): boolean {
      return c.vw >= breakpoint("tablet-lg");
    },
    get uptoTabletLg(): boolean {
      return c.vw < breakpoint("tablet-lg");
    },
    get onlyTablets(): boolean {
      return c.displayMode === "tablet";
    },
    get uptoDesktop(): boolean {
      return c.vw < breakpoint("desktop");
    },
    get fromDesktop(): boolean {
      return c.displayMode === "desktop";
    },
    get fromDesktopMd(): boolean {
      return c.vw >= breakpoint("desktop-md");
    },
    get fromDesktopLg(): boolean {
      return c.vw >= breakpoint("desktop-lg");
    },

    get fromTabletAndTall(): boolean {
      return c.fromTablet && c.vh >= 625;
    },

    intl: null as Nullable<IntlShape>,

    $init: action((root: RootController) => {
      c.$root = root;
    }),

    // $reset: async () => {
    // },
  });
  const measureViewport = action(() => {
    c.vw = window.innerWidth;
    c.vh = window.innerHeight;
  });
  window.addEventListener("resize", measureViewport);
  return c;
};

export type UIController = ReturnType<typeof makeUIController>;
