import { makeIconDef } from "./makeIconDef";

export const ChevronIconDefs = {
  "chevron-left": makeIconDef("chevron-left", {
    regular: () => (
      <path
        d="M15 5L8 12L15 19"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    ),
    filled: () => (
      <path
        d="M15 5L8 12L15 19"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    ),
  }),
  "chevron-right": makeIconDef("chevron-right", {
    regular: () => (
      <path
        d="M9 5L16 12L9 19"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    ),
    filled: () => (
      <path
        d="M9 5L16 12L9 19"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    ),
  }),
  "chevron-down": makeIconDef("chevron-down", {
    regular: () => (
      <path
        d="M20 8L11 16.0867L4 8"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    ),
    filled: () => (
      <path
        d="M20 8L11 16.0867L4 8"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    ),
  }),
  "chevron-up": makeIconDef("chevron-up", {
    regular: () => (
      <path
        d="M4 15L12 8L20 15"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    ),
    filled: () => (
      <path
        d="M4 15L12 8L20 15"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    ),
  }),
};
