import { makeIconDef } from "./makeIconDef";

export const StopIconDef = {
  stop: makeIconDef("stop", {
    regular: () => (
      <rect
        x="4.5"
        y="4.5"
        width="15"
        height="15"
        rx="1.5"
        stroke="currentColor"
      />
    ),
    filled: () => (
      <rect x="4" y="4" width="16" height="16" rx="2" fill="currentColor" />
    ),
  }),
};
