import { makeIconDef } from "./makeIconDef";

export const MoreIconDef = {
  more: makeIconDef("more", {
    regular: () => (
      <>
        <rect x="4" y="10" width="3" height="3" rx="1.5" fill="currentColor" />
        <rect
          x="10.5"
          y="10"
          width="3"
          height="3"
          rx="1.5"
          fill="currentColor"
        />
        <rect x="17" y="10" width="3" height="3" rx="1.5" fill="currentColor" />
      </>
    ),
  }),
};
