import { action } from "mobx";
import { Observer } from "mobx-react-lite";
import React from "react";
import BaseButton from "../../components/_base/BaseButton/BaseButton";
import {
  migratePieceNumericalIdToNamedId,
  migratePiecePlayerToArmySpecificVersion,
} from "../../constants/defaultGameSetup";
import { FM } from "../../constants/lang-components.aliases";
import { useStudioContext } from "../../contexts/studio/studio.context";
import { useObservableRef } from "../../hooks/useObservableRef.hook";
import { MSG } from "../../lang/MSG.enum";
import { GameSnapshot, makeGame } from "../../models/makeGame.model";
import { first } from "../../utils/ramdaEquivalents.utils";
import "./GameImportJsonButton.scss";

type GameImportJsonButtonProps = {};

const GameImportJsonButton: React.FC<GameImportJsonButtonProps> = props => {
  const c = useStudioContext();

  const importGameRecord = action(() => {
    inputRef.current?.click();
  });
  const formRef = useObservableRef<HTMLFormElement>();
  const inputRef = useObservableRef<HTMLInputElement>();
  const handleFileInputChange = () => {
    const file =
      inputRef.current?.files && inputRef.current.files.length > 0
        ? inputRef.current.files[0]
        : null;
    if (!file) return;
    console.log(file);
    const reader = new FileReader();
    reader.onload = action(e => {
      if (!e.target?.result) {
        console.error("Failed to read file");
        return;
      }
      const value = JSON.parse(e.target.result as string) as GameSnapshot;
      if (first(value.setup ?? [])?.id.match(/\d/)) {
        value.setup?.forEach(pc => {
          migratePieceNumericalIdToNamedId(pc);
          migratePiecePlayerToArmySpecificVersion(pc);
        });
      }
      c.game = makeGame(value);
      // if (c.game.length === 0) c.mode = 'edit';
      // if (c.game.length > 0) c.mode = 'export';
    });
    reader.readAsText(file);
    formRef.current?.reset();
  };

  return (
    <Observer
      children={() => (
        <div className="GameImportJsonButton">
          <BaseButton onClick={importGameRecord}>
            <FM
              id={MSG.importJsonButtonLabel}
              defaultMessage="Import JSON..."
            />
          </BaseButton>
          <form ref={formRef}>
            <input
              type="file"
              onChange={handleFileInputChange}
              ref={inputRef}
              accept=".json"
            />
          </form>
        </div>
      )}
    />
  );
};

export default GameImportJsonButton;
