import React from "react";
import { ArrowIconDef } from "./Arrow.icon";
import { BackIconDef } from "./Back.icon";
import { BlankIconDef } from "./Blank.icon";
import { CheckIconDef } from "./Check.icon";
import { CheckCircleIconDef } from "./CheckCircle.icon";
import { ChevronIconDefs } from "./Chevrons.icon";
import { CircleIconDef } from "./Circle.icon";
import { CloseIconDef } from "./Close.icon";
import { CogIconDef } from "./Cog.icon";
import { CopyIconDef } from "./Copy.icon";
import { DeleteIconDef } from "./Delete.icon";
import { DocumentsIconDef } from "./Documents.icon";
import { DropdownArrowIconDef } from "./DropdownArrow.icon";
import { ExternalLinkIconDef } from "./ExternalLink.icon";
import { ForbiddenIconDef } from "./Forbidden.icon";
import { HamburgerMenuIconDef } from "./HamburgerMenu.icon";
import { HeartIconDef } from "./Heart.icon";
import { HideKeyboardIconDef } from "./HideKeyboard.icon";
import { HomeIconDef } from "./Home.icon";
import { InfoIconDef } from "./Info.icon";
import { LockIconDef } from "./Lock.icon";
import { MailIconDef } from "./Mail.icon";
import { MapPinIconDef } from "./MapPin.icon";
import { MicrophoneIconDefs } from "./Microphones.icons";
import { MinusIconDef } from "./Minus.icon";
import { MoreIconDef } from "./More.icon";
import { NestedViewIconDef } from "./NestedView.icon";
import { NotificationIconDef } from "./Notification.icon";
import { PencilIconDef } from "./Pencil.icon";
import { PeopleIconDef } from "./People.icon";
import { PersonCircleIconDef } from "./PersonCircle.icon";
import { PhoneIconDef } from "./Phone.icon";
import { PlayIconDef } from "./Play.icon";
import { PlayCircleIconDef } from "./PlayCircle.icon";
import { PlusIconDef } from "./Plus.icon";
import { PowerIconDef } from "./Power.icon";
import { PrintIconDef } from "./Print.icon";
import { QuestionIconDef } from "./Question.icon";
import { RefreshIconDef } from "./Refresh.icon";
import { SearchIconDef } from "./Search.icon";
import { SendIconDef } from "./Send.icon";
import { ShareIconDef } from "./Share.icon";
import { ShieldIconDef } from "./Shield.icon";
import { ShieldWarningIconDef } from "./ShieldWarning.icon";
import { StopIconDef } from "./Stop.icon";
import { UnlockIconDef } from "./Unlock.icon";
import { VideoIconDef } from "./Video.icon";
import { WarningIconDef } from "./Warning.icon";
import { ZoomIconDef } from "./Zoom.icon";

export type IconDef = {
  regular: () => React.ReactElement;
  filled?: () => React.ReactElement;
  // color?: () => React.ReactElement,
};

export type IconVariant = keyof IconDef;

export const IconDefs = {
  ...ArrowIconDef,
  ...BackIconDef,
  ...BlankIconDef,
  ...CheckCircleIconDef,
  ...CheckIconDef,
  ...ChevronIconDefs,
  ...CircleIconDef,
  ...CloseIconDef,
  ...CogIconDef,
  ...CopyIconDef,
  ...DeleteIconDef,
  ...DocumentsIconDef,
  ...DropdownArrowIconDef,
  ...ExternalLinkIconDef,
  ...ForbiddenIconDef,
  ...HamburgerMenuIconDef,
  ...HeartIconDef,
  ...HideKeyboardIconDef,
  ...HomeIconDef,
  ...InfoIconDef,
  ...LockIconDef,
  ...MailIconDef,
  ...MapPinIconDef,
  ...MicrophoneIconDefs,
  ...MinusIconDef,
  ...MoreIconDef,
  ...NestedViewIconDef,
  ...NotificationIconDef,
  ...PencilIconDef,
  ...PeopleIconDef,
  ...PersonCircleIconDef,
  ...PhoneIconDef,
  ...PlayCircleIconDef,
  ...PlayIconDef,
  ...PlusIconDef,
  ...PowerIconDef,
  ...PrintIconDef,
  ...QuestionIconDef,
  ...RefreshIconDef,
  ...SearchIconDef,
  ...SendIconDef,
  ...ShareIconDef,
  ...ShieldIconDef,
  ...ShieldWarningIconDef,
  ...StopIconDef,
  ...UnlockIconDef,
  ...VideoIconDef,
  ...WarningIconDef,
  ...ZoomIconDef,
} as const;

export type IconName = keyof typeof IconDefs;
