import { makeIconDef } from "./makeIconDef";

export const PlayCircleIconDef = {
  "play-circle": makeIconDef("play-circle", {
    regular: () => (
      <>
        <circle cx="12" cy="12" r="8.5" stroke="currentColor" />
        <path
          d="M16.25 11.567C16.5833 11.7594 16.5833 12.2406 16.25 12.433L10.25 15.8971C9.91667 16.0896 9.5 15.849 9.5 15.4641L9.5 8.5359C9.5 8.151 9.91667 7.91044 10.25 8.10289L16.25 11.567Z"
          fill="currentColor"
        />
      </>
    ),
    filled: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM9.5 15.4641C9.5 15.849 9.91667 16.0896 10.25 15.8971L16.25 12.433C16.5833 12.2406 16.5833 11.7594 16.25 11.567L10.25 8.10289C9.91667 7.91043 9.5 8.151 9.5 8.5359L9.5 15.4641Z"
        fill="currentColor"
      />
    ),
  }),
};
