import { makeIconDef } from "./makeIconDef";

export const DeleteIconDef = {
  delete: makeIconDef("delete", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 3.5C8 3.22386 8.22386 3 8.5 3H15.5C15.7761 3 16 3.22386 16 3.5C16 3.77614 15.7761 4 15.5 4H8.5C8.22386 4 8 3.77614 8 3.5ZM3 6.5C3 6.22386 3.22386 6 3.5 6H6.0808H17.9192H20.5C20.7761 6 21 6.22386 21 6.5C21 6.77614 20.7761 7 20.5 7H18.9193C18.9192 7.02565 18.9182 7.05151 18.9162 7.07754L17.9051 20.0775C17.8646 20.5982 17.4303 21 16.9081 21H7.09191C6.5697 21 6.13541 20.5982 6.09492 20.0775L5.08381 7.07754C5.08178 7.05151 5.08077 7.02565 5.08074 7H3.5C3.22386 7 3 6.77614 3 6.5ZM6.0808 7L7.09191 20H16.9081L17.9192 7H6.0808ZM10 9.5C10.2761 9.5 10.5 9.72386 10.5 10L10.5 16C10.5 16.2761 10.2761 16.5 10 16.5C9.72386 16.5 9.5 16.2761 9.5 16L9.5 10C9.5 9.72386 9.72386 9.5 10 9.5ZM14.5 10C14.5 9.72386 14.2761 9.5 14 9.5C13.7239 9.5 13.5 9.72386 13.5 10V16C13.5 16.2761 13.7239 16.5 14 16.5C14.2761 16.5 14.5 16.2761 14.5 16V10Z"
        fill="currentColor"
      />
    ),
  }),
};
