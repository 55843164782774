import { PieceName } from "../constants/pieceSet.constants";
import { GameBoardDesignConfigInstance } from "../contexts/studio/studioDesign.contextPartial";
import { MSG } from "../lang/MSG.enum";
import { PlayerArmy } from "../models/makePlayer.model";
import { DeepOptional } from "./util.types";

export type BoardTheme = {
  identifier: string;
  name: MSG;
  value: DeepOptional<GameBoardDesignConfigInstance>;
};

export const PieceIds = [
  "rr1",
  "rh1",
  "re1",
  "ra1",
  "rg1",
  "ra2",
  "re2",
  "rh2",
  "rr2",
  "rc1",
  "rc2",
  "rs1",
  "rs2",
  "rs3",
  "rs4",
  "rs5",
  "br1",
  "bh1",
  "be1",
  "ba1",
  "bg1",
  "ba2",
  "be2",
  "bh2",
  "br2",
  "bc1",
  "bc2",
  "bs1",
  "bs2",
  "bs3",
  "bs4",
  "bs5",
] as const;

export type PieceId = ArrayElementType<typeof PieceIds>;

export const VariantTypes = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
] as const;

export type VariantType = ArrayElementType<typeof VariantTypes>;

export type VariantOptionDefMap = Record<
  PlayerArmy,
  Record<PieceName, VariantType[]>
>;
