import { Observer } from "mobx-react-lite";
import React, { PropsWithChildren, ReactNode } from "react";
import joinClassName from "../../utils/className.utils";
import "./StudioSidebarUIPanel.scss";

type StudioSidebarUIPanelProps = {
  className?: string;
  heading?: ReactNode;
};

const StudioSidebarUIPanel: React.FC<
  PropsWithChildren<StudioSidebarUIPanelProps>
> = props => {
  return (
    <Observer
      children={() => (
        <div className={joinClassName("StudioSidebarUIPanel", props.className)}>
          {props.heading && (
            <header className="StudioSidebarUIPanelHeader">
              <h4>{props.heading}</h4>
            </header>
          )}
          <div className="StudioSidebarUIPanelContent">{props.children}</div>
        </div>
      )}
    />
  );
};

export default StudioSidebarUIPanel;
