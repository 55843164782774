import { PieceDef } from "./pieceSet.constants";

export const defaultGameSetup: PieceDef[] = [
  {
    id: "rr1",
    name: "rook",
    playerIdentifier: "playerB",
    row: 9,
    col: 0,
    alive: true,
  },
  {
    id: "rh1",
    name: "horse",
    playerIdentifier: "playerB",
    row: 9,
    col: 1,
    alive: true,
  },
  {
    id: "re1",
    name: "elephant",
    playerIdentifier: "playerB",
    row: 9,
    col: 2,
    alive: true,
  },
  {
    id: "ra1",
    name: "advisor",
    playerIdentifier: "playerB",
    row: 9,
    col: 3,
    alive: true,
  },
  {
    id: "rg1",
    name: "general",
    playerIdentifier: "playerB",
    row: 9,
    col: 4,
    alive: true,
  },
  {
    id: "ra2",
    name: "advisor",
    playerIdentifier: "playerB",
    row: 9,
    col: 5,
    alive: true,
  },
  {
    id: "re2",
    name: "elephant",
    playerIdentifier: "playerB",
    row: 9,
    col: 6,
    alive: true,
  },
  {
    id: "rh2",
    name: "horse",
    playerIdentifier: "playerB",
    row: 9,
    col: 7,
    alive: true,
  },
  {
    id: "rr2",
    name: "rook",
    playerIdentifier: "playerB",
    row: 9,
    col: 8,
    alive: true,
  },
  {
    id: "rc1",
    name: "canon",
    playerIdentifier: "playerB",
    row: 7,
    col: 1,
    alive: true,
  },
  {
    id: "rc2",
    name: "canon",
    playerIdentifier: "playerB",
    row: 7,
    col: 7,
    alive: true,
  },
  {
    id: "rs1",
    name: "soldier",
    playerIdentifier: "playerB",
    row: 6,
    col: 0,
    alive: true,
  },
  {
    id: "rs2",
    name: "soldier",
    playerIdentifier: "playerB",
    row: 6,
    col: 2,
    alive: true,
  },
  {
    id: "rs3",
    name: "soldier",
    playerIdentifier: "playerB",
    row: 6,
    col: 4,
    alive: true,
  },
  {
    id: "rs4",
    name: "soldier",
    playerIdentifier: "playerB",
    row: 6,
    col: 6,
    alive: true,
  },
  {
    id: "rs5",
    name: "soldier",
    playerIdentifier: "playerB",
    row: 6,
    col: 8,
    alive: true,
  },

  {
    id: "br1",
    name: "rook",
    playerIdentifier: "playerA",
    row: 0,
    col: 0,
    alive: true,
  },
  {
    id: "bh1",
    name: "horse",
    playerIdentifier: "playerA",
    row: 0,
    col: 1,
    alive: true,
  },
  {
    id: "be1",
    name: "elephant",
    playerIdentifier: "playerA",
    row: 0,
    col: 2,
    alive: true,
  },
  {
    id: "ba1",
    name: "advisor",
    playerIdentifier: "playerA",
    row: 0,
    col: 3,
    alive: true,
  },
  {
    id: "bg1",
    name: "general",
    playerIdentifier: "playerA",
    row: 0,
    col: 4,
    alive: true,
  },
  {
    id: "ba2",
    name: "advisor",
    playerIdentifier: "playerA",
    row: 0,
    col: 5,
    alive: true,
  },
  {
    id: "be2",
    name: "elephant",
    playerIdentifier: "playerA",
    row: 0,
    col: 6,
    alive: true,
  },
  {
    id: "bh2",
    name: "horse",
    playerIdentifier: "playerA",
    row: 0,
    col: 7,
    alive: true,
  },
  {
    id: "br2",
    name: "rook",
    playerIdentifier: "playerA",
    row: 0,
    col: 8,
    alive: true,
  },
  {
    id: "bc1",
    name: "canon",
    playerIdentifier: "playerA",
    row: 2,
    col: 1,
    alive: true,
  },
  {
    id: "bc2",
    name: "canon",
    playerIdentifier: "playerA",
    row: 2,
    col: 7,
    alive: true,
  },
  {
    id: "bs1",
    name: "soldier",
    playerIdentifier: "playerA",
    row: 3,
    col: 0,
    alive: true,
  },
  {
    id: "bs2",
    name: "soldier",
    playerIdentifier: "playerA",
    row: 3,
    col: 2,
    alive: true,
  },
  {
    id: "bs3",
    name: "soldier",
    playerIdentifier: "playerA",
    row: 3,
    col: 4,
    alive: true,
  },
  {
    id: "bs4",
    name: "soldier",
    playerIdentifier: "playerA",
    row: 3,
    col: 6,
    alive: true,
  },
  {
    id: "bs5",
    name: "soldier",
    playerIdentifier: "playerA",
    row: 3,
    col: 8,
    alive: true,
  },
];

const numericalPieceIdToNamedIdMap = {
  "1": "rr1",
  "2": "rh1",
  "3": "re1",
  "4": "ra1",
  "5": "rg1",
  "6": "ra2",
  "7": "re2",
  "8": "rh2",
  "9": "rr2",
  "10": "rc1",
  "11": "rc2",
  "12": "rs1",
  "13": "rs2",
  "14": "rs3",
  "15": "rs4",
  "16": "rs5",
  "17": "br1",
  "18": "bh1",
  "19": "be1",
  "20": "ba1",
  "21": "bg1",
  "22": "ba2",
  "23": "be2",
  "24": "bh2",
  "25": "br2",
  "26": "bc1",
  "27": "bc2",
  "28": "bs1",
  "29": "bs2",
  "30": "bs3",
  "31": "bs4",
  "32": "bs5",
};

export const migratePieceNumericalIdToNamedId = (
  pc: PieceDef & { id: string }
) => {
  pc.id = Reflect.get(numericalPieceIdToNamedIdMap, pc.id) ?? pc.id;
};
export const migratePiecePlayerToArmySpecificVersion = (
  pc: PieceDef & AnyObject
) => {
  if ("player" in pc) {
    pc.playerIdentifier = pc.player === "red" ? "playerB" : "playerA";
    delete pc.player;
  }
};
