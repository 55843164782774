import { Observer } from "mobx-react-lite";
import React from "react";
import { ContextColor } from "../../../constants/color.enum";
import { useContextColorStyle } from "../../../hooks/useContextColor.hook";
import BaseIcon from "../BaseIcon/BaseIcon";
import { IconVariant } from "../Symbols/iconDefs";
// import './BaseSelectorInlineOption.scss';

interface BaseSelectorInlineOptionProps {
  option: any;
  label: string | number | React.ReactChild;
  disabled: boolean;
  isSelected: boolean;
  onClick?: (e: any) => unknown;
  iconVariant?: IconVariant;
  value: string | number | undefined;
}

const BaseSelectorInlineOption: React.FC<
  BaseSelectorInlineOptionProps
> = props => {
  const { isSelected, disabled, label, onClick, option, value } = props;
  const { color, icon } = option;
  const iconVariant = option.iconVariant || props.iconVariant;
  // const handler = (e: any) => {
  //   if (isSelected) return;
  //   onClick(e)
  // }
  const style = useContextColorStyle(ContextColor.Primary, color);
  return (
    <Observer
      children={() => (
        <div
          className={[
            "BaseSelectorInlineOption",
            isSelected ? "selected" : false,
            disabled ? "disabled" : false,
          ]
            .filter(i => i)
            .join(" ")}
          onClick={onClick}
          style={style}
          data-label={label}
          data-value={value}
        >
          {icon && <BaseIcon name={icon} variant={iconVariant} />}
          <span>{label}</span>
        </div>
      )}
    />
  );
};

export default BaseSelectorInlineOption;
