import { makeIconDef } from "./makeIconDef";

export const WarningIconDef = {
  warning: makeIconDef("warning", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7942 17.5L12.866 5.5C12.4811 4.83333 11.5189 4.83333 11.134 5.5L4.20578 17.5C3.82088 18.1667 4.302 19 5.0718 19H18.9282C19.698 19 20.1791 18.1667 19.7942 17.5ZM13.7321 5C12.9623 3.66667 11.0378 3.66667 10.268 5L3.33975 17C2.56995 18.3333 3.5322 20 5.0718 20H18.9282C20.4678 20 21.4301 18.3333 20.6603 17L13.7321 5ZM13 16.4688C13 17.021 12.5523 17.4688 12 17.4688C11.4477 17.4688 11 17.021 11 16.4688C11 15.9165 11.4477 15.4688 12 15.4688C12.5523 15.4688 13 15.9165 13 16.4688ZM11.7702 8C11.4863 8 11.2594 8.23611 11.2706 8.51979L11.481 13.8323C11.4916 14.1005 11.7121 14.3125 11.9806 14.3125H12.0194C12.2879 14.3125 12.5084 14.1005 12.519 13.8323L12.7294 8.51979C12.7407 8.2361 12.5137 8 12.2298 8H11.7702Z"
        fill="currentColor"
      />
    ),
  }),
};
