import { Observer } from "mobx-react-lite";
import React from "react";
import { BoardBackgroundTextureDefSet } from "../../../components/Board/BoardBackgroundTextureDefSet";
import BaseInput from "../../../components/_base/BaseInput/BaseInput";
import BaseSelector from "../../../components/_base/BaseSelector/BaseSelector";
import { FM, useFM } from "../../../constants/lang-components.aliases";
import { useStudioDesign } from "../../../contexts/studio/studio.context";
import { MSG } from "../../../lang/MSG.enum";
import StudioSidebarUIPanel from "../StudioSidebarUIPanel";
import "./BoardDesignCustomizer.scss";
import BoardInsetEditor from "./BoardInsetEditor";

type BoardDesignCustomizerProps = {};

const BoardDesignCustomizer: React.FC<BoardDesignCustomizerProps> = React.memo(
  props => {
    const d = useStudioDesign();
    const fm = useFM();

    return (
      <Observer
        children={() => (
          <StudioSidebarUIPanel
            className="BoardDesignCustomizer"
            heading={
              <FM
                id={MSG.customizerOptionBoardDesignSectionTitle}
                defaultMessage="Board Design"
              />
            }
          >
            <table>
              <tbody>
                <tr>
                  <th>
                    <FM
                      id={MSG.customizerOptionBoardBackgroundColor}
                      defaultMessage="Background"
                    />
                  </th>
                  <td>
                    <BaseInput
                      type="color"
                      form={d.board}
                      field="backgroundColor"
                    />
                  </td>
                  <th>
                    <FM
                      id={MSG.customizerOptionBoardBackgroundImage}
                      defaultMessage="BG Image"
                    />
                  </th>
                  <td>
                    <BaseSelector
                      form={d.board}
                      field="backgroundImage"
                      appearance="system"
                      options={Object.values(BoardBackgroundTextureDefSet).map(
                        option => ({
                          ...option,
                          label: fm({
                            id: option.label,
                            defaultMessage: option.value,
                          }),
                        })
                      )}
                    />
                  </td>
                </tr>

                <tr>
                  <th>
                    <FM
                      id={MSG.customizerOptionBoardBorderColor}
                      defaultMessage="Border"
                    />
                  </th>
                  <td>
                    <BaseInput
                      type="color"
                      form={d.board}
                      field="borderColor"
                    />
                  </td>
                  <th>
                    <FM
                      id={MSG.customizerOptionBoardBorderWidth}
                      defaultMessage="Border Width"
                    />
                  </th>
                  <td>
                    <BaseInput
                      type="number"
                      min="0"
                      step="1"
                      form={d.board}
                      field="borderWidth"
                    />
                  </td>
                </tr>

                <tr>
                  <th>
                    <FM
                      id={MSG.customizerOptionBoardGridColor}
                      defaultMessage="Grid Color"
                    />
                  </th>
                  <td>
                    <BaseInput type="color" form={d.grid} field="color" />
                  </td>
                  <th>
                    <FM
                      id={MSG.customizerOptionBoardGridOpacity}
                      defaultMessage="Grid Opacity"
                    />
                  </th>
                  <td>
                    <BaseInput
                      type="number"
                      min="0"
                      max="1"
                      step="0.1"
                      form={d.grid}
                      field="opacity"
                    />
                  </td>
                </tr>

                <tr>
                  <th>
                    <FM
                      id={MSG.customizerOptionBoardFontFamily}
                      defaultMessage="Font Family"
                    />
                  </th>
                  <td>
                    <BaseSelector
                      form={d.board}
                      field="fontFamily"
                      appearance="system"
                      options={[
                        // { label: fm({ id: MSG.fontNameSystemSans, defaultMessage: 'System Font' }), value: 'system' },
                        {
                          label: fm({
                            id: MSG.fontNameGenwan,
                            defaultMessage: "Genwan",
                          }),
                          value: "genwan",
                        },
                        {
                          label: fm({
                            id: MSG.fontNameTaipeiSans,
                            defaultMessage: "Taipei Sans",
                          }),
                          value: "taipeiSans",
                        },
                        {
                          label: fm({
                            id: MSG.fontNameSourceHanSerifSC,
                            defaultMessage: "Source Han Serif SC",
                          }),
                          value: "sourceHanSerifSC",
                        },
                        {
                          label: fm({
                            id: MSG.fontNameSourceHanSansSC,
                            defaultMessage: "Source Han Sans SC",
                          }),
                          value: "sourceHanSansSC",
                        },
                      ]}
                    />
                  </td>
                  <th>
                    <FM
                      id={MSG.customizerOptionBoardFontSize}
                      defaultMessage="Font Size"
                    />
                  </th>
                  <td>
                    <BaseInput
                      form={d.board}
                      field="fontSize"
                      type="number"
                      min="1"
                      step="1"
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <FM
                      id={MSG.customizerOptionBoardFontWeight}
                      defaultMessage="Font Weight"
                    />
                  </th>
                  <td>
                    <BaseInput
                      form={d.board}
                      field="fontWeight"
                      type="number"
                      min="300"
                      max="700"
                      step="100"
                    />
                  </td>
                  <th>
                    <FM
                      id={MSG.customizerOptionBoardTextColor}
                      defaultMessage="Text Color"
                    />
                  </th>
                  <td>
                    <BaseInput type="color" form={d.board} field="textColor" />
                  </td>
                </tr>
                <tr>
                  <th>
                    <FM
                      id={MSG.customizerOptionBoardTextOpacity}
                      defaultMessage="Text Opacity"
                    />
                  </th>
                  <td>
                    <BaseInput
                      type="number"
                      min="0"
                      max="1"
                      step="0.1"
                      form={d.board}
                      field="textOpacity"
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <FM
                      id={MSG.customizerOptionBoardInset}
                      defaultMessage="Board Inset"
                    />
                  </th>
                  <td colSpan={3}>
                    <BoardInsetEditor />
                  </td>
                </tr>
              </tbody>
            </table>
          </StudioSidebarUIPanel>
        )}
      />
    );
  }
);

export default BoardDesignCustomizer;
