import { makeIconDef } from "./makeIconDef";

export const PencilIconDef = {
  pencil: makeIconDef("pencil", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3441 4.82843C20.1252 5.60948 20.1252 6.87581 19.3441 7.65685L18.2835 8.71751L15.4551 5.88909L16.5157 4.82843C17.2968 4.04738 18.5631 4.04738 19.3441 4.82843ZM14.748 6.59619L6.69255 14.6516C6.30807 15.0361 6.03531 15.5178 5.90344 16.0453L5.16216 19.0104L8.12726 18.2691C8.65476 18.1373 9.1365 17.8645 9.52097 17.48L17.5764 9.42462L14.748 6.59619ZM20.0513 8.36396C21.2228 7.19239 21.2228 5.29289 20.0513 4.12132C18.8797 2.94975 16.9802 2.94975 15.8086 4.12132L5.98544 13.9445C5.4728 14.4571 5.10913 15.0994 4.9333 15.8028L3.98991 19.5763C3.89836 19.9425 4.23006 20.2742 4.59625 20.1827L8.3698 19.2393C9.07312 19.0634 9.71544 18.6998 10.2281 18.1871L20.0513 8.36396Z"
        fill="currentColor"
      />
    ),
  }),
};
