export function reportError(e: string | Error | unknown) {
  const error = typeof e === "string" ? new Error(e) : e;
  console.error(error);
}

export function runAndIgnoreError(fn?: Function) {
  try {
    fn && fn();
  } catch (e) {
    console.warn("An error was ignored:", e);
  }
}
