import { makeIconDef } from "./makeIconDef";

export const PlusIconDef = {
  plus: makeIconDef("plus", {
    regular: () => <path d="M4 12H20M12 4V20" stroke="currentColor" />,
    filled: () => (
      <path d="M4 12H20M12 4V20" stroke="currentColor" strokeWidth="2" />
    ),
  }),
};
