/**
 * Due to limitations of canvasvg,
 * Do not use the following fonts / type of fonts in any font stacks that could be used for rendering:
 * * -apple-system
 * * web fonts
 */
export const fontFamilyMap = {
  system:
    '-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Ubuntu","Helvetica Neue",sans-serif',
  sourceHanSansSC: 'Inter,思源黑体,"Source Han Sans SC",sans-serif',
  sourceHanSansTC: 'Inter,思源黑體,"Source Han Sans TC",sans-serif',
  sourceHanSerifSC: '"NewsReader",思源宋体,"Source Han Serif SC",Georgia,serif',
  sourceHanSerifTC: '"NewsReader",思源宋體,"Source Han Serif TC",Georgia,serif',
  genwan:
    '"NewsReader",源雲明體,"GenWanMin TW",Georgia,Cambria,"Times New Roman",Times,serif',
  taipeiSans: 'Inter,台北黑體,"Taipei Sans TC Beta",sans-serif',
  ui: 'Inter,台北黑體,-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Ubuntu","Helvetica Neue",sans-serif',
  monospace: '"Iosevka Web",Menlo,Monaco,Consolas,"Courier New",monospace',
};

export type FontFamilyOption = StringKeyOf<typeof fontFamilyMap>;

export const getFont = (label: FontFamilyOption) => {
  return fontFamilyMap[label];
};
