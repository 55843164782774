import { action } from "mobx";
import { Observer } from "mobx-react-lite";
import React from "react";
import { FM, useFM } from "../../constants/lang-components.aliases";
import { useStudioContext } from "../../contexts/studio/studio.context";
import { useControllers } from "../../hooks/controller.hooks";
import { useOnMount } from "../../hooks/lifecycle.hooks";
import { useCreateResizeQueryWithRef } from "../../hooks/useCreateResizeQueryWithRef.hook";
import { LOCALE } from "../../lang/LOCALE.enum";
import { MSG } from "../../lang/MSG.enum";
import { makeGame } from "../../models/makeGame.model";
import GameImportJsonButton from "../../pages/Studio/GameImportJsonButton";
import BaseButton from "../_base/BaseButton/BaseButton";
import BaseSelector from "../_base/BaseSelector/BaseSelector";
import WindowTitle from "../_base/WindowTitle/WindowTitle";
import "./StudioTitleBar.scss";

type StudioTitleBarProps = {};

const StudioTitleBar: React.FC<StudioTitleBarProps> = React.memo(props => {
  const { ref, query } = useCreateResizeQueryWithRef();

  const { SETTINGS } = useControllers();

  const context = useStudioContext();

  const createNewGame = action(() => {
    context.game = makeGame();
    context.mode = "edit";
  });

  const recordHeightAsCssVar = () => {
    document
      .querySelector<HTMLDivElement>(".AppContainer")
      ?.style.setProperty("--StudioTitleBarHeight", query.height + "px");
  };

  useOnMount(() => {
    query.onResize(recordHeightAsCssVar);
    recordHeightAsCssVar();
  });

  const fm = useFM();

  return (
    <Observer
      children={() => (
        <header className="StudioTitleBar" ref={ref}>
          <div className="StudioTitleBar__CenterSlot">
            <WindowTitle
              title={fm({ id: MSG.appName, defaultMessage: "棋谱工作室" })}
            >
              <h1>
                <FM id={MSG.appName} defaultMessage="棋谱工作室" />
              </h1>
            </WindowTitle>
          </div>
          <div className="StudioTitleBar__StartSlot">
            <BaseButton
              onClick={createNewGame}
              Label={
                <FM id={MSG.createNewGame} defaultMessage="Create a new game" />
              }
              primary
            />
            <GameImportJsonButton />
          </div>
          <div className="StudioTitleBar__EndSlot">
            <BaseSelector
              form={SETTINGS}
              field="locale"
              options={[
                { label: "汉", value: LOCALE.ZH_CN },
                { label: "漢", value: LOCALE.ZH_HK },
                { label: "EN", value: LOCALE.EN_US },
              ]}
            />
          </div>
        </header>
      )}
    />
  );
});

export default StudioTitleBar;
