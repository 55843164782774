import {
  PieceAbbrMap,
  PieceDef,
  PieceDefList,
} from "../constants/pieceSet.constants";
import { Delta } from "../models/makeMove.model";
import { isValidColIndex } from "./rowOrColumn.utils";

export const getPieceNameFromString = (input: string) => {
  for (let [name, altNames] of Object.entries(PieceAbbrMap)) {
    if ((altNames as readonly string[]).includes(input)) return name;
  }
  return input;
};

export const getAlivePieceAtCoord = (
  setup: PieceDefList,
  col: number,
  row: number
) => {
  return setup.find(
    piece => piece.alive && piece.row === row && piece.col === col
  );
};
export const isValidDelta = (piece: PieceDef, delta: Delta) => {
  const colAfter = piece.col + delta.col;
  if (isValidColIndex(colAfter)) return true;
  const rowAfter = piece.row + delta.row;
  if (isValidColIndex(rowAfter)) return true;
  return false;
};

/**
 * TODO: a function to check each piece to see which one can legally move in this situation
 */
export const getLegalDeltaSetOfPiece = (
  piece: PieceDef,
  setupBefore: PieceDefList
): Delta[] => {
  // const forwardSign = piece.playerIdentifier === "playerB" ? -1 : 1;
  const result = [] as Delta[];
  const { col, row } = piece;
  switch (piece.name) {
    case "advisor":
      // TODO
      break;
    case "canon":
      // TODO
      break;
    case "elephant":
      if (!getAlivePieceAtCoord(setupBefore, col - 1, row + 1))
        result.push({ col: -2, row: 2 });
      if (!getAlivePieceAtCoord(setupBefore, col + 1, row + 1))
        result.push({ col: 2, row: 2 });
      if (!getAlivePieceAtCoord(setupBefore, col + 1, row - 1))
        result.push({ col: 2, row: -2 });
      if (!getAlivePieceAtCoord(setupBefore, col + 1, row + 1))
        result.push({ col: 2, row: 2 });
      break;
    case "general":
      // TODO
      break;
    case "horse":
      // TODO
      break;
    case "rook":
      // TODO
      break;
    case "soldier":
      // TODO
      break;
  }
  return result.filter(d => isValidDelta(piece, d));
};
