import { Canvg, presets } from "canvg";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { action, flow, toJS } from "mobx";
import { Observer } from "mobx-react-lite";
import React from "react";
import BaseButton from "../../components/_base/BaseButton/BaseButton";
import BaseInput from "../../components/_base/BaseInput/BaseInput";
import BaseSelector from "../../components/_base/BaseSelector/BaseSelector";
import BaseSpacer from "../../components/_base/BaseSpacer/BaseSpacer";
import { FM, useFM } from "../../constants/lang-components.aliases";
import {
  useStudioContext,
  useStudioDesign,
} from "../../contexts/studio/studio.context";
import { useObservableRef } from "../../hooks/useObservableRef.hook";
import { MSG } from "../../lang/MSG.enum";
import { Game } from "../../models/makeGame.model";
import { loadScript } from "../../utils/loadScript.util";
import { padZero } from "../../utils/math.utils";
import { useProps, useStore } from "../../utils/mobx.utils";
import { isNil, last } from "../../utils/ramdaEquivalents.utils";
import { capitalizeFirstLetter } from "../../utils/string.utils";
import tick from "../../utils/waiters.utils";
import "./GameExporterUI.scss";
import StudioSidebarUIPanel from "./StudioSidebarUIPanel";

type GameExporterUIProps = {
  game: Game;
};

const GameExporterUI: React.FC<GameExporterUIProps> = props => {
  const c = useStudioContext();
  const d = useStudioDesign();
  const p = useProps(props);
  const fm = useFM();
  const s = useStore(() => ({
    get $() {
      return c.game.$;
    },
    exportAnimatedImageWidth: 720,
    canvasDepo: [] as OffscreenCanvas[],
    canvas: null as Nullable<OffscreenCanvas>,
    exportStateText: fm({
      id: MSG.exporterStateReady,
      defaultMessage: "Ready to export.",
    }),
    get tooManyMovesForGif() {
      return p.game.moves.length > 50;
    },
    get defaultFileName() {
      return capitalizeFirstLetter(
        fm({ id: MSG.untitledGame, defaultMessage: "Untitled" })
      );
    },
    get fileName() {
      return p.game?.$.fileName || p.game?.$.title || s.defaultFileName;
    },
    exportImageFileFormat: "jpg",
  }));

  const fileNameInputRef = useObservableRef<HTMLInputElement>();
  const handleFileNameInputFocus = flow(function* () {
    if (!s.$.fileName) {
      s.$.fileName = s.$.title;
      yield tick();
      fileNameInputRef.current?.select();
    }
  });
  const handleFileNameInputBlur = action(() => {
    if (s.$.fileName === s.$.title) s.$.fileName = "";
  });

  const exportJSON = flow(function* (excludeMoves?: boolean) {
    c.isAutoPlaying = false;
    c.isExporting = true;
    s.exportStateText = fm({
      id: MSG.exporterStateExportingAsJSON,
      defaultMessage: "Exporting as JSON...",
    });
    const gameSnapshot = toJS(c.game.$);
    gameSnapshot.setup = [...c.game.setup];
    if (excludeMoves) gameSnapshot.moveList = [];
    yield saveAs(
      new Blob([JSON.stringify(gameSnapshot)], {
        type: "application/javascript;charset=utf-8",
      }),
      `${c.game.$.title}${excludeMoves ? "-setup-only" : ""}.json`
    );
    yield tick(100);
    c.isExporting = false;
    s.exportStateText = fm({
      id: MSG.exporterStateCompletion,
      defaultMessage: `Export task finished.`,
    });
  });

  const getMoveFrameAsCanvas = flow(function* (moveIndex?: number) {
    c.isAutoPlaying = false;
    c.debug = false;
    if (!isNil(moveIndex)) c.currentMoveIndex = moveIndex;
    yield tick();
    if (!s.canvas)
      s.canvas = new OffscreenCanvas(d.board.width, d.board.height);
    s.canvasDepo.push(s.canvas);
    const canvas = s.canvas;
    const ctx = canvas.getContext("2d");
    if (!ctx) return;
    const v = yield Canvg.from(
      ctx,
      document.querySelector("svg.Board")!.parentElement!.innerHTML,
      presets.offscreen() as any
    );
    yield v.render();
    return canvas;
  });

  const getMoveFrameAsBlob = flow(function* (moveIndex?: number) {
    c.isAutoPlaying = false;
    const canvas = (yield getMoveFrameAsCanvas(moveIndex)) as OffscreenCanvas;
    const blob: Blob = yield canvas.convertToBlob();
    return blob;
  });

  const exportMoveFrame = flow(function* (moveIndex?: number) {
    c.mode = "export";
    c.isAutoPlaying = false;
    c.isExporting = true;
    yield tick();
    const nth = (moveIndex ?? 0) + 1;
    s.exportStateText = fm(
      {
        id: MSG.exporterStateRenderingNthFrame,
        defaultMessage: `Rendering frame index ${nth}...`,
      },
      { nth }
    );
    const blob: Blob = yield getMoveFrameAsBlob();
    s.exportStateText = fm({
      id: MSG.exporterStateDownloading,
      defaultMessage: "Downloading...",
    });
    yield saveAs(
      blob,
      `${s.fileName}_${padZero(
        c.currentMove?.index,
        c.game.moves.length > 99 ? 3 : 2
      )}.${s.exportImageFileFormat}`
    );
    s.exportStateText = fm({
      id: MSG.exporterStateCompletion,
      defaultMessage: `Export task finished.`,
    });
    c.isExporting = false;
  });

  const exportCurrentMoveFrame = flow(function* () {
    yield exportMoveFrame(c.currentMoveIndex);
  });

  const exportAllFrames = flow(function* () {
    c.mode = "export";
    c.isAutoPlaying = false;
    yield tick();
    c.isExporting = true;
    c.currentMoveIndex = 0;
    const zip = new JSZip();
    do {
      const blob: Blob = yield getMoveFrameAsBlob();
      zip.file(
        `${s.fileName}_${padZero(
          c.currentMove?.index,
          c.game.moves.length > 99 ? 3 : 2
        )}.${s.exportImageFileFormat}`,
        blob
      );
      s.exportStateText = fm(
        {
          id: MSG.exporterStateRenderingNthFrameOfTotal,
          defaultMessage: `Rendering frame ${c.currentMoveIndex + 1} / ${
            c.game.moves.length
          }...`,
        },
        { nth: c.currentMoveIndex + 1, total: c.game.moves.length }
      );
      c.currentMoveIndex++;
    } while (c.currentMoveIndex < c.game.moves.length);
    s.exportStateText = fm({
      id: MSG.exporterStatePreparingZIP,
      defaultMessage: `Preparing ZIP for download...`,
    });
    const file = yield zip.generateAsync({ type: "blob" });
    saveAs(file, `${s.fileName}.zip`);
    c.isExporting = false;
    s.exportStateText = fm({
      id: MSG.exporterStateCompletion,
      defaultMessage: `Export task finished.`,
    });
  });

  const exportGIF = flow(function* () {
    c.mode = "export";
    c.isAutoPlaying = false;
    yield tick();
    c.isExporting = true;
    yield loadScript("/vendors/gifshot.min.js");
    const imageURLs = [] as string[];
    c.currentMoveIndex = 0;
    do {
      s.exportStateText = fm(
        {
          id: MSG.exporterStateRenderingNthFrameOfTotal,
          defaultMessage: `Rendering frame ${c.currentMoveIndex + 1} / ${
            c.game.moves.length
          }...`,
        },
        { nth: c.currentMoveIndex + 1, total: c.game.moves.length }
      );
      const blob: Blob = yield getMoveFrameAsBlob(c.currentMoveIndex);
      const pngUrl = URL.createObjectURL(blob);
      imageURLs.push(pngUrl);
      c.currentMoveIndex++;
    } while (c.currentMoveIndex < c.game.moves.length);
    c.currentMoveIndex = c.game.moves.length - 1;
    imageURLs.push(last(imageURLs)!);
    s.exportStateText = fm({
      id: MSG.exporterStatePreparingGIF,
      defaultMessage: `Preparing GIF for download...`,
    });
    gifshot.createGIF(
      {
        gifWidth: s.exportAnimatedImageWidth,
        gifHeight: s.exportAnimatedImageWidth / d.board.aspectRatio,
        images: imageURLs,
        numFrames: imageURLs.length,
        frameDuration: 10,
      },
      flow(function* (obj: AnyObject) {
        if (!obj.error) {
          s.exportStateText = fm({
            id: MSG.exporterStateDownloadingGIF,
            defaultMessage: `Downloadig GIF...`,
          });
          yield saveAs(obj.image, `${s.fileName}.gif`);
          s.exportStateText = fm({
            id: MSG.exporterStateCompletion,
            defaultMessage: `Export task finished.`,
          });
          c.isExporting = false;
        }
      })
    );
  });

  return (
    <Observer
      children={() => (
        <StudioSidebarUIPanel className="GameExporterUI">
          {/* <h3><FM id={MSG.exporterSectionTitle} defaultMessage="Export" /></h3> */}
          <div className="GameImageExporterStatusDisplay">
            {s.exportStateText}
          </div>

          <BaseInput
            form={s.$}
            field="fileName"
            Label={
              <FM
                id={MSG.gameRecordFileName}
                defaultMessage="Game Record File Name"
              />
            }
            placeholder={s.$.title || s.defaultFileName}
            onFocus={handleFileNameInputFocus}
            onBlur={handleFileNameInputBlur}
            innerRef={fileNameInputRef}
          />

          <hr />

          <BaseButton onClick={() => exportJSON()} disabled={c.isExporting}>
            <FM
              id={MSG.exportAsJonButtonLabel}
              defaultMessage="Export as JSON"
            />
          </BaseButton>
          <BaseButton onClick={() => exportJSON(true)} disabled={c.isExporting}>
            <FM
              id={MSG.exportOnlyOpeningSetupAsJonButtonLabel}
              defaultMessage="Export as JSON (Opening Setup Only)"
            />
          </BaseButton>

          <hr />

          {/* <table>
        <thead>
          <tr><th colSpan={2}><FM id={MSG.exporterOptionsTableTitle} defaultMessage="Export Options" /></th></tr>
        </thead>
        <tbody>
          <tr>
            <th></th>
            <td></td>
          </tr>
        </tbody>
      </table> */}

          <div className="GameExportButtonList">
            <BaseSelector
              form={s}
              field="exportImageFileFormat"
              options={[
                { label: "PNG", value: "png" },
                { label: "JPG", value: "jpg" },
              ]}
              label={fm({
                id: MSG.exportImageFileFormatLabel,
                defaultMessage: "Export Image File Format",
              })}
            />
            <BaseSpacer size="1em" />
            <BaseButton
              onClick={exportCurrentMoveFrame}
              disabled={c.isExporting}
            >
              <FM
                id={MSG.exportCurrentFrameButtonLabel}
                defaultMessage="Export Current Frame as PNG"
              />
            </BaseButton>
            <BaseButton onClick={exportAllFrames} disabled={c.isExporting}>
              <FM
                id={MSG.exportAllFramesButtonLabel}
                defaultMessage="Export All Frames as PNG"
              />
            </BaseButton>
          </div>

          <hr />

          <BaseInput
            form={s}
            field="exportAnimatedImageWidth"
            type="number"
            Label={
              <FM
                id={MSG.labelExportAnimatedImageWidth}
                defaultMessage="Export Animated Image Width"
              />
            }
            min="200"
          />

          <BaseButton onClick={exportGIF} disabled={c.isExporting}>
            <FM id={MSG.exportGifButtonLabel} defaultMessage="Render GIF" />
          </BaseButton>

          {s.tooManyMovesForGif && (
            <p>
              <em>
                <FM
                  id={MSG.tooManyFramesForGifWarning}
                  defaultMessage="Too many frames for GIF"
                />
              </em>
            </p>
          )}
        </StudioSidebarUIPanel>
      )}
    />
  );
};

export default GameExporterUI;
