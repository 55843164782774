import { runInAction } from "mobx";
import { Observer } from "mobx-react-lite";
import React, { PropsWithChildren, useEffect, useRef } from "react";
import { ContextColor } from "../../../constants/color.enum";
import { useContextColorStyle } from "../../../hooks/useContextColor.hook";
import joinClassName from "../../../utils/className.utils";
import { checkIfShouldInvertStyle } from "../../../utils/colors.utils";
import { useProps, useStore } from "../../../utils/mobx.utils";
import "./ShadedBlock.scss";

interface ShadedBlockProps {
  className?: string;
  color?: string;
  spaceChildren?: boolean;
  borderWidth?: string | number;
  rounded?: boolean;
  colorIntensity?: "normal" | "strong";
  dataCy?: string;
  onClick?: () => void;
}

const ShadedBlock: React.FC<PropsWithChildren<ShadedBlockProps>> = props => {
  const ref = useRef<HTMLDivElement>(null);

  const p = useProps(props);

  const colorStyle = useContextColorStyle(ContextColor.Primary, p.color);

  const s = useStore(() => ({
    shouldInvertStyle: false,
    get interactable() {
      return p.onClick;
    },
  }));

  useEffect(() => {
    runInAction(() => {
      s.shouldInvertStyle = checkIfShouldInvertStyle(ref);
    });
  });

  const style = {
    ...colorStyle,
  };
  return (
    <Observer
      children={() => (
        <div
          className={joinClassName(
            "ShadedBlock",
            p.className,
            p.spaceChildren && "spaceChildren",
            s.shouldInvertStyle && "inverted",
            p.rounded && "rounded",
            s.interactable && "interactable",
            p.colorIntensity === "strong" && "intenseColor"
          )}
          style={style}
          ref={ref}
          data-cy={p.dataCy}
          onClick={p.onClick}
        >
          <div
            className="ShadedBlockInner"
            style={{ borderWidth: p.borderWidth }}
          >
            {props.children}
          </div>
        </div>
      )}
    />
  );
};

export default ShadedBlock;
