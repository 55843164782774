import { Observer } from "mobx-react-lite";
import React from "react";
import BaseSelector from "../../components/_base/BaseSelector/BaseSelector";
import { FM } from "../../constants/lang-components.aliases";
import { useStudioContext } from "../../contexts/studio/studio.context";
import { MSG } from "../../lang/MSG.enum";
import "./StudioModeSwitcher.scss";

type StudioModeSwitcherProps = {};

const StudioModeSwitcher: React.FC<StudioModeSwitcherProps> = props => {
  const c = useStudioContext();

  return (
    <Observer
      children={() => (
        <div className="StudioModeSwitcher">
          {/* <h3><FM id={MSG.viewModeSelectorTitle} defaultMessage="View Mode Selector Title" /></h3> */}
          <BaseSelector
            form={c}
            field="mode"
            options={[
              {
                label: (
                  <FM
                    id={MSG.viewModeSelectorDesignModeLabel}
                    defaultMessage="Design"
                  />
                ),
                value: "design",
              },
              {
                label: (
                  <FM
                    id={MSG.viewModeSelectorEditModeLabel}
                    defaultMessage="Edit"
                  />
                ),
                value: "edit",
              },
              {
                label: (
                  <FM
                    id={MSG.viewModeSelectorExportModeLabel}
                    defaultMessage="Export"
                  />
                ),
                value: "export",
              },
            ]}
          />
        </div>
      )}
    />
  );
};

export default StudioModeSwitcher;
