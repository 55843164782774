import { observable } from "mobx";
import { PieceId, PieceIds, VariantType } from "../../@types/app.types";
import { BoardGridTheme } from "../../components/Board/Board";
import { BoardLogoHeight } from "../../components/Board/BoardFooter";
import {
  BoardGridCellSize,
  BoardGridOuterHeight,
  BoardGridOuterWidth,
  BoardGridPieceGap,
  BoardGridPieceSize,
} from "../../components/Board/BoardGridSymbolDefault";
import {
  PieceBaseSubtype,
  PieceBaseType,
  PieceCharSetType,
} from "../../components/PieceGraphic/PieceGraphic";
import { FontFamilyOption } from "../../constants/fonts";
import { PlayerArmy } from "../../models/makePlayer.model";
import { ColorPalette } from "../../utils/colors.utils";

export enum BoardHeaderFooterElement {
  None = "",
  LogoPZ = "LogoPZ",
  LogoZX = "LogoZX",
  PlayerArmy = "PlayerArmy",
  PlayerName = "PlayerName",
  CustomText = "CustomText",
  GameRecordTitle = "GameRecordTitle",
  GameCollectionName = "GameCollectionName",
  GameTournamentName = "GameTournamentName",
  PageNumber = "PageNumber",
  PageNumberWithTotal = "PageNumberWithTotal",
  NthRoundCounter = "NthRoundCounter",
}

export const makePieceSetDesignConfig = (army: PlayerArmy) => {
  const s = observable({
    base: "circle" as PieceBaseType,
    charSet: "Genwan" as PieceCharSetType,
    baseSubType: "alpha" as PieceBaseSubtype,
    outlineColor: ColorPalette[army === "red" ? "red" : "black"],
    outlineTextureId: "",
    outlineWidth: 3,
    fillColor: ColorPalette.white,
    fillTextureId: "",
    textColor: ColorPalette[army === "red" ? "red" : "black"],
    textTextureId: "",
  });
  return s;
};

export type HeaderFooterSlotConfig = {
  element: BoardHeaderFooterElement;
  customText: "";
  height: number;
};

export const makeDefaultDesignConfig = () => {
  const makeHeaderFooterSlotConfig = (
    location: "header" | "footer",
    slot: "start" | "end" | "middle",
    defaultElement: BoardHeaderFooterElement = BoardHeaderFooterElement.None
  ) => {
    const c: HeaderFooterSlotConfig = observable({
      element: defaultElement,
      customText: "",
      get height() {
        return getHeightOfHeaderFooterElement(d[location][slot]);
      },
    });
    return c;
  };

  const getHeightOfHeaderFooterElement = (config: HeaderFooterSlotConfig) => {
    switch (config.element) {
      case BoardHeaderFooterElement.LogoPZ:
        return BoardLogoHeight;
      case BoardHeaderFooterElement.GameRecordTitle:
      case BoardHeaderFooterElement.GameCollectionName:
      case BoardHeaderFooterElement.GameTournamentName:
      case BoardHeaderFooterElement.PlayerArmy:
      case BoardHeaderFooterElement.PageNumber:
      case BoardHeaderFooterElement.PageNumberWithTotal:
      case BoardHeaderFooterElement.NthRoundCounter:
        return d.board.fontSize;
      case BoardHeaderFooterElement.CustomText:
        if (config.customText) return d.board.fontSize;
        return 0;
      default:
        return 0;
    }
  };

  const d = observable({
    board: {
      backgroundImage: "",
      backgroundColor: ColorPalette.ivoryLight,
      borderWidth: 1,
      borderRadius: 0,
      textColor: ColorPalette.inkBlue,
      textOpacity: 1,
      borderColor: ColorPalette.ivoryLight,
      fontSize: 48,
      fontFamily: "genwan" as FontFamilyOption,
      fontWeight: 500,
      get width() {
        return (
          d.grid.outerWidth +
          d.board.inset.computed.left +
          d.board.inset.computed.right
        );
      },
      get height() {
        return d.grid.outerHeight + d.header.outerHeight + d.footer.outerHeight;
      },
      get aspectRatio() {
        return d.board.width / d.board.height;
      },
      inset: {
        userDefined: {
          top: 50,
          left: 50,
          right: 50,
          bottom: 150,
        },
        computed: {
          get top() {
            return d.header.outerHeight;
          },
          get left() {
            return d.board.inset.userDefined.left + d.board.borderWidth * 2;
          },
          get right() {
            return d.board.inset.userDefined.right + d.board.borderWidth * 2;
          },
          get bottom() {
            return d.footer.outerHeight;
          },
        },
        visual: {
          get top() {
            return d.header.outerHeight + d.grid.inset.top;
          },
          get left() {
            return d.board.inset.computed.left + d.grid.inset.left;
          },
          get right() {
            return d.board.inset.computed.right + d.grid.inset.right;
          },
          get bottom() {
            return d.footer.outerHeight + d.grid.inset.bottom;
          },
        },
      },
    },
    header: {
      distanceToBoardContent: 30,
      start: makeHeaderFooterSlotConfig("header", "start"),
      middle: makeHeaderFooterSlotConfig("header", "middle"),
      end: makeHeaderFooterSlotConfig("header", "end"),
      get outerWidth() {
        return d.board.width - d.board.borderWidth * 2;
      },
      get outerHeight() {
        return (
          d.board.inset.userDefined.top +
          d.header.distanceToBoardContent +
          d.columnNumberLabels.black.height +
          d.board.borderWidth
        );
      },
      get innerHeight(): number {
        return Math.max(
          0,
          d.header.middle.height,
          d.header.start.height,
          d.header.end.height
        );
      },
      get yFromTop() {
        return d.board.borderWidth;
      },
      get visualCenterYFromTop() {
        return (
          d.board.borderWidth +
          (d.header.outerHeight - d.columnNumberLabels.black.height) * 0.5
        );
      },
    },
    footer: {
      distanceToBoardContent: 30,
      start: makeHeaderFooterSlotConfig("footer", "start"),
      middle: makeHeaderFooterSlotConfig(
        "footer",
        "middle",
        BoardHeaderFooterElement.LogoPZ
      ),
      end: makeHeaderFooterSlotConfig("footer", "end"),
      get outerWidth() {
        return d.board.width - d.board.borderWidth * 2;
      },
      get outerHeight() {
        return (
          d.board.inset.userDefined.bottom +
          d.footer.distanceToBoardContent +
          d.columnNumberLabels.red.height +
          d.board.borderWidth
        );
      },
      get innerHeight(): number {
        return Math.max(
          0,
          d.footer.middle.height,
          d.footer.start.height,
          d.footer.end.height
        );
      },
      get yFromTop() {
        return d.board.height - d.footer.outerHeight - d.board.borderWidth;
      },
      get visualCenterYFromTop() {
        return (
          d.footer.yFromTop +
          d.columnNumberLabels.red.height +
          (d.footer.outerHeight - d.columnNumberLabels.red.height) * 0.425
        );
      },
    },
    grid: {
      theme: "default" as BoardGridTheme,
      color: ColorPalette.inkBlue,
      opacity: 0.62,
      get cellSize() {
        return BoardGridCellSize;
      },
      get outerWidth() {
        return BoardGridOuterWidth;
      },
      get outerHeight() {
        return BoardGridOuterHeight;
      },
      get inset() {
        return {
          get top() {
            return BoardGridCellSize / 2;
          },
          get right() {
            return BoardGridCellSize / 2;
          },
          get bottom() {
            return BoardGridCellSize / 2;
          },
          get left() {
            return BoardGridCellSize / 2;
          },
        };
      },
      get pieceGapX() {
        return BoardGridPieceGap;
      },
      get pieceGapY() {
        return BoardGridPieceGap;
      },
    },
    columnNumberLabels: {
      enabled: true,
      red: {
        fontSize: 36,
        fontWeight: 600,
        get height() {
          return d.columnNumberLabels.enabled
            ? d.columnNumberLabels.red.fontSize * 1.62
            : 0;
        },
      },
      black: {
        fontSize: 36,
        fontWeight: 500,
        get height() {
          return d.columnNumberLabels.enabled
            ? d.columnNumberLabels.black.fontSize * 1.62
            : 0;
        },
      },
    },
    pieceSet: {
      get size() {
        return BoardGridPieceSize;
      },
      get base() {
        return d.red.base === d.black.base ? d.red.base : "mixed";
      },
      set base(v) {
        if (v === "mixed") return;
        d.red.base = d.black.base = v;
      },
      get charSet() {
        return d.red.charSet === d.black.charSet ? d.red.charSet : "mixed";
      },
      set charSet(v) {
        if (v === "mixed") return;
        d.red.charSet = d.black.charSet = v;
      },
      charSetVariantDefs: {} as Record<PieceId, VariantType>,
    },
    red: makePieceSetDesignConfig("red"),
    black: makePieceSetDesignConfig("black"),
    debugMarker: {
      size: 68,
    },
  });
  [...PieceIds].forEach(
    i => (d.pieceSet.charSetVariantDefs[i as PieceId] = "A")
  );
  return d;
};

export type GameBoardDesignConfigInstance = ReturnType<
  typeof makeDefaultDesignConfig
>;
