import { makeIconDef } from "./makeIconDef";

export const RefreshIconDef = {
  refresh: makeIconDef("refresh", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 4.5L13 2V4.06274C14.2279 4.21744 15.408 4.65562 16.4446 5.34824C17.7602 6.22729 18.7855 7.47672 19.391 8.93853C19.9965 10.4003 20.155 12.0089 19.8463 13.5607C19.5376 15.1126 18.7757 16.538 17.6569 17.6569C16.538 18.7757 15.1126 19.5376 13.5607 19.8463C12.0089 20.155 10.4003 19.9965 8.93853 19.391C7.47672 18.7855 6.22729 17.7602 5.34824 16.4446C4.46919 15.129 4 13.5823 4 12H5C5 13.3845 5.41054 14.7378 6.17971 15.889C6.94888 17.0401 8.04213 17.9373 9.32122 18.4672C10.6003 18.997 12.0078 19.1356 13.3656 18.8655C14.7235 18.5954 15.9708 17.9287 16.9497 16.9497C17.9287 15.9708 18.5954 14.7235 18.8655 13.3656C19.1356 12.0078 18.997 10.6003 18.4672 9.32122C17.9373 8.04213 17.0401 6.94888 15.889 6.17971C15.0172 5.5972 14.0294 5.22038 13 5.07179V7L8.5 4.5Z"
        fill="currentColor"
      />
    ),
  }),
};
