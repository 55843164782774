import { reaction } from "mobx";

export const tick = (msMin?: number, msMax?: number) => {
  const duration =
    msMin && msMax ? Math.random() * (msMax - msMin) + msMin : msMin;
  return new Promise(resolve => setTimeout(resolve, duration));
};
export default tick;

export const runAfter = async (fn: Function, wait?: number) => {
  let alive = true;
  await tick(wait);
  if (alive) fn();
  return () => {
    alive = false;
  };
};

export const doEvery = (
  fn: Function,
  interval: number,
  options?: {
    fireImmediately?: boolean;
    until?: () => boolean;
    while?: () => boolean;
  }
) => {
  const ref = setInterval(() => {
    if (options?.while && !options.while()) {
      cleanup();
      return;
    }
    fn();
  }, interval);
  if (options?.fireImmediately) fn();
  let disposer: AnyFunction;
  if (options?.until) {
    disposer = reaction(options.until, value => value && cleanup());
  }
  const cleanup = () => {
    clearInterval(ref);
    disposer?.();
  };
  return cleanup;
};
