export const BreakpointDefs = {
  phone: 320,
  "phone-md": 360,
  "phone-lg": 412,
  "phone-xl": 480,
  tablet: 640,
  "tablet-md": 768,
  "tablet-lg": 920,
  "tablet-xl": 1024,
  desktop: 1152,
  "desktop-md": 1280,
  "desktop-ml": 1366,
  "desktop-lg": 1440,
  "desktop-xl": 1680,
};

export const BreakpointNames = Object.keys(BreakpointDefs);

export type BreakpointName = keyof typeof BreakpointDefs;

export function isBreakpointName(string: any): string is BreakpointName {
  return BreakpointNames.includes(string);
}

export function breakpoint(key: BreakpointName): number {
  return BreakpointDefs[key];
}
