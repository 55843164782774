import { makeIconDef } from "./makeIconDef";

export const HideKeyboardIconDef = {
  "hide-keyboard": makeIconDef("hide-keyboard", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 5H1V15H23V5ZM1 4C0.447715 4 0 4.44772 0 5V15C0 15.5523 0.447716 16 1 16H23C23.5523 16 24 15.5523 24 15V5C24 4.44772 23.5523 4 23 4H1ZM20 6H22V8H20V6ZM22 9H20V11H22V9ZM2 12H4V14H2V12ZM5 12H19V14H5V12ZM17 9H19V11H17V9ZM16 9H14V11H16V9ZM11 9H13V11H11V9ZM10 9H8V11H10V9ZM5 9H7V11H5V9ZM4 9H2V11H4V9ZM19 6H17V8H19V6ZM14 6H16V8H14V6ZM13 6H11V8H13V6ZM8 6H10V8H8V6ZM7 6H5V8H7V6ZM2 6H4V8H2V6ZM22 12H20V14H22V12ZM7.64645 18.3536L11.6464 22.3536L12 22.7071L12.3536 22.3536L16.3536 18.3536L15.6464 17.6464L12 21.2929L8.35355 17.6464L7.64645 18.3536Z"
        fill="currentColor"
      />
    ),
  }),
};
