import { action } from "mobx";
import { Observer } from "mobx-react-lite";
import React from "react";
import { ColorName } from "../../constants/color.enum";
import {
  useStudioContext,
  useStudioDesign,
} from "../../contexts/studio/studio.context";
import { Game } from "../../models/makeGame.model";
import joinClassName from "../../utils/className.utils";
import { ColorPalette } from "../../utils/colors.utils";
import { useProps, useStore } from "../../utils/mobx.utils";
import { uniq } from "../../utils/ramdaEquivalents.utils";
import PZLogo from "../Logos/PZLogo";
import PieceBaseCircleSymbols from "../PieceBase/PieceBaseCircleSymbols";
import PieceBaseHandDrawnSymbols from "../PieceBase/PieceBaseHandDrawnSymbols";
import PieceCharSet1941Symbols from "../PieceChar/PieceCharSet1941Symbols";
import PieceCharSetGenwanSymbols from "../PieceChar/PieceCharSetGenwanSymbols";
import PieceCharSetTaipeiSansSymbols from "../PieceChar/PieceCharSetTaipeiSansSymbols";
import PieceCharSetTikHianOriginalSymbols from "../PieceChar/PieceCharSetTikHianOriginalSymbols";
import PieceCharSetTsingWoodboneSymbols from "../PieceChar/PieceCharSetTsingWoodboneSymbols";
import "./Board.scss";
import {
  BoardBackgroundTextureDef,
  BoardBackgroundTextureDefSet,
} from "./BoardBackgroundTextureDefSet";
import BoardColNumbers from "./BoardColNumbers";
import BoardDebugPieceSet from "./BoardDebugPieceSet";
import BoardFooter from "./BoardFooter";
import BoardGridSymbolDefault from "./BoardGridSymbolDefault";
import BoardGridSymbolHandDrawn from "./BoardGridSymbolHandDrawn";
import BoardHeader from "./BoardHeader";
import BoardPieceHotspotSet from "./BoardPieceHotspotSet";
import BoardPieceSet from "./BoardPieceSet";

export type BoardGridTheme = "default" | "handDrawn";

export type BoardGridSymbolPropsBase = {
  color?: string;
  opacity?: number;
  strokeWidth?: number;
  debug?: boolean;
};

export type BoardProps = {
  className?: string;
  game?: Game;
  debug?: boolean;
};

export type BoardRowNumber = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
export type BoardColNumber = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

export const makeBoardGridSymbolId = (id: string) => `${id}__BoardGridSymbol`;

const Board = React.memo((props: React.PropsWithChildren<BoardProps>) => {
  const c = useStudioContext();
  const d = useStudioDesign();

  const p = useProps(props);
  const s = useStore(() => ({
    get bgImageDef(): Maybe<BoardBackgroundTextureDef> {
      return BoardBackgroundTextureDefSet[d.board.backgroundImage ?? ""];
    },
    get backgroundColor() {
      return d.board.backgroundColor;
    },
    get boardGraphicSymbol() {
      switch (d.grid.theme) {
        case "handDrawn":
          return <BoardGridSymbolHandDrawn debug={p.debug} />;
        case "default":
        default:
          return <BoardGridSymbolDefault debug={p.debug} />;
      }
    },
    get pieceBaseSymbols() {
      switch (d.pieceSet.base) {
        case "handDrawn":
          return <PieceBaseHandDrawnSymbols />;
        case "circle":
        default:
          return <PieceBaseCircleSymbols />;
      }
    },
    get pieceCharSetSymbols() {
      const sets = uniq([d.red.charSet, d.black.charSet]);
      return sets.map(set => {
        switch (set) {
          case "1941":
            return <PieceCharSet1941Symbols key="PieceCharSet1941Symbols" />;
          case "TaipeiSans":
            return (
              <PieceCharSetTaipeiSansSymbols key="PieceCharSetTaipeiSansSymbols" />
            );
          case "TsingWoodbone":
            return (
              <PieceCharSetTsingWoodboneSymbols key="PieceCharSetTsingWoodboneSymbols" />
            );
          case "TikHianOriginal":
            return (
              <PieceCharSetTikHianOriginalSymbols key="PieceCharSetTikHianOriginalSymbols" />
            );
          case "Genwan":
          default:
            return (
              <PieceCharSetGenwanSymbols key="PieceCharSetGenwanSymbols" />
            );
        }
      });
    },
    get opacity() {
      return d.grid.opacity ?? 0.62;
    },
  }));

  const handleBoardBackgroundHotspotClick = action(() => {
    switch (c.mode) {
      case "edit":
      case "design":
        c.selectedPiece = null;
        break;
      default:
        return;
    }
  });

  return (
    <Observer
      children={() => {
        const { className, debug } = p;

        return (
          <svg
            className={joinClassName("Board", className)}
            width={d.board.width}
            height={d.board.height}
            viewBox={`0 0 ${d.board.width} ${d.board.height}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <defs>
              <PZLogo asSymbol contextId={c.id} color={ColorName.inkBlue} />
              {s.bgImageDef?.src && (
                <pattern
                  id="BoardBackgroundTextureImage"
                  patternUnits="userSpaceOnUse"
                  width={d.board.width}
                  height={d.board.height}
                  viewBox={`0 0 ${d.board.width} ${d.board.height}`}
                >
                  <image
                    href={s.bgImageDef.src}
                    x="0"
                    y="0"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="xMinYMin slice"
                  />
                </pattern>
              )}
              <symbol
                id={`${c.id}__PieceBaseSymbolDebug`}
                width={d.pieceSet.size}
                height={d.pieceSet.size}
                viewBox={`0 0 ${d.pieceSet.size} ${d.pieceSet.size}`}
              >
                <rect
                  width={d.pieceSet.size}
                  height={d.pieceSet.size}
                  stroke={ColorPalette.red}
                  fill="transparent"
                />
                <line
                  x1={d.pieceSet.size / 2}
                  y1={d.pieceSet.size / 2 - d.debugMarker.size / 2}
                  x2={d.pieceSet.size / 2}
                  y2={d.pieceSet.size / 2 + d.debugMarker.size / 2}
                  stroke={ColorPalette.red}
                  strokeWidth={3}
                />
                <line
                  x1={d.pieceSet.size / 2 - d.debugMarker.size / 2}
                  y1={d.pieceSet.size / 2}
                  x2={d.pieceSet.size / 2 + d.debugMarker.size / 2}
                  y2={d.pieceSet.size / 2}
                  stroke={ColorPalette.red}
                  strokeWidth={3}
                />
              </symbol>
              {s.boardGraphicSymbol}
              {s.pieceCharSetSymbols}
              {s.pieceBaseSymbols}
            </defs>

            <rect
              className="BoardBackgroundColorRect"
              width={d.board.width}
              height={d.board.height}
              fill={s.backgroundColor}
              rx={d.board.borderRadius}
              stroke={d.board.borderColor}
            />

            {s.bgImageDef && (
              <rect
                className="BoardBackgroundTextureImage"
                width={d.board.width}
                height={d.board.height}
                fill="url(#BoardBackgroundTextureImage)"
              />
            )}

            {c.debug && (
              <rect
                className="BoardBackgroundColorRect"
                width={d.board.width}
                height={d.board.height}
                fill="transparent"
                stroke={ColorPalette.red}
              />
            )}

            {d.board.borderWidth && (
              <rect
                className="BoardBorderRect"
                x={d.board.borderWidth / 2}
                y={d.board.borderWidth / 2}
                width={d.board.width - d.board.borderWidth}
                height={d.board.height - d.board.borderWidth}
                stroke={d.board.borderColor}
                strokeWidth={d.board.borderWidth}
                rx={d.board.borderRadius}
              />
            )}

            <rect
              className="BoardBackgroundHotsoptRect"
              width={d.board.width}
              height={d.board.height}
              fill="transparent"
              onClick={handleBoardBackgroundHotspotClick}
            />

            <BoardHeader />

            {c.debug && (
              <rect
                className="BoardGridUseDebugAreaMarker"
                x={d.board.inset.computed.left}
                y={d.board.inset.computed.top}
                width={d.grid.outerWidth}
                height={d.grid.outerHeight}
                stroke={ColorPalette.teal}
                strokeWidth="2"
                strokeDasharray="10 10"
              />
            )}

            <use
              className="BoardGridUse"
              xlinkHref={`#${makeBoardGridSymbolId(c.id)}`}
              x={d.board.inset.computed.left}
              y={d.board.inset.computed.top}
              width={d.grid.outerWidth}
              height={d.grid.outerHeight}
              opacity={s.opacity}
            />

            {d.columnNumberLabels.enabled && <BoardColNumbers />}

            <g className="BoardPieceSetLayer">
              {debug && <BoardDebugPieceSet />}
              <BoardPieceSet />
              <BoardPieceHotspotSet />
            </g>

            <BoardFooter />
          </svg>
        );
      }}
    />
  );
});

export default Board;
