export function shuffleInPlace<T extends any>(array: T[]): T[] {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export function shuffle<T extends any>(array: T[]): T[] {
  return shuffleInPlace([...array]);
}

export function getRandomInteger(floor: number = 0, ceil: number = 10) {
  return Math.round(Math.random() * (ceil - floor) + floor);
}

export function getRandomItemFromArray<T>(array: T[]): T {
  return array[Math.floor(Math.random() * array.length)];
}

export function getRandomNumericString(length = 12) {
  return (Math.random() * new Date().getTime() + "")
    .replace(".", "")
    .substr(0, length);
}
