import { Observer } from "mobx-react-lite";
import React, { SyntheticEvent, useRef } from "react";
import { useFM } from "../../../constants/lang-components.aliases";
import { MSG } from "../../../lang/MSG.enum";
import { useProps, useStore } from "../../../utils/mobx.utils";
import BaseIcon from "../BaseIcon/BaseIcon";
import {
  BaseSelectorInnerProps,
  DefaultOptionType,
  DefaultSingleValueType,
} from "./BaseSelector";
// import './BaseSelectorSystemInner.scss';

export function BaseSelectorSystemInner<
  FormType extends AnyObject = AnyObject,
  OptionType extends any = DefaultOptionType,
  SingleValueType extends DefaultSingleValueType = DefaultSingleValueType,
  ValueFieldType extends SingleValueType | string[] = SingleValueType
>(
  props: React.PropsWithChildren<
    BaseSelectorInnerProps<
      FormType,
      OptionType,
      SingleValueType,
      ValueFieldType
    >
  >
) {
  const p = useProps(props);

  const fm = useFM();

  const ref = useRef<HTMLSelectElement | null>(null);

  const s = useStore(() => ({
    get value() {
      if (p.valueFieldRef instanceof Array) {
        return p.valueFieldRef.map(s => s + "");
      } else {
        return p.valueFieldRef as unknown as string | number | undefined;
      }
    },
  }));

  const renderOptions = () => {
    return p.options.map((o, i) => {
      const value: SingleValueType = p.valueGetter!(o);
      const label = p.optionLabelRenderer!(o);
      const disabled = p.disabledOptionChecker!(o);
      return (
        <option key={value + "" + i} value={value} disabled={disabled}>
          {label}
        </option>
      );
    });
  };

  const handleOnChange = (e: SyntheticEvent<HTMLSelectElement>) => {
    const { value } = e.currentTarget;
    p.onChange && p.onChange(value);
    ref.current?.blur();
  };

  const handleOnBlur = (e: SyntheticEvent<HTMLSelectElement>) => {
    const { value } = e.currentTarget;
    p.onBlur && p.onBlur(value);
  };

  const handleClick = (e: React.MouseEvent) => {
    p.onClick?.(e);
  };

  return (
    <Observer
      children={() => (
        <div className="BaseSelectorInner" onClick={handleClick}>
          <select
            className="BaseSelectorSelect"
            value={s.value}
            name={p.name}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            disabled={p.disabled}
            required={p.required}
            multiple={p.multiple}
            ref={ref}
          >
            {!p.doNotShowEmptyOptionInSystemDropdown && (
              <option value={p.emptyValue}>
                {p.placeholder ||
                  fm({ id: MSG.selectorPlaceholderText, defaultMessage: " " })}
              </option>
            )}
            {renderOptions()}
          </select>
          <BaseIcon name="dropdown-arrow" size=".8rem" variant="filled" />
        </div>
      )}
    />
  );
}

export default BaseSelectorSystemInner;
