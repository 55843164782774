import { makeIconDef } from "./makeIconDef";

export const CogIconDef = {
  cog: makeIconDef("cog", {
    regular: () => (
      <path
        d="M13.9595 5.49312L13.9905 5.80242L14.2815 5.91154C14.9581 6.16519 15.5814 6.52857 16.1298 6.98027L16.3698 7.17803L16.6533 7.05009L18.6744 6.13799C19.0521 6.569 19.3936 7.04334 19.6914 7.5592C19.9892 8.07507 20.2293 8.60798 20.4137 9.15056L18.6142 10.4442L18.3621 10.6254L18.4127 10.9317C18.4701 11.2789 18.5 11.6358 18.5 12C18.5 12.3642 18.4701 12.721 18.4127 13.0682L18.3621 13.3745L18.6142 13.5557L20.4137 14.8493C20.2293 15.3919 19.9892 15.9248 19.6914 16.4406C19.3936 16.9565 19.0521 17.4308 18.6744 17.8619L16.6535 16.9498L16.37 16.8219L16.1299 17.0196C15.5815 17.4714 14.9581 17.8348 14.2815 18.0885L13.9905 18.1976L13.9595 18.5069L13.7391 20.7113C13.177 20.8229 12.5955 20.8814 11.9998 20.8814C11.4041 20.8814 10.8226 20.8229 10.2605 20.7113L10.04 18.5067L10.0091 18.1974L9.71807 18.0883C9.04163 17.8346 8.41839 17.4713 7.8701 17.0196L7.63003 16.8218L7.34652 16.9498L5.32517 17.862C4.9475 17.431 4.60601 16.9567 4.30818 16.4408C4.01034 15.9249 3.7703 15.392 3.58587 14.8494L5.38579 13.5556L5.63786 13.3744L5.58726 13.0681C5.5299 12.7209 5.50001 12.3641 5.50001 12C5.50001 11.6359 5.52989 11.2792 5.58724 10.9321L5.63783 10.6258L5.38577 10.4446L3.58587 9.15071C3.7703 8.60813 4.01034 8.07522 4.30817 7.55936C4.60601 7.04349 4.9475 6.56916 5.32517 6.13814L7.3464 7.05031L7.62991 7.17826L7.86999 6.98048C8.41831 6.52877 9.04158 6.16537 9.71807 5.9117L10.0091 5.80256L10.04 5.49328L10.2605 3.2887C10.8226 3.17713 11.4041 3.11856 11.9998 3.11856C12.5955 3.11856 13.177 3.17713 13.7391 3.2887L13.9595 5.49312ZM12 14.5C13.3807 14.5 14.5 13.3807 14.5 12C14.5 10.6193 13.3807 9.5 12 9.5C10.6193 9.5 9.50001 10.6193 9.50001 12C9.50001 13.3807 10.6193 14.5 12 14.5Z"
        stroke="currentColor"
      />
    ),
  }),
};
