import Color from "color";
import { ColorName, ContextColor } from "../constants/color.enum";
import { toLowerCase } from "./string.utils";

export const ColorPalette: Record<ColorName, string> = {
  [ColorName.white]: "#FFFFFF",
  [ColorName.gray]: "#A5B4B7",
  [ColorName.slate]: "#4D5863",
  [ColorName.black]: "#000000",

  [ColorName.inkBlue]: "#202F45",
  [ColorName.red]: "#E35E40",
  [ColorName.ivory]: "#F2E9DF",
  [ColorName.ivoryLight]: "#F5EFE8",
  [ColorName.gold]: "#CFBD7C",
  [ColorName.teal]: "#229C86",
  [ColorName.lightBlue]: "#BAE0DE",
  [ColorName.beige]: "#BCA193",
  [ColorName.brown]: "#463d3a",
  [ColorName.lavender]: "#A58BB1",
};

export const COLOR_HEX_CODE_REGEX = /^#([A-F0-9]{6}|[A-F0-9]{3})$/i;
export const COLOR_HEX_CODE_WITH_ALPHA_REGEX = /^#([A-F0-9]{8}|[A-F0-9]{4})$/i;

export function isColorHexCode(input: Sometimes<string>): input is string {
  if (!input) return false;
  return COLOR_HEX_CODE_REGEX.test(input + "");
}
export const CSSColorKeywords = ["inherit", "transparent", "currentColor"];
export const isCSSColorKeyword = (s?: Sometimes<string>) => {
  if (!s) return false;
  return CSSColorKeywords.includes(s);
};

export const withOpacity = (color: Sometimes<string>, opacity: number) => {
  if (!color) return undefined;
  if (isCSSColorKeyword(color)) return color;
  try {
    return Color(color).alpha(opacity).rgb().string();
  } catch (e) {
    console.warn(
      `The color ${color} supplied to withOpacity() is not in a valid color format.`
    );
    return color;
  }
};

export function isHexCode(input?: string | null) {
  if (!input) return false;
  return (
    /^#[0-9A-F]{6}$/i.test(input) ||
    /^#[0-9A-F]{3}$/i.test(input) ||
    /^#[0-9A-F]{8}$/i.test(input)
  );
}

export const isBrightColor = (colorHex?: string | null) =>
  colorHex ? Color(colorHex || "#000").luminosity() >= 0.48 : false;

export const checkIfShouldInvertStyle = (
  ref: React.RefObject<HTMLElement | null>
): boolean => {
  let result = false;
  const el = ref?.current;
  if (!el) result = false;
  else {
    try {
      const { color } = getComputedStyle(el);
      result = Color(color || "").isLight();
    } catch (e) {
      result = false;
    }
  }
  return result;
};

export function getContextColorStyle(
  contextColorName?: ContextColor,
  color?: string | null,
  namespace: string = "app"
): React.CSSProperties {
  if (!contextColorName || !color) {
    // console.warn('useContextColorStyle must be supplied with both a context color name and color name.');
    return {};
  }
  const colorName = toLowerCase(contextColorName);
  if (isHexCode(color)) {
    const colorObj = Color(color);
    const h = colorObj.hue();
    const s = colorObj.saturationl() + "%";
    const l = colorObj.lightness() + "%";
    const contrastColor = Color(getContrastColorHex(color));
    const ch = contrastColor.hue();
    const cs = contrastColor.saturationl() + "%";
    const cl = contrastColor.lightness() + "%";
    return {
      [`--${namespace}-c-${colorName}-h`]: h,
      [`--${namespace}-c-${colorName}-s`]: s,
      [`--${namespace}-c-${colorName}-l`]: l,
      [`--${namespace}-c-${colorName}-hsl`]: [h, s, l].join(","),
      [`--${namespace}-c-${colorName}_h`]: ch,
      [`--${namespace}-c-${colorName}_s`]: cs,
      [`--${namespace}-c-${colorName}_l`]: cl,
      [`--${namespace}-c-${colorName}_hsl`]: [ch, cs, cl].join(","),
    };
  } else {
    return {
      [`--${namespace}-c-${colorName}-h`]: `var(--${namespace}-c-${color}-h)`,
      [`--${namespace}-c-${colorName}-s`]: `var(--${namespace}-c-${color}-s)`,
      [`--${namespace}-c-${colorName}-l`]: `var(--${namespace}-c-${color}-l)`,
      [`--${namespace}-c-${colorName}-hsl`]: `var(--${namespace}-c-${color}-hsl)`,
      [`--${namespace}-c-${colorName}_h`]: `var(--${namespace}-c-${color}_h)`,
      [`--${namespace}-c-${colorName}_s`]: `var(--${namespace}-c-${color}_s)`,
      [`--${namespace}-c-${colorName}_l`]: `var(--${namespace}-c-${color}_l)`,
      [`--${namespace}-c-${colorName}_hsl`]: `var(--${namespace}-c-${color}_hsl)`,
    };
  }
}

export function getContrastColorHex(colorHex?: string | null) {
  return isBrightColor(colorHex) ? ColorPalette.inkBlue : ColorPalette.white;
}
