import { makeIconDef } from "./makeIconDef";

export const ExternalLinkIconDef = {
  "external-link": makeIconDef("external-link", {
    regular: () => (
      <>
        <path d="M10.1213 5H4V20H19V14.375" stroke="currentColor" />
        <path d="M13 3H21V11" stroke="currentColor" />
        <path d="M9 15L21 3" stroke="currentColor" />
      </>
    ),
    filled: () => (
      <>
        <path
          d="M10.1213 5H4V20H19V14.375"
          stroke="currentColor"
          strokeWidth="2"
        />
        <path d="M13 3H21V11" stroke="currentColor" strokeWidth="2" />
        <path d="M9 15L21 3" stroke="currentColor" strokeWidth="2" />
      </>
    ),
  }),
};
