import { Observer } from "mobx-react-lite";
import React from "react";
import BaseInput from "../../../components/_base/BaseInput/BaseInput";
import { FM } from "../../../constants/lang-components.aliases";
import { useStudioDesign } from "../../../contexts/studio/studio.context";
import { MSG } from "../../../lang/MSG.enum";
import { PlayerArmy } from "../../../models/makePlayer.model";
import { useProps, useStore } from "../../../utils/mobx.utils";
import "./PlayerPieceSetDesignCustomizer.scss";

type PlayerPieceSetDesignCustomizerProps = {
  player: PlayerArmy;
};

const PlayerPieceSetDesignCustomizer: React.FC<PlayerPieceSetDesignCustomizerProps> =
  React.memo(props => {
    const p = useProps(props);
    const d = useStudioDesign();
    const s = useStore(() => ({
      get config() {
        return d[p.player];
      },
    }));

    return (
      <Observer
        children={() => (
          <table className="PlayerPieceSetDesignCustomizer">
            <tbody>
              <tr>
                <th>
                  <FM id={MSG.labelOutlineColor} defaultMessage="Outline" />
                </th>
                <td>
                  <BaseInput
                    form={s.config}
                    field="outlineColor"
                    type="color"
                  />
                </td>
              </tr>
              {/* <tr>
          <th>Outline Texture</th>
          <td><BaseInput form={s.config} field="outlineTextureId" /></td>
        </tr> */}
              <tr>
                <th>
                  <FM id={MSG.labelFillColor} defaultMessage="Fill" />
                </th>
                <td>
                  <BaseInput form={s.config} field="fillColor" type="color" />
                </td>
              </tr>
              {/* <tr>
          <th>Fill Texture</th>
          <td><BaseInput form={s.config} field="fillTextureId" /></td>
        </tr> */}
              <tr>
                <th>
                  <FM id={MSG.labelTextColor} defaultMessage="Text" />
                </th>
                <td>
                  <BaseInput form={s.config} field="textColor" type="color" />
                </td>
              </tr>
              {/* <tr>
          <th>Text Texture</th>
          <td><BaseInput form={s.config} field="textTextureId" /></td>
        </tr> */}
            </tbody>
          </table>
        )}
      />
    );
  });

export default PlayerPieceSetDesignCustomizer;
