import { Observer } from "mobx-react-lite";
import React from "react";
import { getFont } from "../../constants/fonts";
import {
  useStudioContext,
  useStudioDesign,
} from "../../contexts/studio/studio.context";
import { useStore } from "../../utils/mobx.utils";
import {
  ValidColIndex,
  getColumnDisplayName,
} from "../../utils/rowOrColumn.utils";

type BoardColNumbersProps = {};

const BoardColNumbers: React.FC<BoardColNumbersProps> = props => {
  const d = useStudioDesign();
  const c = useStudioContext();

  const s = useStore(() => ({
    getHeaderNumCoords(i: number) {
      return {
        x: d.board.inset.visual.left + (d.pieceSet.size + d.grid.pieceGapX) * i,
        y:
          d.board.inset.visual.top -
          d.pieceSet.size / 3 -
          d.columnNumberLabels.black.fontSize,
      };
    },
    getFooterNumCoords(i: number) {
      return {
        x: d.board.inset.visual.left + (d.pieceSet.size + d.grid.pieceGapX) * i,
        y:
          d.board.inset.computed.top + d.grid.outerHeight + d.pieceSet.size / 3,
      };
    },
  }));

  return (
    <Observer
      children={() => (
        <g className="BoardColNumbers">
          <g className="BoardColNumbersBlack">
            {"012345678".split("").map((n, i) => {
              const coords =
                c.game.black.identifier === "playerA"
                  ? s.getHeaderNumCoords(i)
                  : s.getFooterNumCoords(i);
              return (
                <text
                  key={i}
                  textAnchor="middle"
                  x={coords.x}
                  y={coords.y}
                  fontSize={d.columnNumberLabels.black.fontSize * 1}
                  fill={d.board.textColor}
                  fontFamily={getFont(d.board.fontFamily)}
                  fontWeight={d.columnNumberLabels.black.fontWeight}
                >
                  {getColumnDisplayName(
                    +n as ValidColIndex,
                    c.game.black.identifier,
                    "black"
                  )}
                </text>
              );
            })}
          </g>

          <g className="BoardColNumbersRed">
            {"012345678".split("").map((n, i) => {
              const coords =
                c.game.red.identifier === "playerA"
                  ? s.getHeaderNumCoords(i)
                  : s.getFooterNumCoords(i);
              return (
                <text
                  key={i}
                  textAnchor="middle"
                  x={coords.x}
                  y={coords.y}
                  fontSize={d.columnNumberLabels.red.fontSize}
                  fill={d.board.textColor}
                  fontFamily={getFont(d.board.fontFamily)}
                  fontWeight={d.columnNumberLabels.red.fontWeight}
                >
                  {getColumnDisplayName(
                    +n as ValidColIndex,
                    c.game.red.identifier,
                    "red"
                  )}
                </text>
              );
            })}
          </g>
        </g>
      )}
    />
  );
};

export default BoardColNumbers;
