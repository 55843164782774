import { makeIconDef } from "./makeIconDef";

export const ArrowIconDef = {
  arrow: makeIconDef("arrow", {
    regular: () => (
      <g stroke="currentColor" strokeWidth="1" strokeLinecap="round">
        <path d="M3 11.5H20.5" />
        <path d="M12 2.5L21 11.5L12 20.5" />
      </g>
    ),
    filled: () => (
      <g stroke="currentColor" strokeWidth="2" strokeLinecap="round">
        <path d="M3 11.5H20.5" />
        <path d="M12 2.5L21 11.5L12 20.5" />
      </g>
    ),
  }),
};
