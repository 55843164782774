import { Observer } from "mobx-react-lite";
import React from "react";
import { BoardTheme } from "../../../@types/app.types";
import BaseSelector from "../../../components/_base/BaseSelector/BaseSelector";
import { FM, useFM } from "../../../constants/lang-components.aliases";
import { useStudioContext } from "../../../contexts/studio/studio.context";
import { MSG } from "../../../lang/MSG.enum";
import { boardThemesArray } from "../../../themes/boardThemeIndex";
import StudioSidebarUIPanel from "../StudioSidebarUIPanel";
// import './BoardThemeSelector.scss';

type BoardThemeSelectorProps = {
  state: {
    theme: string;
    selectedTheme: Sometimes<BoardTheme>;
  };
};

const BoardThemeSelector: React.FC<BoardThemeSelectorProps> = props => {
  const c = useStudioContext();

  const handleThemeChange = () => {
    if (props.state.selectedTheme) c.applyTheme(props.state.selectedTheme);
  };

  const fm = useFM();

  return (
    <Observer
      children={() => (
        <StudioSidebarUIPanel
          className="BoardThemeSelector"
          heading={
            <FM
              id={MSG.customizerThemePresetSectionTitle}
              defaultMessage="Theme Presets"
            />
          }
        >
          <BaseSelector
            form={props.state}
            field="theme"
            options={boardThemesArray.map(t => ({
              value: t.identifier,
              label: fm({ id: t.name }),
            }))}
            onChange={handleThemeChange}
            appearance="system"
          />
        </StudioSidebarUIPanel>
      )}
    />
  );
};

export default BoardThemeSelector;
