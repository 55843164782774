import { observable } from "mobx";
import { isLocalhost } from "../utils/env.utils";
import {
  makeNavigatorController,
  NavigatorController,
} from "./navigator.controller";
import {
  makeSettingsController,
  SettingsController,
} from "./settings.controller";
import { makeUIController, UIController } from "./ui.controller";

export const makeRootController = (
  options?: RootConstructorOptions
): RootController => {
  const c = observable({
    $children: {
      SETTINGS: makeSettingsController(),
      UI: makeUIController(),
      NAVIGATOR: makeNavigatorController(),
    },
    $init: async () => {
      c.$children.SETTINGS.$init(c);
      c.$children.UI.$init(c);
      c.$children.NAVIGATOR.$init(c);
      return true;
    },
    $reset: async () => {
      return true;
    },
    get $isRoot() {
      return true as true;
    },
  }) as RootController;
  if (isLocalhost) {
    Reflect.set(window, "ROOT", c);
    Reflect.set(window, "SETTINGS", c.$children.SETTINGS);
    Reflect.set(window, "UI", c.$children.UI);
    Reflect.set(window, "NAVIGATOR", c.$children.NAVIGATOR);
  }
  return c;
};

export type RootConstructorOptions = {};
export type RootControllerChildren = {
  SETTINGS: SettingsController;
  UI: UIController;
  NAVIGATOR: NavigatorController;
};

// RootController has to have explicit typing because child controllers will need to refer to root
export type RootController = {
  $init: (options?: {}) => Promise<boolean>;
  $reset: () => Promise<boolean>;
  $children: RootControllerChildren;
  $isRoot: true;
};
