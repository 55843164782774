import { isNil } from "./ramdaEquivalents.utils";

export type StringTransformerInput = string | number | null;

export function capitalizeFirstLetter(input?: StringTransformerInput) {
  const string = "" + input || "";
  return string.replace(/^./, string[0].toUpperCase());
}

export const toLowerCase = (s: string) => (isNil(s) ? "" : s.toLowerCase());

export const padSpace = (s: string, numberOfSpacesPerLetter: number = 1) => {
  let separator = "";
  let n = 0;
  while (n < numberOfSpacesPerLetter) {
    separator += " ";
    n++;
  }
  return s.split("").join(separator);
};

export const slugify = (text: string = "") =>
  text
    .toString() // Cast to string
    .toLowerCase() // Convert the string to lowercase letters
    .normalize("NFD") // The normalize() method returns the Unicode Normalization Form of a given string.
    .trim() // Remove whitespace from both sides of a string
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w-]+/g, "") // Remove all non-word chars
    .replace(/--+/g, "-") // Replace multiple - with single -
    .replace(/(-)+$/, "") // remove trailing dashes
    .replace(/^(-)+/, ""); // remove dashes at the beginning of string
