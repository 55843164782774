import { BoardTheme } from "../@types/app.types";
import { BoardHeaderFooterElement } from "../contexts/studio/studioDesign.contextPartial";
import { MSG } from "../lang/MSG.enum";
import { ColorPalette } from "../utils/colors.utils";

export const BoardThemeTextbookBw: BoardTheme = {
  identifier: "textbook-bw",
  name: MSG.themeNameTextbook1941BW,
  value: {
    board: {
      backgroundColor: ColorPalette.white,
      borderWidth: 0,
      borderRadius: 0,
      borderColor: ColorPalette.white,
      textColor: ColorPalette.black,
      fontFamily: "genwan",
      fontWeight: 700,
      inset: {
        userDefined: {
          top: 38,
          left: 10,
          right: 10,
          bottom: 38,
        },
      },
    },
    grid: {
      color: ColorPalette.black,
    },
    pieceSet: {
      charSet: "1941",
      charSetVariantDefs: {
        ba1: "A",
        ba2: "B",
        be1: "A",
        be2: "B",
        bh1: "A",
        bh2: "B",
        br1: "A",
        br2: "B",
        bc1: "A",
        bc2: "B",
        bs1: "A",
        bs2: "B",
        bs3: "C",
        bs4: "D",
        bs5: "E",

        ra1: "A",
        ra2: "B",
        re1: "A",
        re2: "B",
        rh1: "A",
        rh2: "B",
        rr1: "A",
        rr2: "B",
        rc1: "A",
        rc2: "B",
        rs1: "A",
        rs2: "B",
        rs3: "C",
        rs4: "D",
        rs5: "E",
      },
    },
    black: {
      fillColor: ColorPalette.black,
      outlineColor: ColorPalette.black,
      textColor: ColorPalette.white,
    },
    red: {
      fillColor: ColorPalette.white,
      outlineColor: ColorPalette.black,
      textColor: ColorPalette.black,
    },
    header: {
      distanceToBoardContent: 20,
      // middle: { element: BoardHeaderFooterElement.None },
      middle: { element: BoardHeaderFooterElement.PlayerArmy },
    },
    footer: {
      distanceToBoardContent: 20,
      // middle: { element: BoardHeaderFooterElement.None },
      middle: { element: BoardHeaderFooterElement.PlayerArmy },
    },
  },
};
