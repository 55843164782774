import { observer } from "mobx-react-lite";
import * as React from "react";
import { runAndIgnoreError } from "../../../utils/errors.utils";
import { isString } from "../../../utils/typeChecks.utils";

interface ErrorRendererProps {
  error?: string | Error | null | unknown;
}

const defaultMessage = "An unknown error occurred.";

const ErrorRenderer: React.FC<ErrorRendererProps> = observer(props => {
  let errorMessage = defaultMessage;

  runAndIgnoreError(() => {
    errorMessage = isString(props.error)
      ? props.error
      : (props.error as Error)?.message;
  });

  return <div className="ErrorRenderer">{errorMessage}</div>;
});

export default ErrorRenderer;
