import { Observer } from "mobx-react-lite";
import React from "react";
import {
  useStudioContext,
  useStudioDesign,
} from "../../contexts/studio/studio.context";
import { useProps, useStore } from "../../utils/mobx.utils";
import { BoardGridSymbolPropsBase, makeBoardGridSymbolId } from "./Board";

export type BoardGridSymbolDefaultProps = BoardGridSymbolPropsBase;

export const BoardGridCellSize = 135;
export const BoardGridPieceSize = 128;
export const BoardGridPieceGap = BoardGridCellSize - BoardGridPieceSize;
export const BoardGridInnerWidth = BoardGridCellSize * 8;
export const BoardGridInnerHeight = BoardGridCellSize * 9;
export const BoardGridOuterWidth = BoardGridInnerWidth + BoardGridCellSize;
export const BoardGridOuterHeight = BoardGridInnerHeight + BoardGridCellSize;
export const BoardGridInnerOffsetX =
  (BoardGridOuterWidth - BoardGridInnerWidth) / 2;
export const BoardGridInnerOffsetY =
  (BoardGridOuterHeight - BoardGridInnerHeight) / 2;

export const BoardGridSymbolDefaultFlower = (
  props: React.PropsWithChildren<{
    col: number;
    row: number;
    stroke: string;
    strokeWidth: number;
    strokeOpacity: number;
  }>
) => {
  const p = useProps(props);
  const d = useStudioDesign();
  const s = useStore(() => ({
    get x() {
      return d.grid.cellSize * (p.col + 0.5);
    },
    get y() {
      return d.grid.cellSize * (p.row + 0.5);
    },
    get flowerSize() {
      return 32;
    },
    get flowerGapToGridLine() {
      return 12;
    },
  }));
  return (
    <Observer
      children={() => (
        <g>
          {/* <SvgDebugPxPointMarker x={s.x} y={s.y} /> */}
          {/* ┘ */}
          {p.col > 0 && (
            <path
              d={`M${s.x - s.flowerGapToGridLine - s.flowerSize} ${
                s.y - s.flowerGapToGridLine
              } h ${s.flowerSize} v ${-s.flowerSize}`}
              stroke={p.stroke}
              strokeOpacity={p.strokeOpacity}
              strokeWidth={p.strokeWidth}
            />
          )}
          {/* └ */}
          {p.col < 8 && (
            <path
              d={`M${s.x + s.flowerGapToGridLine + s.flowerSize} ${
                s.y - s.flowerGapToGridLine
              } h ${-s.flowerSize} v ${-s.flowerSize}`}
              stroke={p.stroke}
              strokeOpacity={p.strokeOpacity}
              strokeWidth={p.strokeWidth}
            />
          )}
          {/* ┐ */}
          {p.col > 0 && (
            <path
              d={`M${s.x - s.flowerGapToGridLine - s.flowerSize} ${
                s.y + s.flowerGapToGridLine
              } h ${s.flowerSize} v ${+s.flowerSize}`}
              stroke={p.stroke}
              strokeOpacity={p.strokeOpacity}
              strokeWidth={p.strokeWidth}
            />
          )}
          {/* ┌ */}
          {p.col < 8 && (
            <path
              d={`M${s.x + s.flowerGapToGridLine + s.flowerSize} ${
                s.y + s.flowerGapToGridLine
              } h ${-s.flowerSize} v ${+s.flowerSize}`}
              stroke={p.stroke}
              strokeOpacity={p.strokeOpacity}
              strokeWidth={p.strokeWidth}
            />
          )}
        </g>
      )}
    />
  );
};

const BoardGridSymbolDefault: React.FC<BoardGridSymbolDefaultProps> = props => {
  const c = useStudioContext();
  const d = useStudioDesign();

  return (
    <Observer
      children={() => {
        const { strokeWidth = 3 } = props;
        const lineStyle = {
          stroke: d.grid.color,
          strokeWidth: strokeWidth,
          strokeOpacity: d.grid.opacity,
        };
        return (
          <symbol
            className="BoardSymbolDefault"
            id={makeBoardGridSymbolId(c.id)}
            viewBox={`0 0 ${BoardGridOuterWidth} ${BoardGridOuterHeight}`}
          >
            {/* { props.debug && <rect width={BoardGridOuterWidth} height={BoardGridOuterHeight} stroke={d.grid.color} strokeDasharray="5 5"/> } */}
            {/* <rect
        width={BoardGridOuterWidth}
        height={BoardGridOuterHeight}
        stroke={d.grid.color}
        strokeDasharray="5 5"
      /> */}
            <rect
              x={BoardGridInnerOffsetX}
              y={BoardGridInnerOffsetY}
              width={BoardGridInnerWidth}
              height={BoardGridInnerHeight}
              {...lineStyle}
            />
            <rect
              x={BoardGridInnerOffsetX - 12}
              y={BoardGridInnerOffsetY - 12}
              width={BoardGridInnerWidth + 24}
              height={BoardGridInnerHeight + 24}
              {...lineStyle}
            />
            {
              // top half vertical lines
              [1, 2, 3, 4, 5, 6, 7].map(i => {
                const x = BoardGridInnerOffsetX + i * BoardGridCellSize;
                const y1 = BoardGridInnerOffsetY;
                const y2 = BoardGridInnerOffsetY + 4 * BoardGridCellSize;
                return (
                  <line
                    data-no={i}
                    key={i}
                    x1={x}
                    x2={x}
                    y1={y1}
                    y2={y2}
                    {...lineStyle}
                  />
                );
              })
            }
            {
              // bottom half vertical lines
              [1, 2, 3, 4, 5, 6, 7].map(i => {
                const x = BoardGridInnerOffsetX + i * BoardGridCellSize;
                const y1 = BoardGridInnerOffsetY + 5 * BoardGridCellSize;
                const y2 = BoardGridInnerOffsetY + 9 * BoardGridCellSize;
                return (
                  <line
                    data-no={i}
                    key={i}
                    x1={x}
                    x2={x}
                    y1={y1}
                    y2={y2}
                    {...lineStyle}
                  />
                );
              })
            }
            {
              // horizontal lines
              [1, 2, 3, 4, 5, 6, 7, 8].map(i => {
                const x1 = BoardGridInnerOffsetX;
                const x2 = BoardGridInnerOffsetX + 8 * BoardGridCellSize;
                const y = BoardGridInnerOffsetY + i * BoardGridCellSize;
                return (
                  <line
                    data-no={i}
                    key={i}
                    x1={x1}
                    x2={x2}
                    y1={y}
                    y2={y}
                    {...lineStyle}
                  />
                );
              })
            }
            {/* // the top cross */}
            <line
              x1={BoardGridInnerOffsetX + 3 * BoardGridCellSize}
              y1={BoardGridInnerOffsetY}
              x2={BoardGridInnerOffsetX + 5 * BoardGridCellSize}
              y2={BoardGridInnerOffsetY + 2 * BoardGridCellSize}
              {...lineStyle}
            />
            <line
              x1={BoardGridInnerOffsetX + 5 * BoardGridCellSize}
              y1={BoardGridInnerOffsetY}
              x2={BoardGridInnerOffsetX + 3 * BoardGridCellSize}
              y2={BoardGridInnerOffsetY + 2 * BoardGridCellSize}
              {...lineStyle}
            />
            <line
              x1={BoardGridInnerOffsetX + 3 * BoardGridCellSize}
              y1={BoardGridInnerOffsetY + 7 * BoardGridCellSize}
              x2={BoardGridInnerOffsetX + 5 * BoardGridCellSize}
              y2={BoardGridInnerOffsetY + 9 * BoardGridCellSize}
              {...lineStyle}
            />
            <line
              x1={BoardGridInnerOffsetX + 5 * BoardGridCellSize}
              y1={BoardGridInnerOffsetY + 7 * BoardGridCellSize}
              x2={BoardGridInnerOffsetX + 3 * BoardGridCellSize}
              y2={BoardGridInnerOffsetY + 9 * BoardGridCellSize}
              {...lineStyle}
            />
            {[
              { col: 1, row: 2 },
              { col: 7, row: 2 },
              { col: 0, row: 3 },
              { col: 2, row: 3 },
              { col: 4, row: 3 },
              { col: 6, row: 3 },
              { col: 8, row: 3 },
              { col: 0, row: 6 },
              { col: 2, row: 6 },
              { col: 4, row: 6 },
              { col: 6, row: 6 },
              { col: 8, row: 6 },
              { col: 1, row: 7 },
              { col: 7, row: 7 },
            ].map((point, i) => (
              <BoardGridSymbolDefaultFlower
                key={i}
                col={point.col}
                row={point.row}
                {...lineStyle}
              />
            ))}
          </symbol>
        );
      }}
    />
  );
};

export default BoardGridSymbolDefault;
