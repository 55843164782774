import BoardThemeGenwanWarm from "./genwan-warm.theme";
import BoardThemeTaipeiSansWhite from "./taipei-sans-white.theme";
import { BoardThemeTextbookBw } from "./textbook-bw.theme";
import BoardThemeTikHianDark from "./tikhian-dark";
import BoardThemeTikHianOlive from "./tikhian-olive";
import BoardThemeTikHianOriginal from "./tikhian-original";
import BoardThemeTikHianWhite from "./tikhian-white";
import BoardThemeTsingWoodboneClean from "./tsing-woodbone.theme";

export const boardThemesArray = [
  BoardThemeTikHianOriginal,
  BoardThemeTikHianWhite,
  BoardThemeTikHianOlive,
  BoardThemeTikHianDark,
  BoardThemeGenwanWarm,
  BoardThemeTaipeiSansWhite,
  BoardThemeTextbookBw,
  BoardThemeTsingWoodboneClean,
];
