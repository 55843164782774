import { action, flow } from "mobx";
import { Observer } from "mobx-react-lite";
import React from "react";
import { FM, useFM } from "../../constants/lang-components.aliases";
import { useStudioContext } from "../../contexts/studio/studio.context";
import { convertSimpleRecordToMovelist as convertSimpleRecordToMoveList } from "../../converters/SimpleRecord.converter";
import { MSG } from "../../lang/MSG.enum";
import StudioSidebarUIPanel from "../../pages/Studio/StudioSidebarUIPanel";
import { copyString } from "../../utils/dom.utils";
import { useStore } from "../../utils/mobx.utils";
import tick from "../../utils/waiters.utils";
import BaseButton from "../_base/BaseButton/BaseButton";
import BaseInput from "../_base/BaseInput/BaseInput";
import BaseLabel from "../_base/BaseLabel/BaseLabel";
import GameMoveListRangeControl from "./GameMoveListRangeControl";
import GameMoveListRound from "./GameMoveListRound";
import "./GameMoveListUI.scss";

type GameMoveListUIProps = {};

const GameMoveListUI: React.FC<GameMoveListUIProps> = React.memo(props => {
  const c = useStudioContext();
  const fm = useFM();
  // const clear = () => {
  //   c.game.clearMoveList();
  // };
  const s = useStore(() => ({
    autoPlayStepDurationSeconds: 1.25,
  }));
  const pasteInMovesRecord = flow(function* () {
    const input: Nullable<string> = yield window.prompt(
      fm({
        id: MSG.pasteOrEnterMovesRecordLabel,
        defaultMessage: "Paste in or enter moves record:",
      })
    );
    if (!input) return;
    const moveList = convertSimpleRecordToMoveList(input);
    if (!moveList) {
      copyString(input);
      window.alert(fm({ id: MSG.error_MovesRecordImportFailure }));
      return;
    }
    c.game.pushMoves(moveList);
  });
  const copyRecord = () => {
    copyString(c.game.rounds.map(r => r.map(m => m.def).join(" ")).join("\n"));
    window.alert(
      fm({ id: MSG.copySuccessMessage, defaultMessage: "Copied to clipboard." })
    );
  };
  const moveTo = action((index: number) => {
    if (index <= 0) c.currentMoveIndex = 0;
    else if (index >= c.pageCountTotal - 1)
      c.currentMoveIndex = c.pageCountTotal - 1;
    else c.currentMoveIndex = index;
  });
  const prevMove = flow(function* (shouldWait?: boolean) {
    if (c.currentMoveIndex <= 0) {
      c.currentMoveIndex = 0;
      return;
    }
    c.currentMoveIndex--;
    if (shouldWait) yield tick(s.autoPlayStepDurationSeconds * 1000);
  });
  const nextMove = flow(function* (shouldWait?: boolean) {
    if (c.currentMoveIsLastMove) {
      c.currentMoveIndex = c.game.moves.length - 1;
      return;
    }
    c.currentMoveIndex++;
    if (shouldWait) yield tick(s.autoPlayStepDurationSeconds * 1000);
  });
  const startAutoPlay = flow(function* () {
    c.isAutoPlaying = true;
    if (c.currentMoveIsLastMove) c.currentMoveIndex = 0;
    yield tick(s.autoPlayStepDurationSeconds * 1000);
    while (c.isAutoPlaying && !c.currentMoveIsLastMove) {
      yield nextMove(true);
      if (c.currentMoveIndex >= c.game.moves.length - 1) {
        c.isAutoPlaying = false;
      }
    }
  });
  const stopAutoPlay = action(() => {
    c.isAutoPlaying = false;
  });

  return (
    <Observer
      children={() => (
        <StudioSidebarUIPanel
          className="GameMoveListUI"
          heading={
            <FM id={MSG.movesSectionTitle} defaultMessage="Moves List" />
          }
        >
          <div className="GameMoveListUIControlSet">
            <BaseButton onClick={pasteInMovesRecord}>
              <FM
                id={MSG.enterMovesRecordButtonLabel}
                defaultMessage="Enter Moves Record"
              />
            </BaseButton>
            {c.game.rounds.length > 0 && (
              <BaseButton onClick={copyRecord}>
                <FM
                  id={MSG.copyGameRecordAsText}
                  defaultMessage="Copy to Clipboard"
                />
              </BaseButton>
            )}
          </div>

          {c.game.rounds.length > 0 && (
            <div className="GameMoveListUIControlSet">
              <BaseButton onClick={() => moveTo(0)}>
                <FM id={MSG.gameOpening} defaultMessage="Opening" />
              </BaseButton>
              <BaseButton onClick={() => prevMove()}>
                <FM id={MSG.labelPrevMove} defaultMessage="Prev Move" />
              </BaseButton>
              <BaseButton onClick={() => nextMove()}>
                <FM id={MSG.labelNextMove} defaultMessage="Next Move" />
              </BaseButton>
              <BaseButton onClick={() => moveTo(c.pageCountTotal - 1)}>
                <FM id={MSG.endGame} defaultMessage="End" />
              </BaseButton>
            </div>
          )}

          {c.game.rounds.length > 0 && (
            <div className="GameMoveListUIControlSet">
              {!c.isAutoPlaying && (
                <BaseButton onClick={startAutoPlay}>
                  <FM id={MSG.labelStartAutoPlay} defaultMessage="Auto Play" />
                </BaseButton>
              )}
              {c.isAutoPlaying && (
                <BaseButton onClick={stopAutoPlay}>
                  <FM
                    id={MSG.labelStopAutoPlay}
                    defaultMessage="Stop Auto Play"
                  />
                </BaseButton>
              )}
              <div className="GameMoveListUIControlSet">
                <BaseLabel>
                  <FM
                    id={MSG.labelAutoPlayStepDurationInSeconds}
                    defaultMessage="Auto Play Speed"
                  />
                </BaseLabel>
                <BaseInput
                  form={s}
                  field="autoPlayStepDurationSeconds"
                  type="number"
                  min={0.75}
                  step={0.25}
                />
              </div>
            </div>
          )}

          <div className="GameMoveListUIControlSet">
            <GameMoveListRangeControl />
          </div>

          <ol className="GameMoveListUIMoveList">
            {c.game.moves.length > 0 && (
              <GameMoveListRound game={c.game} index={-1} />
            )}
            {c.game?.rounds.map((round, index) => (
              <GameMoveListRound
                game={c.game}
                round={round}
                index={index}
                key={index}
              />
            ))}
          </ol>
        </StudioSidebarUIPanel>
      )}
    />
  );
});

export default GameMoveListUI;
