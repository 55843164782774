import { makeIconDef } from "./makeIconDef";

export const DropdownArrowIconDef = {
  "dropdown-arrow": makeIconDef("dropdown-arrow", {
    regular: () => (
      <path
        d="M3 7.5L12 16.5L21 7.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    ),
    filled: () => (
      <path
        d="M3 7.5L12 16.5L21 7.5"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
      />
    ),
  }),
};
