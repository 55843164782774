import deepFreeze from "deep-freeze";
import { PieceId } from "../@types/app.types";
import { PlayerArmy, PlayerIdentifier } from "../models/makePlayer.model";
import { ValidColIndex, ValidRowIndex } from "../utils/rowOrColumn.utils";
import { LangCode } from "./lang.enum";

export type PieceDef = {
  id: PieceId;
  playerIdentifier: PlayerIdentifier;
  name: PieceName;
  row: ValidRowIndex;
  col: ValidColIndex;
  alive: boolean;
};
export type PieceDefList = PieceDef[];

export type PieceName =
  | "general"
  | "advisor"
  | "elephant"
  | "horse"
  | "rook"
  | "canon"
  | "soldier";

export const PieceAbbrMap = {
  general: ["G", "将", "將", "帅", "帥"],
  advisor: ["A", "仕", "士"],
  elephant: ["E", "相", "象"],
  horse: ["H", "马", "馬", "㐷", "傌"],
  rook: ["R", "车", "車", "俥", "伡"],
  canon: ["C", "炮", "砲", "包"],
  soldier: ["S", "卒", "兵"],
} as const;

export const ValidPieceNameAbbrs = deepFreeze(
  Object.values(PieceAbbrMap).flat()
);
export const isValidPieceNameAbbr = (s: Sometimes<string>) =>
  Boolean(s && ValidPieceNameAbbrs.includes(s as any));
export const ValidPieceNameAbbrSet = ValidPieceNameAbbrs.join("");
export const getPieceNameFromChar = (char: string): Nullable<PieceName> => {
  for (let [name, values] of Object.entries(PieceAbbrMap)) {
    if ((values as readonly string[]).includes(char as any))
      return name as PieceName;
  }
  return null;
};
export const PieceDisplayNameMap = {
  general: { en: ["General", "General"], sc: ["帅", "将"], tc: ["帥", "將"] },
  advisor: { en: ["Advisor", "Advisor"], sc: ["仕", "士"], tc: ["仕", "士"] },
  elephant: { en: ["Bishop", "Elephant"], sc: ["相", "象"], tc: ["相", "象"] },
  horse: { en: ["Horse", "Horse"], sc: ["马", "马"], tc: ["馬", "馬"] },
  rook: { en: ["Rook", "Rook"], sc: ["车", "车"], tc: ["車", "車"] },
  canon: { en: ["Canon", "Canon"], sc: ["炮", "砲"], tc: ["炮", "砲"] },
  soldier: { en: ["Soldier", "Soldier"], sc: ["兵", "卒"], tc: ["兵", "卒"] },
} as const;
export const getPieceDisplayName = (
  pieceName?: Nullable<PieceName>,
  army?: Nullable<PlayerArmy>,
  lang?: LangCode
) => {
  if (!pieceName || !army) return null;
  return PieceDisplayNameMap[pieceName][lang || "tc"][army === "red" ? 0 : 1];
};
