import { MSG } from "../../lang/MSG.enum";
import BoardBackgroundTextureMarble from "./BoardBackgroundTextureMarble.jpg";
import BoardBackgroundTexturePaperA from "./BoardBackgroundTexturePaperA.jpg";
import BoardBackgroundTexturePaperB from "./BoardBackgroundTexturePaperB.jpg";

export type BoardBackgroundTextureDef = {
  value: string;
  label: string;
  fallbackColorHex: Nullable<string>;
  src: Nullable<string>;
  width: number;
  height: number;
};
export const BoardBackgroundTextureDefSet: Record<
  string,
  BoardBackgroundTextureDef
> = {
  marble: {
    value: "marble",
    label: MSG.textureNameMarble,
    src: BoardBackgroundTextureMarble,
    fallbackColorHex: "#E1E2E3",
    width: 1356,
    height: 1583,
  },
  "paper-a": {
    value: "paper-a",
    label: MSG.textureNamePaperWarm,
    src: BoardBackgroundTexturePaperA,
    fallbackColorHex: "#F3E5D8",
    width: 1356,
    height: 1584,
  },
  "paper-b": {
    value: "paper-b",
    label: MSG.textureNamePaperWarmer,
    src: BoardBackgroundTexturePaperB,
    fallbackColorHex: "#F3E5D8",
    width: 1356,
    height: 1584,
  },
};
