import { action } from "mobx";
import { Observer } from "mobx-react-lite";
import React from "react";
import { useFM } from "../../constants/lang-components.aliases";
import { useStudioContext } from "../../contexts/studio/studio.context";
import { MSG } from "../../lang/MSG.enum";
import { Game } from "../../models/makeGame.model";
import { Move } from "../../models/makeMove.model";
import { useProps, useStore } from "../../utils/mobx.utils";
import "./GameMoveListItem.scss";

type GameMoveListItemProps = {
  game: Game;
  move: Move;
};

export const OpeningSetupString = "OpeningSetup";

const GameMoveListItem: React.FC<GameMoveListItemProps> = props => {
  const context = useStudioContext();
  const p = useProps(props);
  const fm = useFM();

  const s = useStore(() => ({
    get isOpeningSetupButton() {
      return p.move.def === OpeningSetupString;
    },
    get isCurrentMove() {
      return context.currentMoveIndex === p.move.index;
    },
    // get style(): CSSObject {
    //   return {
    //     appearance: "none",
    //     display: 'flex',
    //     border: 'none',
    //     backgroundColor: s.isCurrentMove ? ColorPalette.white : 'transparent',
    //     // color: 'inherit',
    //     color: p.move.player ? ColorPalette[p.move.player] : 'inherit',
    //     padding: '.62em 0',
    //     width: '100%',
    //     textAlign: 'left',
    //     fontWeight: s.isOpeningSetupButton ? 700 : 400,
    //     opacity: p.move.errorExistsInPrevMoves ? .5 : 1,
    //     cursor: p.move.errorExistsInPrevMoves ? 'not-allowed' : 'pointer',
    //     paddingLeft: '.5em',
    //     '&:hover': {
    //       backgroundColor: p.move.errorExistsInPrevMoves ? undefined : withOpacity(ColorPalette.white, s.isCurrentMove ? 1 : .38),
    //     },
    //     '&:focus': {
    //       outlineColor: ColorPalette.gold,
    //     }
    //   }
    // }
  }));

  const handleClick = action(() => {
    context.mode = "edit";
    context.setCurrentMoveIndex(p.move.index);
  });

  return (
    <Observer
      children={() => (
        <button
          className="GameMoveListItem"
          onClick={handleClick}
          disabled={p.move.errorExistsInPrevMoves}
          data-move-index={p.move.index}
          data-is-current={s.isCurrentMove}
          data-army={p.move.army}
          data-is-opening-setup={s.isOpeningSetupButton}
        >
          <div>
            <span>
              {s.isOpeningSetupButton
                ? fm({
                    id: MSG.labelOpeningSetup,
                    defaultMessage: "Opening Setup",
                  })
                : p.move.displayDef}
            </span>
            {!!p.move.error && <strong> ERROR </strong>}
            {/* {context.debug && <div>
          <span>{p.move.playerDisplayname} </span>
          <span>{p.move.relativeLocatorDisplayName} </span>
          <span>{p.move.pieceDisplayName} </span>
          <br />
          <span>COL: {p.move.columnIndex} </span>
          <span>{p.move.columnDisplayName} </span>
          <br />
          <span>{p.move.typeDisplayName} </span>
          <span>x: {p.move.delta.x} </span>
          <span>y: {p.move.delta.y} </span>
        </div>} */}
          </div>
        </button>
      )}
    />
  );
};

export default GameMoveListItem;
