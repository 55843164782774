import { Observer } from "mobx-react-lite";
import React from "react";
import { useStudioContext } from "../../contexts/studio/studio.context";
import { Game } from "../../models/makeGame.model";
import { Move } from "../../models/makeMove.model";
import joinClassName from "../../utils/className.utils";
import GameMoveListItem, { OpeningSetupString } from "./GameMoveListItem";
import "./GameMoveListRound.scss";

type GameMoveListRoundProps = {
  game: Game;
  index: number;
  round?: [Move] | [Move, Move];
};

const GameMoveListRound: React.FC<GameMoveListRoundProps> = props => {
  const c = useStudioContext();
  return (
    <Observer
      children={() => (
        <li
          className={joinClassName(
            "GameMoveListRound",
            c.currentRoundIndex === props.index + 1 && "--current"
          )}
          data-round-index={props.index}
        >
          <span className="GameMoveListRoundNumber">
            {props.index >= 0 ? props.index + 1 : null}
          </span>
          <div className="GameMoveListRoundMoves">
            {props.round?.map(move => (
              <GameMoveListItem
                game={props.game}
                key={move.index}
                move={move}
              />
            ))}
            {props.index === -1 && (
              <GameMoveListItem
                game={props.game}
                key={OpeningSetupString}
                move={props.game.moves[0]}
              />
            )}
          </div>
        </li>
      )}
    />
  );
};

export default GameMoveListRound;
