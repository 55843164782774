import { Observer } from 'mobx-react-lite';
import React from 'react';
import BaseInput from '../../../components/_base/BaseInput/BaseInput';
import { useStudioDesign } from '../../../contexts/studio/studio.context';
import './BoardInsetEditor.scss';

type BoardInsetEditorProps = {}

const BoardInsetEditor: React.FC<BoardInsetEditorProps> = React.memo(props => {
  const d = useStudioDesign();
  return <Observer children={() => (
    <div className="BoardInsetEditor">
      <BaseInput type="number" step="1" min="0" form={d.board.inset.userDefined} field="top" />
      <BaseInput type="number" step="1" min="0" form={d.board.inset.userDefined} field="right" />
      <BaseInput type="number" step="1" min="0" form={d.board.inset.userDefined} field="bottom" />
      <BaseInput type="number" step="1" min="0" form={d.board.inset.userDefined} field="left" />
    </div>
  )} />
})

export default BoardInsetEditor;