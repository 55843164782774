import { observable, runInAction } from "mobx";
import { OpeningSetupString } from "../components/GameMoveListUI/GameMoveListItem";
import {
  PieceDef,
  PieceDefList,
  getPieceDisplayName,
  getPieceNameFromChar,
  isValidPieceNameAbbr,
} from "../constants/pieceSet.constants";
import { reportError } from "../utils/errors.utils";
import {
  getMoveTypeDisplayName,
  getMoveTypeFromString,
  getRelativeLocatorDisplayName,
  getRelativeLocatorTypeFromChar,
} from "../utils/move.utils";
import { getLegalDeltaSetOfPiece } from "../utils/piece.utils";
import { getPlayerColorHanName } from "../utils/player.utils";
import {
  first,
  isNil,
  last,
  lastInString,
  uniq,
} from "../utils/ramdaEquivalents.utils";
import {
  ValidColIndex,
  getColumnDisplayName,
  getColumnNumberFromChar,
  getFullWidthNumberFromHalfWidthNumber,
  getHanCharFromHalfWidthNumber,
  getNumberFromChar,
} from "../utils/rowOrColumn.utils";
import { Game } from "./makeGame.model";
import { PlayerArmy } from "./makePlayer.model";

export type MoveType = "forward" | "backward" | "sideways";
export type RelativeLocatorType =
  | "first"
  | "second"
  | "third"
  | "fourth"
  | "fifth"
  | "last";
export type Delta = { col: number; row: number };

export const makeMove = (_game: Game, _index: number) => {
  const s = observable({
    error: null as Nullable<Error | unknown>,
    get errorExistsInPrevMoves(): boolean {
      return Boolean(s.error || s.prevMove?.errorExistsInPrevMoves);
    },
    get def() {
      return s.game.movesList[s.index];
    },
    set def(v) {
      console.log(`Updating move#${s.index} to ${v}`);
      s.game.$.moveList[s.index - 1] = v;
    },
    get isOpeningSetup() {
      return s.def === OpeningSetupString;
    },
    get index() {
      return _index;
    },
    get game() {
      return _game;
    },
    get prevMove(): Move | null {
      if (s.isOpeningSetup || s.index === 0 || s.game.moves.length < s.index)
        return null;
      return s.game.moves[s.index - 1];
    },
    get player() {
      if (s.isOpeningSetup) return null;
      return s.index % 2 === 0
        ? s.game.secondHandPlayer
        : s.game.firstHandPlayer;
    },
    get army(): Sometimes<PlayerArmy> {
      return s.player?.army;
    },
    get playerDisplayName() {
      return getPlayerColorHanName(s.army);
    },
    get piecesBeforeInColumn(): PieceDefList {
      const fn = (pc: PieceDef) =>
        pc.name === s.pieceName &&
        pc.playerIdentifier === s.player?.identifier &&
        pc.col === s.columnIndex &&
        pc.alive;
      const result =
        (s.isOpeningSetup ? s.game.setup : s.setupBefore)
          ?.filter(fn)
          .sort((a, b) => (s.army === "red" ? a.row - b.row : b.row - a.row)) ??
        [];
      return s.game.hasInvertedPlayerArmyAssignment ? result.reverse() : result;
    },
    get firstCharIsPieceName() {
      return isValidPieceNameAbbr(s.def[0]);
    },
    get relativeLocator() {
      if (s.isOpeningSetup || s.def.length < 1) return null;
      if (s.firstCharIsPieceName) return null;
      return getRelativeLocatorTypeFromChar(s.def[0]);
    },
    get relativeLocatorDisplayName() {
      return getRelativeLocatorDisplayName(s.relativeLocator);
    },
    get allAlivePiecesInSameClass() {
      return s.setupBefore.filter(
        pc =>
          pc.playerIdentifier === s.player?.identifier &&
          pc.name === s.pieceName &&
          pc.alive
      );
    },
    get columnsWithMoreThanOneAlivePiecesInSameClass() {
      return uniq(s.allAlivePiecesInSameClass.map(pc => pc.col)).filter(
        col =>
          s.allAlivePiecesInSameClass.filter(pc => pc.col === col).length > 1
      );
    },
    get columnIndex(): Nullable<ValidColIndex> {
      if (s.isOpeningSetup || s.def.length < 2) return null;
      if (s.firstCharIsPieceName)
        return getColumnNumberFromChar(
          s.def[1],
          s.player?.identifier,
          s.player?.army
        );
      // when a relative locator is defined, there could potentially be two columns that have more than one soldiers.
      if (s.columnsWithMoreThanOneAlivePiecesInSameClass.length === 1)
        return s.columnsWithMoreThanOneAlivePiecesInSameClass[0];
      // if there are more than two columns containing more than one pieces,
      // it should look like "前九平八" where the second char is the col.
      return getColumnNumberFromChar(
        s.def[1],
        s.player?.identifier,
        s.player?.army
      );
    },
    get columnDisplayName() {
      return getColumnDisplayName(s.columnIndex, s.player?.identifier, s.army);
    },
    get pieceName() {
      const char =
        s.def.length === 1
          ? s.def
          : s.firstCharIsPieceName
          ? s.def[0]
          : s.def.length >= 2 && isValidPieceNameAbbr(s.def[1])
          ? s.def[1]
          : null;
      // where more than two columns have more than one soldiers,
      // the record will look like "前九平八" where the piece name for soldier is omitted.
      return char ? getPieceNameFromChar(char) ?? "soldier" : null;
    },
    get pieceDisplayName() {
      return getPieceDisplayName(s.pieceName, s.army);
    },
    get type() {
      if (s.def.length < 3) return null;
      return getMoveTypeFromString(s.def[2]);
    },
    get typeDisplayName() {
      return getMoveTypeDisplayName(s.type);
    },
    get delta(): Delta {
      const defaultValue = { col: 0, row: 0 };
      try {
        if (
          s.isOpeningSetup ||
          s.def.length < 4 ||
          isNil(s.columnIndex) ||
          !s.pieceBefore ||
          s.errorExistsInPrevMoves
        )
          return defaultValue;
        const char = lastInString(s.def)!;
        const xDest = getColumnNumberFromChar(
          char,
          s.player?.identifier,
          s.player?.army
        );
        if (isNil(xDest))
          throw Error(`Unable to parse column number from ${s.def}`);
        switch (s.type) {
          case "sideways": {
            return { col: xDest - s.columnIndex, row: 0 };
          }
          case "forward":
          case "backward": {
            const charNum = getNumberFromChar(char);
            if (isNil(charNum))
              throw Error(`Unable to parse y delta from ${s.def}`);
            const ySign =
              s.player?.identifier === "playerA"
                ? s.type === "forward"
                  ? 1
                  : -1
                : s.type === "forward"
                ? -1
                : 1;
            switch (s.pieceName) {
              case "advisor": {
                // moves diagonally, 1 * 1. abs represents destination col number (x).
                return { col: xDest - s.columnIndex, row: ySign };
              }
              case "horse": {
                // moves diagonally, 2 * 1. abs represents destination col number (x).
                const x = xDest - s.columnIndex;
                if (Math.abs(x) !== 1 && Math.abs(x) !== 2) {
                  console.log(xDest, s.columnIndex);
                  console.log(s);
                  throw Error(
                    `Move#${s.index} ${s.def} is impossible for a Horse.`
                  );
                }
                const yAbs = Math.abs(x) === 1 ? 2 : 1;
                return { col: x, row: yAbs * ySign };
              }
              case "elephant": {
                // moves diagonally, 2 * 2. abs represents destination col number (x).
                const x = xDest - s.columnIndex;
                if (Math.abs(x) !== 2)
                  throw Error(
                    `Move#${s.index} ${s.def} is impossible for an Elephant.`
                  );
                return { col: x, row: 2 * ySign };
              }
              default: {
                return { col: 0, row: charNum * ySign };
              }
            }
          }
        }
      } catch (e) {
        runInAction(() => (s.error = e));
        reportError(e);
      }
      return defaultValue;
    },
    get pieceBefore(): Nullable<PieceDef> {
      if (s.relativeLocator) {
        if (s.piecesBeforeInColumn.length === 1) {
          throw Error(
            `String ${s.def} asks for the ${s.relativeLocator} ${s.pieceDisplayName}, but there is only one ${s.pieceDisplayName}. This is likely a mistake.`
          );
        }
        switch (s.relativeLocator) {
          case "first":
            return first(s.piecesBeforeInColumn) ?? null;
          case "last":
            return last(s.piecesBeforeInColumn) ?? null;
          case "second":
            return s.piecesBeforeInColumn[1] ?? null;
          case "third":
            return s.piecesBeforeInColumn[2] ?? null;
          case "fourth":
            return s.piecesBeforeInColumn[3] ?? null;
          case "fifth":
            return s.piecesBeforeInColumn[4] ?? null;
        }
      } else {
        if (s.piecesBeforeInColumn.length > 1) {
          console.log(s.piecesBeforeInColumn);
          for (let piece of s.piecesBeforeInColumn) {
            const legalDeltaSet = getLegalDeltaSetOfPiece(piece, s.setupBefore);
            const forwardSign = piece.playerIdentifier === "playerB" ? -1 : 1;
            const backwardSign = piece.playerIdentifier === "playerB" ? 1 : -1;
            console.log(legalDeltaSet);
            switch (s.type) {
              case "forward": {
                // TODO what about d.row === 0?
                const forwardable =
                  legalDeltaSet.filter(d => Math.sign(d.row) === forwardSign)
                    .length > 0;
                if (forwardable) return piece; // return the first piece that can be forwarded;
                break;
              }
              case "backward": {
                // TODO what about d.row === 0?
                const backwardable =
                  legalDeltaSet.filter(d => Math.sign(d.row) === backwardSign)
                    .length > 0;
                if (backwardable) return piece;
                break;
              }
              case "sideways": {
                return piece;
              }
            }
            throw Error(
              `Cannot determine which piece the move is referring to from the string ${s.def}`
            );
          }
        }
      }
      return first(s.piecesBeforeInColumn) ?? null;
    },
    get pieceAfter(): Nullable<PieceDef> {
      if (!s.isValid) return null;
      return {
        ...s.pieceBefore!,
        col: (s.pieceBefore!.col + s.delta.col) as ValidColIndex,
        row: (s.pieceBefore!.row + s.delta.row) as ValidColIndex,
      };
    },
    get setupBefore(): PieceDefList {
      if (!s.prevMove) return s.game.setup;
      if (s.def.length === 4) return s.prevMove.setupAfter;
      return s.game.setup;
    },
    get isValid() {
      return (s.delta.col || s.delta.row) && s.pieceBefore;
    },
    get setupAfter(): PieceDefList {
      if (s.isOpeningSetup) return s.game.setup;
      if (!s.prevMove) return s.game.setup;
      if (s.isValid && s.pieceAfter) {
        return s.setupBefore.map(pc => {
          const pcCopy = { ...pc };
          if (
            pcCopy.col === s.pieceAfter!.col &&
            pcCopy.row === s.pieceAfter!.row
          )
            pcCopy.alive = false;
          return pcCopy.id === s.pieceAfter!.id ? s.pieceAfter! : pcCopy;
        }) as PieceDef[];
      }
      return s.setupBefore;
    },
    get deltaDisplayName() {
      switch (s.type) {
        case "forward":
        case "backward":
          switch (s.pieceName) {
            case "advisor":
            case "elephant":
            case "horse": {
              if (!s.pieceAfter) return null;
              return getColumnDisplayName(
                s.pieceAfter.col,
                s.player?.identifier,
                s.army
              );
            }
            default:
              const value = Math.abs(s.delta.row);
              if (s.army === "black")
                return getFullWidthNumberFromHalfWidthNumber(value);
              else return getHanCharFromHalfWidthNumber(value);
          }
        case "sideways":
          if (!s.pieceAfter) return null;
          return getColumnDisplayName(
            s.pieceAfter.col,
            s.player?.identifier,
            s.army
          );
        default:
          return null;
      }
    },
    get displayDef() {
      if (s.isOpeningSetup) return OpeningSetupString;
      let result = "";
      /** TODO: write tests or double check all rules for this */
      if (s.army && s.pieceName && s.type && s.deltaDisplayName) {
        if (s.relativeLocator) {
          switch (s.relativeLocator) {
            case "first":
            case "last":
              result = [
                s.relativeLocatorDisplayName,
                s.pieceDisplayName,
                s.typeDisplayName,
                s.deltaDisplayName,
              ].join("");
              break;
            default:
              result = [
                "前",
                s.relativeLocatorDisplayName,
                s.typeDisplayName,
                s.deltaDisplayName,
              ].join("");
              break;
          }
        } else {
          if (!s.pieceBefore) result = "";
          else
            result = [
              s.pieceDisplayName,
              getColumnDisplayName(
                s.pieceBefore.col,
                s.player?.identifier,
                s.army
              ),
              s.typeDisplayName,
              s.deltaDisplayName,
            ].join("");
        }
      }
      if (!result) return s.def ? `[!]${s.def}` : s.def;
      return result;
    },
  });

  return s;
};

export type Move = ReturnType<typeof makeMove>;
