import { makeIconDef } from "./makeIconDef";

export const NotificationIconDef = {
  notification: makeIconDef("notification", {
    regular: () => (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6 2.9C11.6 2.67909 11.7791 2.5 12 2.5C12.2209 2.5 12.4 2.67909 12.4 2.9C12.4 3.72559 12.9939 4.35137 13.6608 4.61049C15.0681 5.15726 16.1561 6.38135 16.4874 7.92772L17.8486 14.2796C18.0177 15.0687 18.4543 15.7752 19.0844 16.2793L20.4269 17.3533C20.4731 17.3902 20.5 17.4462 20.5 17.5053C20.5 17.6128 20.4128 17.7 20.3053 17.7H3.69469C3.58716 17.7 3.5 17.6128 3.5 17.5053C3.5 17.4462 3.52688 17.3902 3.57307 17.3533L4.91556 16.2793C5.54574 15.7752 5.98234 15.0687 6.15143 14.2796L7.51255 7.92773C7.84392 6.38135 8.9319 5.15726 10.3392 4.61049C11.0061 4.35137 11.6 3.72559 11.6 2.9ZM12 1.5C11.2268 1.5 10.6 2.1268 10.6 2.9C10.6 3.20845 10.3672 3.52679 9.97707 3.67837C8.26427 4.34383 6.93848 5.83411 6.53475 7.7182L5.17363 14.0701C5.05285 14.6337 4.74099 15.1383 4.29086 15.4984L2.94837 16.5724C2.66497 16.7991 2.5 17.1424 2.5 17.5053C2.5 18.1651 3.03488 18.7 3.69469 18.7H9.02643C9.009 18.8309 9 18.9644 9 19.1C9 20.7569 10.3431 22.1 12 22.1C13.6569 22.1 15 20.7569 15 19.1C15 18.9644 14.991 18.8309 14.9736 18.7H20.3053C20.9651 18.7 21.5 18.1651 21.5 17.5053C21.5 17.1424 21.335 16.7991 21.0516 16.5724L19.7091 15.4984C19.259 15.1383 18.9471 14.6337 18.8264 14.0701L17.4652 7.7182C17.0615 5.83411 15.7357 4.34383 14.0229 3.67837C13.6328 3.52679 13.4 3.20845 13.4 2.9C13.4 2.1268 12.7732 1.5 12 1.5ZM13.96 18.7H10.04C10.0138 18.8292 10 18.963 10 19.1C10 20.2046 10.8954 21.1 12 21.1C13.1046 21.1 14 20.2046 14 19.1C14 18.963 13.9862 18.8292 13.96 18.7Z"
        fill="currentColor"
      />
    ),
  }),
};
