import { ResizeObserver as Polyfill } from '@juggle/resize-observer';
import { reaction, when } from "mobx";
import React from "react";
import { useOnMount } from "./lifecycle.hooks";

const ResizeObserver = window.ResizeObserver || Polyfill;

export const useResizeObserver = (
  handler: ResizeObserverCallback,
  ref: React.RefObject<HTMLElement | SVGElement>
) => {
  const observer = new ResizeObserver(handler);
  useOnMount(() => {
    const disposers = [] as AnyFunction[];
    disposers.push(when(
      () => Boolean(ref?.current),
      () => {
        observer.observe(ref.current!);
        disposers.push(reaction(
          () => !Boolean(ref?.current),
          () => {
            // console.log('unobserving', ref.current);
            ref.current && observer.unobserve(ref.current);
          },
        ));
      },
    ));
    return () => disposers.forEach(d => d());
  })
}