import { action, observable } from "mobx";
import { RootController } from "./_root.controller";

export const makeNavigatorController = () => {
  const c = observable({
    $root: null as Nullable<RootController>,
    windowTitle: "",
    $init: action((root: RootController) => {
      c.$root = root;
    }),
  });
  return c;
};

export type NavigatorController = ReturnType<typeof makeNavigatorController>;
