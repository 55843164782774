import gsap from "gsap";
import CSSPlugin from "gsap/CSSPlugin";
import { configure as configureMobX } from "mobx";
import "normalize.css";
import { createRoot } from "react-dom/client";
import initReactFastclick from "react-fastclick";
import App from "./App";
import { RootContext, RootControllerInstance } from "./contexts/root.context";
import reportWebVitals from "./reportWebVitals";
import { runPolyfills } from "./utils/polyfills.utils";

configureMobX({
  enforceActions: "observed",
  useProxies: "always",
});

runPolyfills();

initReactFastclick();

gsap.registerPlugin(CSSPlugin);

const root = createRoot(document.getElementById("root")!);

root.render(
  // <React.StrictMode>
  <RootContext.Provider value={RootControllerInstance}>
    <App />
  </RootContext.Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
