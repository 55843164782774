import { Observer } from "mobx-react-lite";
import React from "react";
import { OpeningSetupString } from "../../components/GameMoveListUI/GameMoveListItem";
import MoveEditor from "../../components/MoveEditor/MoveEditor";
import BaseCheckbox from "../../components/_base/BaseCheckbox/BaseCheckbox";
import BaseSpacer from "../../components/_base/BaseSpacer/BaseSpacer";
import ShadedBlock from "../../components/_base/ShadedBlock/ShadedBlock";
import { FM } from "../../constants/lang-components.aliases";
import { useStudioContext } from "../../contexts/studio/studio.context";
import { MSG } from "../../lang/MSG.enum";
import { useStore } from "../../utils/mobx.utils";
import "./GameEditorUI.scss";
import GameOpeningSetupEditor from "./GameOpeningSetupEditor";
import StudioSidebarUIPanel from "./StudioSidebarUIPanel";

type GameEditorUIProps = {};

const GameEditorUI: React.FC<GameEditorUIProps> = props => {
  const c = useStudioContext();
  const s = useStore(() => ({
    get isOpeningSetup() {
      return c.currentMove?.def === OpeningSetupString;
    },
  }));
  return (
    <Observer
      children={() => (
        <StudioSidebarUIPanel className="GameEditorUI">
          {s.isOpeningSetup ? (
            <GameOpeningSetupEditor />
          ) : (
            <>
              <p className="GameEditorNthRoundCounter">
                <FM
                  id={MSG.nthRound}
                  defaultMessage={`Round ${c.currentRoundIndex}`}
                  values={{
                    nth: c.currentRoundIndex,
                  }}
                />
              </p>

              {c.currentMove && (
                <div className="GameEditorCurrentMoveDisplay">
                  <MoveEditor move={c.currentMove} />
                </div>
              )}
            </>
          )}

          <BaseSpacer size=".5em" />

          <ShadedBlock>
            <BaseCheckbox form={c.settings} field="animatePieceMovement">
              <FM
                id={MSG.labelAnimatePieceMovement}
                defaultMessage="Animate Piece Movement"
              />
            </BaseCheckbox>
          </ShadedBlock>
        </StudioSidebarUIPanel>
      )}
    />
  );
};

export default GameEditorUI;
