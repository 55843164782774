import { isNil } from "./ramdaEquivalents.utils";

export const copyElementContents = (el: HTMLElement | SVGElement | null) => {
  if (!el) return null;
  const body = document.body;
  let range: Range;
  let sel: Selection | null;
  if (document.createRange && window.getSelection) {
    range = document.createRange();
    sel = window.getSelection();
    if (!sel) return null;
    sel.removeAllRanges();
    try {
      range.selectNodeContents(el);
      sel.addRange(range);
    } catch (e) {
      range.selectNode(el);
      sel.addRange(range);
    }
    document.execCommand("copy");
  } else if ((body as any).createTextRange) {
    range = (body as any).createTextRange();
    (range as any).moveToElementText(el);
    (range as any).select();
    (range as any).execCommand("Copy");
  }
};
export const copyString = (string: Sometimes<string>) => {
  if (isNil(string)) return;
  const textarea = document.createElement("textarea");
  textarea.setAttribute("value", string);
  textarea.innerHTML = string;
  textarea.setAttribute(
    "style",
    `
		opacity: 0;
		position: fixed;
		top: 0;
		left: 0;
		transform: translate(-100%, -100%);
	`
  );
  document.body.appendChild(textarea);
  textarea.focus();
  textarea.select();
  document.execCommand("copy");
  document.body.removeChild(textarea);
};

export function getScrollParent(
  node: Sometimes<Node & ParentNode & HTMLElement>
): HTMLElement | null {
  if (!node) return null;
  const isElement = node instanceof HTMLElement;
  const overflowY = isElement && window.getComputedStyle(node).overflowY;
  const isScrollable = !(
    (overflowY && overflowY.includes("hidden")) ||
    (overflowY && overflowY.includes("visible"))
  );
  if (isScrollable && node.scrollHeight >= node.clientHeight) {
    return node;
  }
  return (
    getScrollParent(node.parentNode as Node & ParentNode & HTMLElement) ||
    document.body
  );
}
