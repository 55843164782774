import { BoardTheme } from "../@types/app.types";
import { BoardGridTheme } from "../components/Board/Board";
import {
  BoardHeaderFooterElement,
  makePieceSetDesignConfig,
} from "../contexts/studio/studioDesign.contextPartial";
import { MSG } from "../lang/MSG.enum";

const BoardThemeTikHianOlive: BoardTheme = {
  identifier: "tikhian-olive",
  name: MSG.themeNameTikHianOlive,
  value: {
    board: {
      backgroundColor: "#4E6E58",
      borderWidth: 0,
      borderRadius: 0,
      textColor: "#D6B385",
      borderColor: "#4E6E58",
      fontSize: 48,
      fontFamily: "genwan",
      fontWeight: 600,
      inset: {
        userDefined: {
          top: 50,
          left: 50,
          right: 50,
          bottom: 150,
        },
      },
    },
    header: {
      distanceToBoardContent: 30,
      middle: {
        element: BoardHeaderFooterElement.None,
      },
    },
    footer: {
      distanceToBoardContent: 62,
      middle: {
        element: BoardHeaderFooterElement.LogoPZ,
      },
    },
    grid: {
      theme: "default" as BoardGridTheme,
      color: "#D6B385",
      opacity: 0.7,
    },
    columnNumberLabels: {
      enabled: true,
      red: {
        fontSize: 36,
        fontWeight: 600,
      },
      black: {
        fontSize: 36,
        fontWeight: 500,
      },
    },
    red: {
      ...makePieceSetDesignConfig("red"),
      charSet: "TikHianOriginal",
      fillColor: "#102412",
      textColor: "#E94C26",
      outlineColor: "#E94C26",
    },
    black: {
      ...makePieceSetDesignConfig("black"),
      charSet: "TikHianOriginal",
      fillColor: "#D6B385",
    },
    pieceSet: {
      charSet: "TikHianOriginal",
      charSetVariantDefs: {
        ba1: "A",
        ba2: "B",
        be1: "A",
        be2: "B",
        bh1: "A",
        bh2: "B",
        br1: "A",
        br2: "B",
        bc1: "A",
        bc2: "B",
        bs1: "A",
        bs2: "B",
        bs3: "C",
        bs4: "D",
        bs5: "E",

        ra1: "A",
        ra2: "B",
        re1: "A",
        re2: "B",
        rh1: "A",
        rh2: "B",
        rr1: "A",
        rr2: "B",
        rc1: "A",
        rc2: "B",
        rs1: "A",
        rs2: "B",
        rs3: "C",
        rs4: "D",
        rs5: "E",
      },
    },
  },
};

export default BoardThemeTikHianOlive;
