export enum MSG {
  pzxy = "",
  pzxyLogo = "pzxyLogo",
  appName = "appName",
  untitledGame = "untitledGame",
  createNewGame = "createNewGame",
  viewModeSelectorTitle = "viewModeSelectorTitle",
  viewModeSelectorEditSetupModeLabel = "viewModeSelectorEditSetupModeLabel",
  viewModeSelectorReadingModeLabel = "viewModeSelectorReadingModeLabel",
  viewModeSelectorDesignModeLabel = "viewModeSelectorDesignModeLabel",
  viewModeSelectorEditModeLabel = "viewModeSelectorEditModeLabel",
  viewModeSelectorExportModeLabel = "viewModeSelectorExportModeLabel",
  importGamesSectionTitle = "importGamesSectionTitle",
  importJsonButtonLabel = "importJsonButtonLabel",
  pasteOrEnterMovesRecordLabel = "pasteOrEnterMovesRecordLabel",
  enterMovesRecordButtonLabel = "enterMovesRecordButtonLabel",
  error_MovesRecordImportFailure = "error_MovesRecordImportFailure",
  gameRecordTitle = "gameRecordTitle",
  gameCategory = "gameCategory",
  gameCollectionName = "gameCollectionName",
  gameMatchName = "gameMatchName",
  gameTournamentName = "gameTournamentName",
  gameRecordFileName = "gameRecordFileName",
  gameOpeningSetupEditorTitle = "gameOpeningSetupEditorTitle",
  removeSelectedPieceButtonLabel = "removeSelectedPieceButtonLabel",
  clearBoard = "clearBoard",
  confirmClearBoard = "confirmClearBoard",
  exporterSectionTitle = "exporterSectionTitle",
  exporterOptionsTableTitle = "exporterOptionsTableTitle",
  metaInfoEditorSectionTitle = "metaInfoEditorSectionTitle",
  tooManyFramesForGifWarning = "tooManyFramesForGifWarning",
  gameOpening = "gameOpening",
  endGame = "endGame",
  labelOpeningSetup = "labelOpeningSetup",
  exportAsJonButtonLabel = "exportAsJonButtonLabel",
  exportOnlyOpeningSetupAsJonButtonLabel = "exportOnlyOpeningSetupAsJonButtonLabel",
  exportCurrentFrameButtonLabel = "exportCurrentFrameButtonLabel",
  exportAllFramesButtonLabel = "exportAllFramesButtonLabel",
  exportImageFileFormatLabel = "exportImageFileFormatLabel",
  exportGifButtonLabel = "exportGifButtonLabel",
  movesSectionTitle = "movesSectionTitle",
  DesignCustomizerSectionTitle = "DesignCustomizerSectionTitle",
  customizerOptionBoardDesignSectionTitle = "customizerOptionBoardDesignSectionTitle",
  customizerOptionBoardBackgroundColor = "customizerOptionBoardBackgroundColor",
  customizerOptionBoardBorderColor = "customizerOptionBoardBorderColor",
  customizerOptionBoardBorderWidth = "customizerOptionBoardBorderWidth",
  customizerOptionBoardBackgroundImage = "customizerOptionBoardBackgroundImage",
  customizerOptionBoardFontFamily = "customizerOptionBoardFontFamily",
  customizerOptionBoardFontWeight = "customizerOptionBoardFontWeight",
  customizerOptionBoardFontSize = "customizerOptionBoardFontSize",
  customizerOptionBoardTextColor = "customizerOptionBoardTextColor",
  customizerOptionBoardTextOpacity = "customizerOptionBoardTextOpacity",
  customizerOptionBoardGridColor = "customizerOptionBoardGridColor",
  customizerOptionBoardGridOpacity = "customizerOptionBoardGridOpacity",
  customizerOptionPieceDesignSectionTitle = "customizerOptionPieceDesignSectionTitle",
  customizerOptionPieceDesignRedSectionTitle = "customizerOptionPieceDesignRedSectionTitle",
  customizerOptionPieceDesignBlackSectionTitle = "customizerOptionPieceDesignBlackSectionTitle",
  customizerOptionPieceBaseType = "customizerOptionPieceBaseType",
  customizerOptionPieceCharSet = "customizerOptionPieceCharSet",
  customizerOptionMoreOptionsSectionTitle = "customizerOptionMoreOptionsSectionTitle",
  fontNameSystemSans = "fontNameSystemSans",
  fontNameGenwan = "fontNameGenwan",
  fontNameTaipeiSans = "fontNameTaipeiSans",
  fontNameSourceHanSansSC = "fontNameSourceHanSansSC",
  fontNameSourceHanSerifSC = "fontNameSourceHanSerifSC",
  pieceCharSetNameGenwan = "pieceCharSetNameGenwan",
  pieceCharSetNameTaipeiSans = "pieceCharSetNameTaipeiSans",
  pieceCharSetName1941 = "pieceCharSetName1941",
  pieceCharSetNameTsingWoodbone = "pieceCharSetNameTsingWoodbone",
  pieceCharSetNameTikHianOriginal = "pieceCharSetNameTikHianOriginal",
  pieceBaseTypeCircle = "pieceBaseTypeCircle",
  selectorPlaceholderText = "selectorPlaceholderText",
  labelOutlineColor = "labelOutlineColor",
  labelOutlineTexture = "labelOutlineTexture",
  labelFillColor = "labelFillColor",
  labelFillTexture = "labelFillTexture",
  labelTextColor = "labelTextColor",
  labelTextTexture = "labelTextTexture",
  customizerOptionBoardInset = "customizerOptionBoardInset",
  noticeTextAllPiecesAlive = "noticeTextAllPiecesAlive",
  labelDistanceToBoardContent = "labelDistanceToBoardContent",
  labelHeader = "labelHeader",
  labelFooter = "labelFooter",
  labelMiddleSlot = "labelMiddleSlot",
  labelStartSlot = "labelStartSlot",
  labelEndSlot = "labelEndSlot",
  labelPlayerRed = "labelPlayerRed",
  labelPlayerBlack = "labelPlayerBlack",
  labelPlayerRedName = "labelPlayerRedName",
  labelPlayerBlackName = "labelPlayerBlackName",
  labelCustomText = "labelCustomText",
  boardPlayerColorLabelRed = "boardPlayerColorLabelRed",
  boardPlayerColorLabelBlack = "boardPlayerColorLabelBlack",
  boardPlayerColorLabelRedWithSpace = "boardPlayerColorLabelRedWithSpace",
  boardPlayerColorLabelBlackWithSpace = "boardPlayerColorLabelBlackWithSpace",
  customizerThemePresetSectionTitle = "customizerThemePresetSectionTitle",
  themeNameGenwanWarm = "themeNameGenwanWarm",
  themeNameTaipeiSansWhite = "themeNameTaipeiSansWhite",
  themeNameTextbook1941BW = "themeNameTextbook1941BW",
  themeNameTsingWoodboneClean = "themeNameTsingWoodboneClean",
  themeNameTikHianOriginal = "themeNameTikHianOriginal",
  themeNameTikHianWhite = "themeNameTikHianWhite",
  themeNameTikHianOlive = "themeNameTikHianOlive",
  themeNameTikHianDark = "themeNameTikHianDark",
  nthRoundCounter = "nthRoundDisplay",
  nthRound = "nthRound",
  nthPage = "nthPage",
  pageNumber = "pageNumber",
  pageNumberWithCount = "pageNumberWithCount",
  labelShowColumnNumberLabels = "labelShowColumnNumberLabels",
  labelColumnNumberLabelBlackFontWeight = "labelColumnNumberLabelBlackFontWeight",
  labelColumnNumberLabelRedFontWeight = "labelColumnNumberLabelRedFontWeight",
  labelExportStaticImageWidth = "labelExportStaticImageWidth",
  labelExportAnimatedImageWidth = "labelExportAnimatedImageWidth",
  labelAnimatePieceMovement = "labelAnimatePieceMovement",
  labelDropHereToRemovePiece = "labelDropHereToRemovePiece",
  copyGameRecordAsText = "copyGameRecordAsText",
  copySuccessMessage = "copySuccessMessage",
  labelPrevMove = "labelPrevMove",
  labelNextMove = "labelNextMove",
  labelStartAutoPlay = "labelStartAutoPlay",
  labelStartAutoPlayFromBeginning = "labelStartAutoPlayFromBeginning",
  labelStopAutoPlay = "labelStopAutoPlay",
  labelPauseAutoPlay = "labelPauseAutoPlay",
  labelResumeAutoPlay = "labelResumeAutoPlay",
  labelAutoPlayStepDurationInSeconds = "labelAutoPlayStepDurationInSeconds",
  labelPlayerArmyAssignment = "labelPlayerArmyAssignment",
  labelBlackAtNorth = "labelBlackAtNorth",
  labelRedAtNorth = "labelRedAtNorth",
  labelPlayerFirstHandSelector = "labelPlayerFirstHandSelector",
  unnamedPlayer = "unnamedPlayer",
  labelCurrentlySelectedPiece = "labelCurrentlySelectedPiece",
  labelCharacterDesignVariant = "labelCharacterDesignVariant",
  exporterStateReady = "exporterStateReady",
  exporterStateExportingAsJSON = "exporterStateExportingAsJSON",
  exporterStateRenderingNthFrame = "exporterStateRenderingNthFrame",
  exporterStateRenderingNthFrameOfTotal = "exporterStateRenderingNthFrameOfTotal",
  exporterStatePreparingGIF = "exporterStatePreparingGIF",
  exporterStatePreparingZIP = "exporterStatePreparingZIP",
  exporterStateDownloading = "exporterStateDownloading",
  exporterStateDownloadingGIF = "exporterStateDownloadingGIF",
  exporterStateCompletion = "exporterStateCompletion",
  textureNameMarble = "textureNameMarble",
  textureNamePaperWarm = "textureNamePaperWarm",
  textureNamePaperWarmer = "textureNamePaperWarmer",
}
